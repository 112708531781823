import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const WorkshopsChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const workshopChartRef = useRef(null);
  const [workshopData, setWorkshopData] = useState([]);
  const [labels, setLabels] = useState([]);

  // Function to check if a workshop is completed
  const isWorkshopCompleted = (endDate) => {
    const today = new Date();
    const workshopEndDate = new Date(endDate);
    return workshopEndDate < today; // Return true if the workshop's end date is before today's date
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const labels = Object.values(tlMappings);
        const workshopCounts = Array(labels.length).fill(0); // Initialize an array with zeroes for each label

        const fetchWorkshopData = async (TL) => {
          const response = await axiosApiService.get('/training/workshops/', {
            headers: {
              'TL': TL,
            },
          });
          return response.data;
        };

        for (const [TL, name] of Object.entries(tlMappings)) {
          const workshops = await fetchWorkshopData(name);
          // Filter workshops to only consider completed ones
          const completedWorkshops = workshops.filter(workshop => isWorkshopCompleted(workshop.to_date));
          workshopCounts[labels.indexOf(name)] = completedWorkshops.length; // Count the number of completed workshops for each TL
        }

        setLabels(labels);
        setWorkshopData(workshopCounts);
      } catch (error) {
        console.error('Error fetching workshop data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  useEffect(() => {
    if (workshopChartRef.current) workshopChartRef.current.destroy();

    if (workshopData.length > 0 && Math.max(...workshopData) > 0) {
      const maxWorkshops = Math.ceil(Math.max(...workshopData) * 1.2); // Extend y-axis by 20%

      const workshopCtx = chartRef.current.getContext('2d');
      workshopChartRef.current = new Chart(workshopCtx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Number of Workshops',
              data: workshopData,
              backgroundColor: 'rgba(54, 162, 235, 0.6)',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              max: maxWorkshops,
              title: {
                display: true,
                text: 'Number of Workshops',
              },
              ticks: {
                stepSize: 1, // Ensure the y-axis values are integers
              },
            },
          },
        },
      });
    }

    return () => {
      if (workshopChartRef.current) workshopChartRef.current.destroy();
    };
  }, [labels, workshopData]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: 1, borderStyle: 'solid', width: '90%', height: 336, padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardContent sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '700', color: '#212121', textAlign: 'center', letterSpacing: 0 }}>
          Workshops Completed
        </Typography>
        {workshopData.length > 0 && Math.max(...workshopData) > 0 ? (
          <Box sx={{ height: '250px', width: '100%', marginTop: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <canvas ref={chartRef} style={{ width: '100%' }} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
            <Typography variant='h5' color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default WorkshopsChart;
