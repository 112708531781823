import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const TutorialsWatchedChart = () => {
  const chartRef = useRef(null);
  const tutorialChartRef = useRef(null);
  const [tutorialData, setTutorialData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosApiService.get('/api/modules/');
        const data = response.data;
        const labels = data.map(item => `Tutorial #${item.tutorial_number}`);
        const tutorialCounts = data.map(item => item.count);

        setLabels(labels);
        setTutorialData(tutorialCounts);
      } catch (error) {
        console.error('Error fetching tutorial data:', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (tutorialChartRef.current) tutorialChartRef.current.destroy();

    const hasData = tutorialData.length > 0 && tutorialData.some(value => value > 0);

    if (hasData) {
      const maxTutorials = Math.ceil(Math.max(...tutorialData) * 1.2); // Extend y-axis by 20%
      const tutorialCtx = chartRef.current.getContext('2d');
      tutorialChartRef.current = new Chart(tutorialCtx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Tutorials Watched',
              data: tutorialData,
              backgroundColor: 'rgba(255, 206, 86, 0.6)',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y', // This makes the bars horizontal
          scales: {
            x: {
              beginAtZero: true,
              max: maxTutorials,
              ticks: {
                stepSize: 1, // Ensure the x-axis values are integers
              },
            },
          },
        },
      });
    }

    return () => {
      if (tutorialChartRef.current) tutorialChartRef.current.destroy();
    };
  }, [labels, tutorialData]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: 1, borderStyle: 'solid', flex: 1, height: '330px', padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%' }}>
      <CardContent sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ fontSize: '25px', fontWeight: '700', color: '#212121', textAlign: 'center', letterSpacing: 0 }}>Tutorials Watched</Typography>
        {tutorialData.length > 0 && tutorialData.some(value => value > 0) ? (
          <Box sx={{ height: '250px', width: '100%', marginTop: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <canvas ref={chartRef} style={{ width: '100%' }} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
            <Typography variant='h5' color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default TutorialsWatchedChart;
