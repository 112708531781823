import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, TextField, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import WorkbenchAPIs from '../../../utilities/api/workbench/WorkbenchAPIs';
import 'react-toastify/dist/ReactToastify.css'; // Import styles for toastify

// Validation schema using Yup
const validationSchema = Yup.object().shape({
    seats: Yup.number().required('No. of seats is required'),
    upc: Yup.string().required('Available machine/inventory is required'),
    startTime: Yup.string().required('Start time is required'),
    endTime: Yup.string().required('End time is required').test(
        'is-after-startTime',
        'End time must be after start time',
        function (value) {
            const { startTime } = this.parent;
            return new Date(`1970-01-01T${value}`) > new Date(`1970-01-01T${startTime}`);
        }
    ),
    description: Yup.string(),
});

const AddWorkbenchDialog = ({ open, onClose, update, setUpdate }) => {
    const handleSubmitForm = (values, resetForm) => {
        const bookingData = {
          seats: values.seats,
          available_machines: values.upc, // Map "upc" to "available_machines"
          slot_from: values.startTime,
          slot_to: values.endTime,
          description: values.description,
        };
      
        // Call the API to create a new booking using .then() and .catch()
        const response = WorkbenchAPIs.createWorkbench(bookingData);
        response
          .then((result) => {
            console.log(result);
            toast.success('Workbench booking added successfully!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            resetForm(); // Reset the form
            onClose(); // Close the dialog
            setUpdate((prev) => !prev); // Trigger parent component update
          })
          .catch((error) => {
            console.error('Error creating booking:', error);
            if (error.response && error.response.data) {
              // Extract and show error messages from the response
              const errorMessages = Object.values(error.response.data).flat().join(' ');
              toast.error(`Failed to create booking: ${errorMessages}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else {
              // Show a general error message if no specific details are provided
              toast.error('Failed to create booking: An unexpected error occurred.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          });
      };      

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" sx={{
            '& .MuiDialog-paper': {
                borderRadius: '16px',
            }
        }}>
            <Box sx={{ p: 1, borderRadius: '16px', backgroundColor: '#fff', boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)' }}>
                <DialogTitle sx={{ fontSize: '24px', color: '#3f3f3f', fontWeight: 700, mb:-2 }}>Add New Work Bench</DialogTitle>

                <Formik
                    initialValues={{
                        seats: '',
                        upc: '',
                        startTime: '00:00',
                        endTime: '00:00',
                        description: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmitForm(values, resetForm);
                    }}
                >
                    {({ handleSubmit, values, touched, errors, handleChange }) => (
                        <Form onSubmit={handleSubmit}>
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

                                {/* No. of seats */}
                                <Box>
                                    <Typography sx={{ mb: 1, fontWeight: 600, fontSize: '16px', color: '#3f3f3f' }}>
                                        No. of seats <span style={{ color: '#c90505' }}>*</span> :
                                    </Typography>
                                    <TextField
                                        name="seats"
                                        placeholder="Enter the number of seats"
                                        fullWidth
                                        value={values.seats}
                                        onChange={handleChange}
                                        error={touched.seats && !!errors.seats}
                                        helperText={touched.seats && errors.seats}
                                        sx={{
                                            '& .MuiInputBase-root': { borderRadius: '8px', },
                                            '& input': { color: '#898989' },
                                        }}
                                    />
                                </Box>

                                {/* Available machine/inventory */}
                                <Box>
                                    <Typography sx={{ mb: 1, fontWeight: 600, color: '#3f3f3f' }}>
                                        Available machine/inventory <span style={{ color: '#c90505' }}>*</span> :
                                    </Typography>
                                    <TextField
                                        name="upc"
                                        placeholder="Enter available machine/inventory"
                                        fullWidth
                                        value={values.upc}
                                        onChange={handleChange}
                                        error={touched.upc && !!errors.upc}
                                        helperText={touched.upc && errors.upc}
                                        sx={{
                                            '& .MuiInputBase-root': { borderRadius: '8px', },
                                            '& input': { color: '#898989' },
                                        }}
                                    />
                                </Box>

                                {/* Booking Duration */}
                                <Box>
                                    <Typography sx={{ mb: 1, fontWeight: 600, color: '#3f3f3f' }}>
                                        Booking Duration <span style={{ color: '#c90505' }}>*</span> :
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        {/* Start Time */}
                                        <TextField
                                            type="time"
                                            name="startTime"
                                            value={values.startTime}
                                            onChange={handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 minutes
                                            }}
                                            sx={{
                                                borderRadius: '8px',
                                                '& .MuiInputBase-root': { borderRadius: '8px' }
                                            }}
                                        />

                                        <Typography sx={{ marginInline: 1, color: '#3f3f3f', fontWeight: 500 }}>To</Typography>

                                        {/* End Time */}
                                        <TextField
                                            type="time"
                                            name="endTime"
                                            value={values.endTime}
                                            onChange={handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 minutes
                                            }}
                                            sx={{
                                                borderRadius: '8px',
                                                '& .MuiInputBase-root': { borderRadius: '8px' }
                                            }}
                                        />
                                    </Box>
                                </Box>


                                {/* Description */}
                                <Box>
                                    <Typography sx={{ fontWeight: 600, color: '#3f3f3f' }}>Description :</Typography>
                                    <TextField
                                        name="description"
                                        placeholder="Enter Description"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={values.description}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-root': { borderRadius: '8px', },
                                            '& input': { color: '#898989' },
                                        }}
                                    />
                                </Box>
                            </DialogContent>

                            <DialogActions sx={{ justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'none',
                                        color: '#000',
                                        backgroundColor: '#fff',
                                        border: '1px solid #b5b5b5',
                                        borderRadius: '12px',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0',
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                        borderRadius: '12px',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            backgroundColor: '#0056b3',
                                        }
                                    }}
                                >
                                    Add Work Bench
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    );
};

export default AddWorkbenchDialog;
