import { axiosApiService } from "../axios";
const tagMapping = {
  'Free': '0',
  'Issue': '1',
  'Purchase': '2',
  'Exhaustible': '3',
  'NonExhaustible': '4',
  'InLabOnly': '5',
  'HighValue': '6',

}
const availabilityMapping = {
  'Moderate': 2,
  'Very Low': 0,
  'Low': 2,
  'High': 3,
  'Always': 4
};
const categoryMapping = {
  'Electronics': 0,
  'Mechanical': 1,
  'Integrated Chips': 2,
};
const groupMapping = {
  'A': 0,
  'B': 1,
  'C': 2
}
const EquipmentListAPIs = {
  EquipmentListGet: async function(){
    const response = await axiosApiService.get(`/admin_equipment_list/`);
    return response.data;
  },

  AddInventory: async function (values, token) {
    try {
      const response = await axiosApiService.post(`/admin_equipment_list/`, values);
      return response;
    } catch (error) {
      console.error('Error adding Equipment:', error);
      // You can choose to return an error response or throw an error
      // throw error;
    }
  },
}
export default EquipmentListAPIs;
