import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Typography, Box, Button, TextField } from '@mui/material';
import WorkshopContent from './workshop/WorkshopContent';
import TutorialPage from './Tutorial/TutorialPage';
import { useSelector, useDispatch } from 'react-redux';
import { setHasReturned } from '../../redux/slices/editModeSlice'; // Adjust path as needed

const TopbarTraining = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const hasReturned = useSelector((state) => state.editMode.hasReturned);

  useEffect(() => {
    // If hasReturned is true, set value to 1 and then reset hasReturned
    if (hasReturned) {
      setValue(1);
      dispatch(setHasReturned(false)); // Reset hasReturned to false after using it
    }
  }, [hasReturned, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleSearchChange = (event) => setSearchQuery(event.target.value);
  const handleAddModule = () => {
    setOpenDialog(true);
  };

  return (
    <div style={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="#fff"
        indicatorColor="primary"
        variant="fullWidth"
        sx={{ padding: '16px 16px' }}
      >
        <Tab label="Tutorials" sx={tabStyles(value === 0)} />
        <Tab label="Workshops" sx={tabStyles(value === 1)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <TutorialPage />
      </TabPanel>
      
      <TabPanel value={value} index={1}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <WorkshopContent />
        </Box>
      </TabPanel>
    </div>
  );
};

const tabStyles = (isSelected) => ({
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: isSelected ? '#3434f5' : '#fff',
  color: isSelected ? '#fff' : 'inherit',
  padding: '8px 16px',
});

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tabpanel-${index}`}
    {...other}
    style={{ width: '100%' }}
  >
    {value === index && (
      <Box sx={{padding:'0px 24px'}}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

export default TopbarTraining;