import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';
import { useLocation } from 'react-router-dom';
import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
import { useSelector } from 'react-redux';
import WebSocketService from '../../utilities/api/IMS/websocketService';
import { v4 as uuidv4 } from 'uuid';

const MessagingPage = () => {
    const { user, access } = useSelector(state => state.user.user);
    const [conversations, setConversations] = useState([]);
    const [messagesByConversation, setMessagesByConversation] = useState({});
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const location = useLocation();
    const [lastLocation, setLastLocation] = useState("/");
    const [pendingMessages, setPendingMessages] = useState([]); // Track pending messages
    const [lastSentMessageId, setLastSentMessageId] = useState(null); // Track the last sent message ID
    const [lastSentMessageText, setLastSentMessageText] = useState(""); // Track the last sent message text

    useEffect(() => {
        if (location.state?.from) {
            setLastLocation(location.state.from);
        }
    }, [location.state]);

    useEffect(() => {
        getThreads();
    }, []);

    useEffect(() => {
        if (selectedConversation !== null) {
            const conversationDetails = conversations.find(conv => conv.id === selectedConversation);
            if (conversationDetails) {
                WebSocketService.connect(access, conversationDetails.userName);
                WebSocketService.onMessage(handleIncomingMessage);
            }
        }
        return () => {
            WebSocketService.disconnect();
        };
    }, [selectedConversation, access]);

    const getThreads = async () => {
        try {
            const threads = await AdminMessagingAPIs.GetThreadList();
            const filteredThreads = threads.filter(thread =>
                thread.messages.some(message =>
                    message.sender === user.username || (message.receivers && message.receivers.includes(user.username))
                )
            );

            const convs = filteredThreads.map(thread => {
                let otherUser = null;

                if (thread.messages && thread.messages.length > 0) {
                    const message = thread.messages[0];
                    if (message.sender === user.username && message.receivers) {
                        otherUser = message.receivers.find(receiver => receiver !== user.username);
                    } else if (message.sender !== user.username) {
                        otherUser = message.sender;
                    }
                }

                const latestMessage = thread.latest_message;

                return {
                    id: thread.id,
                    userName: otherUser || 'Unknown',
                    message: latestMessage?.content || 'No message',
                    date: latestMessage?.timestamp ? new Date(latestMessage.timestamp).toLocaleString() : 'No date',
                    avatar: "path_to_avatar",
                };
            });
            setConversations(convs);

            const messagesByConv = {};
            filteredThreads.forEach(thread => {
                messagesByConv[thread.id] = thread.messages.map(message => ({
                    id: message.id,
                    text: message.content || 'No content',
                    date: message.timestamp ? new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'No time',
                    sender: message.sender,
                    isCurrentUser: message.sender === user.username
                }));
            });
            setMessagesByConversation(messagesByConv);
        } catch (error) {
            console.error("Error fetching conversations:", error);
        }
    };

    const handleIncomingMessage = useCallback((messageData) => {
        console.log("Received incoming message:", messageData);

        // Ignore the message if it matches the last sent message text
        if (messageData.message === lastSentMessageText) {
            console.log("Duplicate message received, ignoring:", messageData);
            return;
        }

        if (messageData && messageData.message && selectedConversation) {
            const incomingMessage = {
                id: messageData.id,
                text: messageData.message,
                date: new Date().toLocaleString(),
                sender: messageData.sender,
                isCurrentUser: messageData.sender === user.username,
            };

            setMessagesByConversation(prevMessages => ({
                ...prevMessages,
                [selectedConversation]: [...(prevMessages[selectedConversation] || []), incomingMessage],
            }));
            updateLastMessage(selectedConversation, messageData.message, new Date().toLocaleString());
        }
    }, [selectedConversation, lastSentMessageText]);

    const handleConversationSelect = (id) => {
        setSelectedConversation(id);
        setSelectedUser(null);
    };

    const handleStartNewConversation = (user) => {
        setSelectedUser(user);
        setSelectedConversation(null);
    };

    const addMessageToConversation = async (message) => {
        try {
            let threadId = selectedConversation;
            if (!threadId && selectedUser) {
                const newThread = await createThread(selectedUser);
                threadId = newThread.id;
                setSelectedConversation(threadId);
                setSelectedUser(null);
            }
            if (threadId) {
                const messageId = uuidv4();
                setLastSentMessageId(messageId); // Set the last sent message ID
                setLastSentMessageText(message); // Set the last sent message text

                const newMessage = { 
                    id: messageId, 
                    text: message, 
                    date: new Date().toLocaleString(),
                    sender: user.username,
                    isCurrentUser: true 
                };

                setMessagesByConversation(prevMessages => ({
                    ...prevMessages,
                    [threadId]: [
                        ...(prevMessages[threadId] || []),
                        newMessage
                    ]
                }));

                setPendingMessages([...pendingMessages, newMessage]); // Track as pending message

                WebSocketService.sendMessage(newMessage.text, threadId);

                updateLastMessage(threadId, newMessage.text, newMessage.date);
            }
        } catch (error) {
            console.error('Error adding message to conversation:', error);
        }
    };

    const createThread = async (user) => {
        try {
            const threadData = { participants: [user.username], messages: [] };
            const newThread = await AdminMessagingAPIs.CreateThread(threadData);
            const newConversation = {
                id: newThread.id,
                userName: user.username,
                message: '',
                date: new Date().toLocaleString(),
                avatar: 'path_to_avatar',
            };
            setConversations((prevConversations) => [newConversation, ...prevConversations]);
            setMessagesByConversation((prevMessages) => ({
                ...prevMessages,
                [newThread.id]: []
            }));
            return newThread;
        } catch (error) {
            console.error('Error creating new thread:', error);
            throw error;
        }
    };

    const updateLastMessage = (id, message, time) => {
        setConversations((prevConversations) => {
            const updatedConvs = prevConversations.map((conv) =>
                conv.id === id ? { ...conv, message, date: time } : conv
            );
            const updatedConv = updatedConvs.find(conv => conv.id === id);
            const remainingConvs = updatedConvs.filter(conv => conv.id !== id);
            return [updatedConv, ...remainingConvs];
        });
    };

    const selectedConversationDetails = conversations.find(conv => conv.id === selectedConversation);

    return (
        <Box sx={{ display: 'flex', height: '92vh' }}>
            <Sidebar 
                conversations={conversations} 
                onSelectConversation={handleConversationSelect} 
                lastLocation={lastLocation} 
                onStartNewConversation={handleStartNewConversation}
            />
            {selectedConversationDetails ? (
                <ChatWindow 
                    messages={messagesByConversation[selectedConversation]} 
                    currentUser={user} 
                    conversationUser={selectedConversationDetails} 
                    onNewMessage={addMessageToConversation} 
                    conversationId={selectedConversation} 
                />
            ) : selectedUser ? (
                <ChatWindow 
                    messages={[]} 
                    currentUser={user} 
                    conversationUser={{ userName: selectedUser.username, avatar: 'path_to_avatar' }} 
                    onNewMessage={addMessageToConversation} 
                    conversationId={null} 
                />
            ) : (
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray' }}>
                    <Typography variant="h6">Select a conversation or start a new one</Typography>
                </Box>
            )}
        </Box>
    );
};

export default MessagingPage;
