import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import UserRequestAPIs from '../../../utilities/api/user/AdminUserRequestList';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const buttonStyle = {
  display: 'flex',
  padding: '0.5rem 0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  borderRadius: '0.5rem',
};

const positionLabels = {
  0: 'TL student management body',
  1: 'Manager (other technical clubs)',
  2: 'TL Manager',
  3: 'TL Technician',
  4: 'Other',
};

const Request = ({ searchQuery }) => { // Receive searchQuery as prop
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [acceptedRows, setAcceptedRows] = useState([]);
  const [rejectedRows, setRejectedRows] = useState([]);

  const user = useSelector((state) => state.user.user);

  const fetchAndFilterData = () => {
    const response = UserRequestAPIs.UserRequestListGet();
    response
      .then((result) => {
        const filteredData = result.filter((item) => item.TL_name === user.user.TL_name);
        const formattedData = filteredData.map((item) => ({
          id: item.id, // Use unique ID from API
          email: item.email,
          name: item.name || item.username, // Display name
          profile_pic: item.profile_pic,
          TL: item.TL,
          TL_name: item.TL_name,
          phone_number: item.phone_number,
          position: item.other_position || positionLabels[item.position] || 'N/A',
          other_position: item.other_position,
          department: item.department,
          graduation_year: item.graduation_year,
          degree: item.degree,
          hostel_address: item.hostel_address,
          current_year: item.current_year,
          is_student: item.is_student,
          student_id: item.student_id,
        }));
        setTableData(formattedData);
      })
      .catch((error) => {
        toast.error(`Error fetching data: ${error.message || 'Unknown error occurred'}`);
        console.log('error', error);
      });
  };

  useEffect(() => {
    fetchAndFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleAccept = (row) => {
    setAcceptedRows([...acceptedRows, row.id]);
    setRejectedRows(rejectedRows.filter((rejectedRowId) => rejectedRowId !== row.id));

    const formData = new FormData();
    formData.append('accepted', true);
    formData.append('email', row.email);

    const response = UserRequestAPIs.AdminRequestAcceptDecline(formData);
    response
      .then((result) => {
        toast.success('Successfully accepted request!');
        fetchAndFilterData();
      })
      .catch((error) => {
        toast.error(`Error accepting request for ${row.email}: ${error.message || 'Unknown error occurred'}`);
        console.log('error', error);
      });
  };

  const handleReject = (row) => {
    setRejectedRows([...rejectedRows, row.id]);

    const formData = new FormData();
    formData.append('accepted', false);
    formData.append('email', row.email);

    const response = UserRequestAPIs.AdminRequestAcceptDecline(formData);
    response
      .then((result) => {
        toast.success('Declined request successfully!');
        fetchAndFilterData();
      })
      .catch((error) => {
        toast.error(`Error declining request for ${row.email}: ${error.message || 'Unknown error occurred'}`);
        console.log('error', error);
      });
  };

  const isAccepted = (row) => acceptedRows.includes(row.id);
  const isRejected = (row) => rejectedRows.includes(row.id);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredData(tableData); // No filter applied
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredData(
        tableData.filter(
          row => 
            (row.name && row.name.toLowerCase().includes(lowerCaseQuery)) ||
            (row.email && row.email.toLowerCase().includes(lowerCaseQuery))
        )
      );
    }
  }, [searchQuery, tableData]);

  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={params.row.profile_pic || '/default-avatar.png'} alt={params.row.name} />
          <div>
            <div>{params.row.name}</div>
            <div style={{ color: 'gray', fontSize: '0.875rem' }}>{params.row.email}</div>
          </div>
        </Box>
      ),
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          {params.row.other_position ? (
            <div>{params.row.other_position || positionLabels[params.row.position]}</div>
          ) : (
            <div>{positionLabels[params.row.position]}</div>
          )}
        </Box>
      ),
    },
    {
      field: 'groupName',
      headerName: 'Group Name',
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          <div>{params.row.TL_name}</div>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            padding: '0.25rem 0.75rem',
            alignItems: 'center',
            gap: '0.25rem',
            borderBottom: '1px solid #F2F2F2',
            background: '#FFF',
          }}
        >
          {!isRejected(params.row) && (
            <Button
              size="small"
              sx={{
                ...buttonStyle,
                color: '#057D35', // Action-Success color
                '&:hover': {
                  backgroundColor: '#90ee90',
                },
              }}
              onClick={() => handleAccept(params.row)}
              disabled={isAccepted(params.row)}
            >
              {isAccepted(params.row) ? (
                <>
                  Accepted <CheckCircleIcon style={{ marginLeft: '4px' }} />
                </>
              ) : (
                'Accept'
              )}
            </Button>
          )}

          {!isAccepted(params.row) && (
            <Button
              size="small"
              sx={{
                ...buttonStyle,
                color: '#C90505', // Action-Error color
                '&:hover': {
                  backgroundColor: '#FF474C',
                },
              }}
              onClick={() => handleReject(params.row)}
              disabled={isRejected(params.row)}
            >
              {isRejected(params.row) ? (
                <>
                  Declined <CancelIcon style={{ marginLeft: '4px' }} />
                </>
              ) : (
                'Decline'
              )}
            </Button>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}> {/* Increased height for better visibility */}
      <DataGrid
        rows={filteredData}
        columns={columns}
        pageSize={10} // Adjusted page size
        rowsPerPageOptions={[5, 10, 20]} // More pagination options
        checkboxSelection
        disableSelectionOnClick
        autoHeight
      />
      <ToastContainer />
    </Box>
  );
};

export default Request;
