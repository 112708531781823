import React, { useEffect, useRef } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import Chart from 'chart.js/auto';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust path if necessary

// Helper function to generate the last 30 days' dates
const generateLast30Days = () => {
  const today = new Date();
  const dates = [];

  // Generate the last 30 days
  for (let i = 0; i < 30; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i); // Go back by i days
    dates.push(date.toLocaleDateString('en-GB').slice(0,5)); // Format the date as DD/MM/YYYY
  }

  return dates.reverse(); // Reverse the array to have the earliest date first
};

// Dummy data for daily footfall with some dips (simulate 30 days)
const dummyData = {
    dates: generateLast30Days(), // Generate last 30 days
    footfall: [
        100, 120, 150, 180, 210, 240, 280, 310, 350, 380, 
        400, 420, 450, 470, 460, 470, 490, 510, 530, 550, 
        580, 590, 600, 610, 620, 630, 640, 650, 680, 700, // Increasing trend
        750, 800 // Strong increase in the end
    ], // Example daily footfall data with dips in the middle
};

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const FootfallChart = ({ data = dummyData }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Handle resizing of the chart
  useEffect(() => {
    const handleResize = debounce(() => {
      if (chartInstance.current) {
        try {
          chartInstance.current.resize();
        } catch (error) {
          console.error("ResizeObserver error:", error);
        }
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  // Create or update the chart when data changes
  useEffect(() => {
    if (data.dates.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const maxFootfall = Math.max(...data.footfall);
      const suggestedMax = Math.ceil(maxFootfall * 1.2);

      chartInstance.current = new Chart(chartRef.current, {
        type: 'line',
        data: {
          labels: data.dates, // Use the dynamically generated dates for the X-axis
          datasets: [
            {
              label: 'Footfall',
              data: data.footfall,
              borderColor: 'green', // Green color for the footfall line
              fill: false,          // No filling below the line
              tension: 0.3,         // Smooth curve
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
              ticks: {
                maxRotation: 45,
                minRotation: 45,
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Visitors',
              },
              beginAtZero: true,
              ticks: {
                stepSize: 50, // Makes sure the Y-axis increments by 50
              },
              suggestedMax: suggestedMax, // Adjusts max value to 1.2x the highest value
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <Paper elevation={3} style={{ height:'94%', padding: '10px', borderRadius: '16px', backgroundColor: '#f9fafe', border: '1px solid #b5b5b5', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box style={{ alignSelf: 'flex-start', marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" style={{ fontSize: '25px', fontWeight: '700', color: '#212121' }}>
          Daily Footfall Statistics
        </Typography>
      </Box>
      {data.dates.length > 0 ? (
        <Box style={{ width: '95%', height: '300px', position: 'relative' }}>
          <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="300px" width="100%">
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
          <Typography variant='h5' color="textSecondary">
            No data available
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default FootfallChart;
