import { axiosApiService } from "../axios";

const WorkbenchAPIs = {
    // Method to fetch a list of workbenches
    async getList() {
        try {
            const response = await axiosApiService.get('workbenches/');
            console.log('WorkbenchAPIs getList response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching the workbench list:', error);
            throw error;
        }
    },

    // Method to fetch details of a specific workbench by ID
    async getDetail(id) {
        try {
            const response = await axiosApiService.get(`workbenches/${id}/`);
            console.log(`WorkbenchAPIs getDetail response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error fetching the workbench detail for ID ${id}:`, error);
            throw error;
        }
    },

    // Method to create a new workbench
    async createWorkbench(data) {
        try {
            const response = await axiosApiService.post('workbenches/', data);
            console.log('WorkbenchAPIs createWorkbench response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error creating a new workbench:', error);
            throw error;
        }
    },

    // Method to update an existing workbench by ID
    async updateWorkbench(id, data) {
        try {
            const response = await axiosApiService.put(`workbenches/${id}/`, data);
            console.log(`WorkbenchAPIs updateWorkbench response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error updating the workbench with ID ${id}:`, error);
            throw error;
        }
    },

    // Method to delete a specific workbench by ID
    async deleteWorkbench(id) {
        try {
            const response = await axiosApiService.delete(`workbenches/${id}/`);
            console.log(`WorkbenchAPIs deleteWorkbench response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error deleting the workbench with ID ${id}:`, error);
            throw error;
        }
    },

    // -----------------LAYOUT IMAGE API METHODS-----------------

    // Method to fetch layout images (GET request)
    async getLayoutImage() {
        try {
            const response = await axiosApiService.get(`layout_image/`);
            console.log('WorkbenchAPIs getLayoutImage response:', response.data);
            return response.data;
        } catch (error) {
            console.error(`Error fetching layout image for workbench`, error);
            throw error;
        }
    },

    // Method to fetch layout images (GET request)
    async getDetailLayoutImage(id) {
        try {
            const response = await axiosApiService.get(`layout_image_detail/${id}/`);
            console.log('WorkbenchAPIs getDetailLayoutImage response:', response.data);
            return response.data;
        } catch (error) {
            console.error(`Error fetching layout image for workbench with ID: ${id}`, error);
            throw error;
        }
    },

    // Method to upload a new layout image (POST request)
    async uploadLayoutImage(data) {
        try {
            const response = await axiosApiService.post('layout_image/', data);
            console.log('WorkbenchAPIs uploadLayoutImage response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error uploading layout image:', error);
            throw error;
        }
    },

    // Method to update layout image via PUT request (replace entire image)
    async updateLayoutImage(id,data) {
        try {
            const response = await axiosApiService.put(`layout_image_detail/${id}/`, data);
            console.log(`WorkbenchAPIs updateLayoutImage response for ID:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error updating the layout image for ID:`, error);
            throw error;
        }
    },

    // Method to update layout image via PATCH request (partial update)
    async patchLayoutImage(id,data) {
        try {
            const response = await axiosApiService.patch(`layout_image_detail/${id}/`, data);
            console.log(`WorkbenchAPIs patchLayoutImage response for ID `, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error patching the layout image for ID `, error);
            throw error;
        }
    },

    // Method to fetch layout images (GET request)
    async deleteLayoutImage(id) {
        try {
            const response = await axiosApiService.delete(`layout_image_detail/${id}/`);
            console.log('WorkbenchAPIs deleteLayoutImage response:', response.data);
            return response.data;
        } catch (error) {
            console.error(`Error deleting layout image for workbench with ID: ${id}`, error);
            throw error;
        }
    },
};

export default WorkbenchAPIs;
