import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, LinearProgress, Grid } from '@mui/material';

// Dummy data for live occupancy (simulated data)
const dummyOccupancy = {
  totalCapacity: 1000,  // Total capacity of the space (e.g., a building, room, etc.)
  currentOccupancy: 480, // Current number of people occupying the space
  maxOccupancyToday: 900, // Max occupancy today (simulated)
  lastThreeDays: [700, 800, 650], // Simulated occupancy for the last 3 days
};

const LiveOccupancy = ({ data = dummyOccupancy }) => {
  const [currentOccupancy, setCurrentOccupancy] = useState(data.currentOccupancy);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate a live update by randomly changing the occupancy
      setLoading(true);
      setTimeout(() => {
        const newOccupancy = Math.min(data.totalCapacity, currentOccupancy + Math.floor(Math.random() * 10));
        setCurrentOccupancy(newOccupancy);
        setLoading(false);
      }, 500);
    }, 60000); // Update every 1min

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [currentOccupancy, data.totalCapacity]);

  const occupancyPercentage = ((currentOccupancy / data.totalCapacity) * 100).toFixed(2);

  return (
    <Paper elevation={3} style={{ padding: '20px', borderRadius: '16px', backgroundColor: '#f9fafe', width: '95%', height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" style={{ fontSize: '22px', fontWeight: '700', color: '#212121' }}>
          Live Occupancy Status
        </Typography>
        <Typography variant="h5" style={{ fontSize: '30px', fontWeight: '500', color: '#2196F3' }}>
          {currentOccupancy} / {data.totalCapacity}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" >
          {occupancyPercentage}% Occupied
        </Typography>
      </Box>

      {loading ? (
        <Typography variant="body1" color="textSecondary">
          Updating occupancy...
        </Typography>
      ) : (
        <Box style={{ width: '100%', marginTop: '0px' }}>
          <LinearProgress
            variant="determinate"
            value={occupancyPercentage}
            color={occupancyPercentage > 80 ? 'error' : 'primary'}
            style={{ height: '10px' }}
          />
        </Box>
      )}

      {/* Today's Maximum Occupancy */}
      <Box style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
        <Typography variant="h6" style={{ fontSize: '20px', fontWeight: '700', color: '#212121' }}>
          Today's Maximum Occupancy
        </Typography>
        <Typography variant="h4" style={{ fontSize: '30px', fontWeight: '500', color: '#f44336' }}>
          {data.maxOccupancyToday} / {data.totalCapacity}
        </Typography>
        {/* <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: '10px' }}>
          {((data.maxOccupancyToday / data.totalCapacity) * 100).toFixed(2)}% of capacity
        </Typography> */}
      </Box>

      {/* Last 3 Days Occupancy */}
      <Box style={{ marginTop: '6px', width: '100%' }}>
        <Typography variant="h6" style={{ fontSize: '20px', fontWeight: '700', color: '#212121' }}>
          Occupancy - Last 3 Days
        </Typography>
        <Grid container spacing={3} justifyContent="center" style={{ marginTop: '-20px' }}>
          {data.lastThreeDays.map((occupancy, index) => (
            <Grid item xs={4} key={index} style={{ textAlign: 'center' }}>
              <Typography variant="h6" style={{ fontSize: '18px', fontWeight: '500' }}>
                Day {index + 1}
              </Typography>
              <Typography variant="h5" style={{ fontSize: '24px', fontWeight: '500', color: '#2196F3' }}>
                {occupancy} / {data.totalCapacity}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {((occupancy / data.totalCapacity) * 100).toFixed(2)}% of capacity
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

export default LiveOccupancy;
