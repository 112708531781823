const reportData = [
    {
        id: 1,
        issueTitle: 'Bookings',
        issueInfo: [
            'Unable to cancel booking',
            'Booking done but not reflecting in records',
            'Booked a machine but was cancelled',
            'Accidently booked for the wrong date/time', // corrected spelling
            'Machine not working',
            'Other'
        ]
    },
    {
        id: 2,
        issueTitle: 'Messaging',
        issueInfo: [
            'Not receiving notifications',
            'How to message the admin',
            'Other'
        ],
    },
    {
        id: 3,
        issueTitle: 'Workshops',
        issueInfo: [
            'Workshop unaccessible',
            'Workshop completed but not visible in \'Completed Workshop\'',
            'Workshop videos not working',
            'Other'
        ],
    },
    {
        id: 4,
        issueTitle: 'Records',
        issueInfo: [
            'Booking done but not reflecting in records',
            'Records not updating',
            'Other'
        ],
    },
    {
        id: 5,
        issueTitle: 'Login',
        issueInfo: [
            'Not able to login',
            'How to delete your account',
            'How to change password',
            'Other'
        ],
    },
    {
        id: 6,
        issueTitle: 'Issues/Purchases',
        issueInfo: [
            'How to extend issue date',
            'Inaccurate inventory data',
            'Equipment not in desired condition',
            'Borrowed an item but was actually unavailable',
            'Other'
        ],
    },
    {
        id: 7,
        issueTitle: 'Web Application',
        issueInfo: [
            'Unclear navigation',
            'Button not working',
            'Not receiving notifications',
            'Other'
        ],
    },
    {
        id: 8,
        issueTitle: 'Other',
        issueInfo: [],
    },
]

export default reportData;
