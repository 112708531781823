import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { MenuComponent } from '../components/Menu';
import NavBar from '../components/Navbar/Navbar';
import { ToastContainer } from 'react-toastify';
import TopbarTraining from '../components/training/TopbarTraining';
import WorkshopDetailTopBar from '../components/training/workshop/EditAndAttendance/WorkshopDetailTopBar';
import TutorialDetailTopBar from '../components/training/Tutorial/ModuleAndStats/TutorialDetailTopBar';
import { toggleEditMode } from '../redux/slices/editModeSlice'; // Adjust the path as per your file structure

const Training = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const isEdit = useSelector((state) => state.editMode.editMode);
  const isNewWorkshop = useSelector((state) => state.editMode.isNewWorkshop);
  const isTutEditMode = useSelector((state) => state.tutorialEdit.tutEditMode); // Get the tutEditMode state
  const isNewTut = useSelector((state) => state.tutorialEdit.isNewTut); // Get the isNewTut state

  // LOCATIONS DISSPATCH CALLS MADE : 
  // Workshop Page-> Dispatch request being made in the Table Row component (icons and function defined there) and upon saving/ canceling
  // Tutorial Page-> Dispatch request are being made in the List and Grid View components as there are the icons defined and upon changing page

  // const handleEditMode = () => {
  //   dispatch(toggleEditMode());
  // };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ width: collapsed ? '7%' : '17.5%' }}>
        <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <NavBar />
        {(isTutEditMode || isNewTut) ? ( // Check for tutEditMode or isNewTut
          <TutorialDetailTopBar />
        ) 
        : 
        (isEdit || isNewWorkshop) ? (
          <WorkshopDetailTopBar />
        ) : (
          <TopbarTraining />
        )}
      </Box>
      <ToastContainer /> {/* Adjust as per your requirements */}
    </Box>
  );
};

export default Training;



// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import Box from '@mui/material/Box';
// import { MenuComponent } from '../components/Menu';
// import NavBar from '../components/Navbar/Navbar';
// import { ToastContainer } from 'react-toastify';
// import TopbarTraining from '../components/training/TopbarTraining';
// import WorkshopDetailTopBar from '../components/training/workshop/EditAndAttendance/WorkshopDetailTopBar';
// import TutorialDetailTopBar from '../components/training/Tutorial/ModuleAndStats/TutorialDetailTopBar';
// import { toggleEditMode } from '../redux/slices/editModeSlice'; // Adjust the path as per your file structure
// // import { toggleTutEditMode } from '../redux/slices/tutorialEditSlice'; // Import the action for toggling tutorial edit mode

// const Training = ({ collapsed, setCollapsed }) => {
//   const dispatch = useDispatch();
//   const isEdit = useSelector((state) => state.editMode.editMode);
//   const isNewWorkshop = useSelector((state) => state.editMode.isNewWorkshop);
//   const isTutEditMode = useSelector((state) => state.tutorialEdit.tutEditMode); // Get the tutEditMode state

//   // LOCATIONS DISSPATCH CALLS MADE : 
//   // Workshop Page-> Dispatch request being made in the Table Row component (icons and function defined there) and upon saving/ canceling
//   // Tutorial Page-> Dispatch request are being made in the List and Grid View components as there are the icons defined and upon changing page

//   // const handleEditMode = () => {
//   //   dispatch(toggleEditMode());
//   // };

//   return (
//     <Box sx={{ display: 'flex', height: '100vh' }}>
//       <Box sx={{ width: collapsed ? '7%' : '17.5%' }}>
//         <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
//       </Box>
//       <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
//         <NavBar />
//         {isTutEditMode ? ( // Check for tutEditMode
//           <TutorialDetailTopBar />
//         ) : isEdit || isNewWorkshop ? (
//           <WorkshopDetailTopBar />
//         ) : (
//           <TopbarTraining />
//         )}
//       </Box>
//       <ToastContainer /> {/* Adjust as per your requirements */}
//     </Box>
//   );
// };

// export default Training;