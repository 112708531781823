import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { MenuComponent } from '../components/Menu';
import NavBar from '../components/Navbar/Navbar';
import MachineTable from '../components/Inventory/MachineTable';
import EquipmentsTable from '../components/Inventory/EquipmentsTable';
import InventoryTable from '../components/Inventory/InventoryTable';
import WorkbenchTable from '../components/Inventory/WorkbenchTable';  // Import the new WorkbenchTable component
import TopBarComponent from '../components/Inventory/TopBar';
import SearchBar from '../components/Inventory/SearchBar';
import { useDispatch } from 'react-redux';
import InventoryCategoryAPIs from '../utilities/api/category/AdminInventoryCategoryList';
import InventoryGroupAPIs from '../utilities/api/group/AdminInventoryGroupList';
import InventoryLocationAPIs from '../utilities/api/location/AdminLocationList';
import { getCategorySuccess, getCategoryFail } from '../redux/slices/categorySlice';
import { getLocationSuccess, getLocationFail } from '../redux/slices/locationSlice';
import { getGroupSuccess, getGroupFail } from '../redux/slices/groupSlice';

const Inventory = ({ collapsed, setCollapsed }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [category, setCategory] = useState([]);
  const [group, setGroup] = useState([]);
  const [tag, setTag] = useState([]);
  const [update, setUpdate] = useState(false);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const response = InventoryCategoryAPIs.CategoryListGet();
    response
      .then((result) => {
        dispatch(getCategorySuccess(result));
      })
      .catch((error) => {
        dispatch(getCategoryFail(error));
      });
  }, [flag]);

  useEffect(() => {
    const response = InventoryGroupAPIs.GroupListGet();
    response
      .then((result) => {
        dispatch(getGroupSuccess(result));
      })
      .catch((error) => {
        dispatch(getGroupFail(error));
      });
  }, [flag]);

  useEffect(() => {
    const response = InventoryLocationAPIs.LocationListGet();
    response
      .then((result) => {
        dispatch(getLocationSuccess(result));
      })
      .catch((error) => {
        dispatch(getLocationFail(error));
      });
  }, [flag]);

  const renderTable = () => {
    switch (activeTab) {
      case 0:
        return <MachineTable searchQuery={searchQuery} category={category} group={group} tag={tag} update={update} setUpdate={setUpdate} flag={flag} setFlag={setFlag} />;
      case 1:
        return <EquipmentsTable searchQuery={searchQuery} category={category} update={update} setUpdate={setUpdate} flag={flag} setFlag={setFlag} />;
      case 2:
        return <InventoryTable searchQuery={searchQuery} category={category} group={group} tag={tag} update={update} setUpdate={setUpdate} flag={flag} setFlag={setFlag} />;
      case 3: // New case for the Workbench tab
        return <WorkbenchTable searchQuery={searchQuery} update={update} setUpdate={setUpdate} flag={flag} setFlag={setFlag} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ width: collapsed ? '7%' : '17.5%' }}>
        <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <NavBar />
        <TopBarComponent activeTab={activeTab} setActiveTab={setActiveTab} update={update} setUpdate={setUpdate} flag={flag} setFlag={setFlag} />
        <SearchBar activeTab={activeTab} searchQuery={searchQuery} setSearchQuery={setSearchQuery} category={category} setCategory={setCategory} group={group} setGroup={setGroup} tag={tag} setTag={setTag} />
        {renderTable()}
      </Box>
    </Box>
  );
};

export default Inventory;
