import  axiosAuthService  from "./axios";

const AdminPasswordAPIs = {

    ForgotPassword: async function (values) {
        try {
            const response = await axiosAuthService.post(`/auth/forgot_password/`, values, {
            });

            console.log('Response:', response.data);
            return response.data; // Assuming the server returns the data
        } catch (error) {
            console.error('Error creating project:', error);
            throw error;
        }
    },

    ResetPassword: async function (values) {
        try {
            const response = await axiosAuthService.post(`/auth/reset_password/`, values, {
            });

            console.log('Response:', response.data);
            return response.data; // Assuming the server returns the data
        } catch (error) {
            console.error('Error creating project:', error);
            throw error;
        }
    },
};

export default AdminPasswordAPIs;