import { axiosApiService } from "../axios";

const AdminAttendanceList = {
    AttendanceListGet: async function (workshopID, token) {
        
        const response = await axiosApiService.get(`training/workshops/${workshopID}/attendees/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log((response));
        return response.data
    },
    
}
export default AdminAttendanceList;