import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AdminPasswordAPIs from "../../utilities/api/AdminPasswordAPIs";
import { toast, ToastContainer } from 'react-toastify';
import Logo from '../../utilities/dummy_assets/MBF_Logo.png'; // Use your actual logo file
import mapITlogo from '../../utilities/dummy_assets/mapITlogo.png'; // Use your actual mapIT logo

const styledTextField = {
    mt:-1,
    fontSize:'24px',
    width: "460px", // Set width to Fill (460px)
    padding: "16px", // Custom padding
    borderRadius: "12px", // Custom border radius
    "& .MuiOutlinedInput-root": {
        borderRadius: "12px",
        "& fieldset": {
            border: "1px solid #B5B5B5", // Custom border
        },
        "&:hover fieldset": {
            borderColor: "#007BFF", // Border color on hover
        },
        "&.Mui-focused fieldset": {
            borderColor: "#007BFF", // Border color when focused
        },
    },
};

const validation_schema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
});

const ForgotPasswordPage = () => {
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState("");
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            setSubmittedEmail(values.email);
            AdminPasswordAPIs.ForgotPassword(values)
                .then(() => {
                    setEmailSubmitted(true);
                    toast.success('Password reset email sent successfully!');
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Failed to send email. Please try again.');
                });
        },
    });

    // Warn user when they try to refresh or leave the page
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ""; // Some browsers require this for custom message
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <Box
            sx={{
                background: '#F7F7F7',
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "12px",
                    left: "50px",
                }}
            >
                <img src={Logo} alt="MBF Logo" style={{ height: '69px', width: '159px' }} />
            </Box>

            <Box
                sx={{
                    position: "absolute",
                    top: "44px",
                    right: "104px",
                }}
            >
                <Typography
                    onClick={() => window.location.href = "https://tinkerspace.mis.mapit.ai/"}
                    sx={{
                        color: "#0062CC",
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "16px",
                        fontWeight: 500,
                    }}
                >
                    Go to student site
                </Typography>
            </Box>

            <Box
                sx={{
                    padding: "2rem",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1.5rem",
                    textAlign: "center",
                    maxWidth: "460px",
                    width: "100%",
                }}
            >
                {!emailSubmitted ? (
                    <>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "36px",
                                fontWeight: 600,
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#212121",
                            }}
                        >
                            Forgot Your Password?
                        </Typography>

                        <Typography
                            sx={{
                                mt: 1,
                                fontFamily: "Inter",
                                fontSize: "24px",
                                fontWeight: 400,
                                lineHeight: "29.05px",
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#5C5C5C",
                            }}
                        >
                            We will send you the instruction to the email address below to reset your password.
                        </Typography>

                        <Box
                            component="form"
                            onSubmit={formik.handleSubmit}
                            sx={{ width: "100%", p: 1, mt: 2 }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "24px", 
                                        fontWeight: 400,
                                        alignSelf: "flex-start",
                                    }}
                                >
                                    Email Address
                                </Typography>
                                
                                <TextField
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    sx={styledTextField}
                                    placeholder="Enter email address"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Box>

                            <Button
                                type="submit"
                                sx={{
                                    height: "48px",
                                    marginTop: "1rem",
                                    padding: "1rem",
                                    fontSize:'20px',
                                    width: "100%",
                                    borderRadius: "12px",
                                    background: "#007BFF",
                                    color: "#FFF",
                                    '&:hover': {
                                        background: "#0056b3",
                                    }
                                }}
                            >
                                <Typography sx={{ fontFamily: "Inter", fontSize: "1.25rem", fontWeight: 600 }}>
                                    Reset my password
                                </Typography>
                            </Button>
                        </Box>

                        <Typography
                            onClick={() => navigate("/login")}
                            sx={{
                                marginTop: "1rem",
                                fontSize: "20px",
                                color: "#007BFF",
                                cursor: "pointer",
                            }}
                        >
                            Back to login
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "var(--Size360)",
                                fontWeight: 600,
                                lineHeight: "43.57px",
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#212121",
                            }}
                        >
                            Check Your Email
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "var(--Size240)",
                                fontWeight: 400,
                                lineHeight: "29.05px",
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#5C5C5C",
                            }}
                        >
                            Please check the email address {submittedEmail} for instructions to reset your password.
                        </Typography>
                    </>
                )}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "16px",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '24px',
                        marginRight: '8px',
                    }}
                >
                    Powered by
                </Typography>
                <img src={mapITlogo} alt="mapIT Logo" style={{ height: '40px' }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Times New Roman',
                        fontSize: '14px',
                        marginLeft: '8px',
                    }}
                >
                    mapIT.ai
                </Typography>
            </Box>

            <ToastContainer />
        </Box>
    );
};

export default ForgotPasswordPage;
