import axiosAuthService from "../axios";

const AdminMessagingAPIs = {
    GetThreadList: async function () {
        try {
            const response = await axiosAuthService.get(`/chat/threads/`);
            console.log('Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error getting Thread list:', error);
            throw error;
        }
    },

    CreateThread: async function (threadData) {
        try {
            const response = await axiosAuthService.post(`/chat/threads/`, threadData);
            console.log('Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error creating thread:', error);
            throw error;
        }
    },

    GetThreadDetail: async function (id) {
        try {
            const response = await axiosAuthService.get(`/chat/threads/${id}/`);
            console.log('Response:', response.data);
            return response.data;
        } catch (error) {
            console.error(`Error getting thread detail for ID ${id}:`, error);
            throw error;
        }
    },

    PostMessage: async function (id, messageData) {
        try {
            const response = await axiosAuthService.post(`/chat/threads/${id}/messages/`, messageData);
            console.log('Response:', response.data);
            return response.data;
        } catch (error) {
            console.error(`Error posting message to thread ID ${id}:`, error);
            throw error;
        }
    },
};

export default AdminMessagingAPIs;
