import { axiosApiService } from "../axios";

const AdminBarcodeUPCsAPIs = {
    GetUPCData: async function (barcode) {
        try {
            const apiKey = process.env.REACT_APP_UPC_API_TOKEN;

            if (!apiKey) {
                throw new Error("API Key is not set in the environment variables.");
            }

            const response = await axiosApiService.get(`https://api.upcdatabase.org/product/${barcode}`, {
            // const response = await axiosApiService.get(`https://api.upcitemdb.com/prod/trial/lookup?upc=${barcode}`, {
                headers: {
                    "Authorization": `Bearer ${apiKey}`
                }
            });

            const data = response.data;
            return data;
        } catch (error) {
            console.error(`Error fetching UPC data for barcode ${barcode}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    }
};

export default AdminBarcodeUPCsAPIs;
