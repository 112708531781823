import { axiosApiService } from "../axios";

const availabilityMapping = {
  'Moderate': 2,
  'Very Low': 0,
  'Low': 1,
  'High': 3,
  'Always': 4
};

const MachineDetailAPIs = {
  MachineDetailGet: async function(id) {
    try {
      const response = await axiosApiService.get(`/admin_machine_detail/${id}/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching machine detail:", error);
      throw error;
    }
  },
  MachineDetailGetObject: async function(primaryKey) {
    try {
      const response = await axiosApiService.get(`/admin_machine_detail/${primaryKey}/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching machine detail object:", error);
      throw error;
    }
  },
  MachineDetailPatch: async function(primaryKey, patchData) {
    try {
      const response = await axiosApiService.patch(`/admin_machine_detail/${primaryKey}/`, patchData);
      return response.data;
    } catch (error) {
      console.error("Error patching machine detail:", error);
      throw error;
    }
  },
  MachineDetailPut: async function(primaryKey, values, token) {
    try {
      const response = await axiosApiService.put(`/admin_machine_detail/${primaryKey}/`, values, {
        headers: { 
          "Authorization": `Bearer ${token}` // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating machine detail:", error);
      throw error;
    }
  },
  MachineDetailDelete: async function(id, token) {
    try {
      const response = await axiosApiService.delete(`/admin_machine_detail/${id}/`, {
        headers: { 
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Bearer ${token}` // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting machine detail:", error);
      throw error;
    }
  }
};

export default MachineDetailAPIs;
