// ProfilePage.jsx
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import AdminUserDetail from '../utilities/api/user/AdminUserDetail';
import { MenuComponent } from '../components/Menu';
import NavBar from '../components/Navbar/Navbar';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ProfileImage from '../components/profile/ProfileImage';
import CollegeInfo from '../components/profile/CollegeInfo';
import PersonalInfo from '../components/profile/PersonalInfo';

const ProfilePage = ({collapsed, setCollapsed}) => {
  const {user} = useSelector((state) => state.user.user); 
  const userId = user.id // Get user ID from Redux
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await AdminUserDetail.AdminDetailGet(userId);
      setUserData(data);
    } catch (err) {
      setError('Failed to fetch user data');
      console.error('Error fetching user data:', err);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Box sx={{ display: "flex", height: "auto", overflowX: "hidden" }}>
      <Box sx={{ border: '1px solid blue' }}>
        <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>

      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", marginLeft: collapsed ? '7%' : '17.5%', border: '1px solid green' }}>
        <NavBar />
        <Box >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                padding: "1rem",
                color: "#007BFF",
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              My Profile
            </Typography>
          </Box>

          {/* <div> */}
            <ProfileImage user={userData} refreshUserData={fetchUserData} /> {/* Pass refresh function */}
            <PersonalInfo user={userData} refreshUserData={fetchUserData} /> {/* Pass refresh function */}
            <CollegeInfo user={userData} refreshUserData={fetchUserData} /> {/* Pass refresh function */}
          {/* </div> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;

