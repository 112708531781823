import { axiosApiService } from "../axios";

const AdminReservationList = {
    ReservationListGet: async function () {
        try {
            const response = await axiosApiService.get(`/machine_reservation_list/`);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    ReservationListPost: async function ( postData) {
        try {
            const response = await axiosApiService.post(`/machine_reservation_list/`, postData);
            return response.data;
        } catch (error) {
            console.error('Error posting data:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    ReservationDetailGet: async function (id) {
        try {
            const response = await axiosApiService.get(`/machine_reservation_detail/${id}/`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    ReservationDetailPatch: async function (id, updateData) {
        try {
            const response = await axiosApiService.patch(`/machine_reservation_detail/${id}/`, updateData);
            return response.data;
        } catch (error) {
            console.error(`Error updating detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    ReservationDetailDelete: async function (id) {
        try {
            const response = await axiosApiService.delete(`/machine_reservation_detail/${id}/`);
            return response.data;
        } catch (error) {
            console.error(`Error deleting detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    }
};

export default AdminReservationList;
