import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Typography,
  InputBase,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  useTheme,
  useMediaQuery,
  Checkbox,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import nodatafound from '../../utilities/dummy_assets/No data-cuate 1.jpg';
import AdminInventoryIssueAPIs from '../../utilities/api/inventory/AdminInventoryIssueAPIs';
import InventoryDetailAPIs from '../../utilities/api/inventory/AdminInventoryDetail';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';  // Import CircularProgress

const IssuableTable = () => {
  const user = useSelector((state) => state.user.user);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState([]);
  const [names, setNames] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [pickupStatus, setPickupStatus] = useState([]);
  const [selectedPickupStatus, setSelectedPickupStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [returnedStatus, setReturnedStatus] = useState([]);
  const [selectedReturnedStatus, setSelectedReturnedStatus] = useState([]);
  const [toggleState, setToggleState] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({ id: null, status: null });

  const fetchData = async () => {
    try {
      const data = await AdminInventoryIssueAPIs.InventoryIssueListGet();
      setRows(data.reverse());
      setProjects([...new Set(data.map((item) => item.project_name))]);
      setNames([...new Set(data.map((item) => item.name))]);
      setPickupStatus([...new Set(data.map((item) => item.pickup_))]);
      setReturnedStatus([...new Set(data.map((item) => item.returned_))]);
      setToggleState(data.reduce((acc, item) => ({ ...acc, [item.id]: item.pickup === '0' }), {}));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const handleProjectChange = (event) => setProject(event.target.value);
  const handleNameChange = (event) => setSelectedName(event.target.value);
  const handlePickupStatusChange = (event) => setSelectedPickupStatus(event.target.value);
  const handleSearchChange = (event) => setSearchQuery(event.target.value);
  const handleReturnedStatusChange = (event) => setSelectedReturnedStatus(event.target.value);

  const formatCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleToggleChange = (id, newValue) => async () => {
    const currentDate = formatCurrentDate();
    const payload = {
      pickup: newValue ? '0' : '1',
      pickup_on: newValue ? currentDate : null,
    };

    setToggleState((prev) => ({ ...prev, [id]: newValue }));
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, pickup: payload.pickup, pickup_on: payload.pickup_on } : row))
    );

    try {
      await AdminInventoryIssueAPIs.InventoryIssueDetailPatch(id, payload);
    } catch (error) {
      console.error(`Error updating detail for ID ${id}:`, error.response?.data || error.message);
    }
  };

  const handleReturnStatusChange = (id) => (event) => {
    const newStatus = event.target.value;
    if (newStatus === '0') {
      setDialogContent({ id, status: newStatus });
      setDialogOpen(true);
    } else {
      updateReturnStatus(id, newStatus);
    }
  };

  const updateReturnStatus = async (id, status) => {
    const currentDate = formatCurrentDate();
    const payload = {
      returned: status,
      returned_on: ['0', '3', '4', '5'].includes(status) ? currentDate : null,
    };

    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, returned: payload.returned, returned_on: payload.returned_on } : row))
    );

    try {
      await AdminInventoryIssueAPIs.InventoryIssueDetailPatch(id, payload);
      if (status === '0') {
        const row = rows.find((row) => row.id === id);
        if (row) {
          const inventoryDetail = await InventoryDetailAPIs.InventoryDetailGet(row.inventory);
          const updatedStockPayload = {
            stock_available: parseInt(inventoryDetail.stock_available, 10) + parseInt(row.quantity, 10),
            stock_total: parseInt(inventoryDetail.stock_total, 10) + parseInt(row.quantity, 10),
          };
          await InventoryDetailAPIs.InventoryDetailPatch(row.inventory, updatedStockPayload);
        }
      }
    } catch (error) {
      console.error(`Error updating return status for ID ${id}:`, error.response?.data || error.message);
    }
  };

  const handleDialogClose = async (confirm) => {
    if (confirm) {
      const { id, status } = dialogContent;
      await updateReturnStatus(id, status);
    }
    setDialogOpen(false);
  };

  const filteredRows = rows.filter((row) => {
    const matchesProject = !project.length || project.includes(row.project_name);
    const matchesName = !selectedName.length || selectedName.includes(row.name);
    const matchesPickupStatus = !selectedPickupStatus.length || selectedPickupStatus.includes(row.pickup_);
    const matchesReturnedStatus = !selectedReturnedStatus.length || selectedReturnedStatus.includes(row.returned_);
    const searchText = searchQuery.toLowerCase();
    const matchesSearchQuery =
      !searchQuery ||
      row.name?.toLowerCase().includes(searchText) ||
      row.project_name?.toLowerCase().includes(searchText) ||
      row.taker?.toLowerCase().includes(searchText) ||
      row.issued_from?.toLowerCase().includes(searchText) ||
      row.issued_till?.toLowerCase().includes(searchText) ||
      row.quantity.toString().includes(searchText);

    return matchesProject && matchesName && matchesPickupStatus && matchesReturnedStatus && matchesSearchQuery;
  });

  const columns = [
    {
      field: 'itemDetails',
      headerName: 'Item Details',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <img
            src={row.imageUrl || nodatafound}
            alt={row.name || 'No Text'}
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          />
          <Box>
            <Typography variant="subtitle2">{row.name || 'No Text'}</Typography>
            <Typography variant="body2" sx={{ color: 'blue' }}>
              {row.issued_from || 'No Subtext'}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.5,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'period',
      headerName: 'Period',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Typography>
          {row.issued_from} To {row.issued_till}
        </Typography>
      ),
    },
    {
      field: 'taker',
      headerName: 'Student Name',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'project_name',
      headerName: 'Project Name',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'action',
      headerName: 'Picked',
      sortable: false,
      width: 120,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <IconButton color={toggleState[row.id] ? 'success' : 'default'} onClick={handleToggleChange(row.id, true)}>
            <CheckCircleIcon color={toggleState[row.id] ? 'success' : 'default'} />
          </IconButton>
          <IconButton color={!toggleState[row.id] ? 'error' : 'default'} onClick={handleToggleChange(row.id, false)}>
            <CancelIcon color={!toggleState[row.id] ? 'error' : 'default'} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'returned',
      headerName: 'Returned',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Select
          value={row.returned || ''}
          onChange={handleReturnStatusChange(row.id)}
          displayEmpty
          sx={{ width: '100%', height: '75%' }}
          disabled={row.pickup === '1' || row.returned === '0'}
        >
          <MenuItem value="0">Yes</MenuItem>
          <MenuItem value="1">No</MenuItem>
          <MenuItem value="2">Waiting</MenuItem>
          <MenuItem value="3">Invalid</MenuItem>
          <MenuItem value="4">Lost</MenuItem>
          <MenuItem value="5">Damaged</MenuItem>
        </Select>
      ),
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: isMobile ? 1 : 0,
          width: '100%',
          marginBlock: '1rem',
          boxSizing: 'border-box',
        }}
      >
        {[
          { label: 'Select Project', value: project, onChange: handleProjectChange, items: projects },
          { label: 'Select Item Name', value: selectedName, onChange: handleNameChange, items: names },
          { label: 'Select Pickup Status', value: selectedPickupStatus, onChange: handlePickupStatusChange, items: pickupStatus },
          { label: 'Select Returned Status', value: selectedReturnedStatus, onChange: handleReturnedStatusChange, items: returnedStatus },
        ].map(({ label, value, onChange, items }, index) => (
          <FormControl key={index} sx={{ minWidth: 120 }}>
            <InputLabel shrink sx={{ marginLeft: '18px' }}>{label}</InputLabel>
            <Select
              multiple
              value={value}
              onChange={onChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ height: '3rem', width: '50%', ml: '0rem', mr: '1rem', minWidth: '200px' }}
              input={<OutlinedInput label={label} />}
              renderValue={(selected) => selected.join(', ')}
            >
              {items.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={value.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
        <InputBase
          sx={{
            marginLeft: 'auto',
            maxWidth: '250px',
            flex: 1,
            height: '3rem',
            border: '0.5px solid #B5B5B5',
            borderRadius: '10px',
            bgcolor: '#F4F4F4',
            '& .MuiInputBase-input': { paddingLeft: '.1rem' },
          }}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress 
            size={60} 
            thickness={4.5} 
            sx={{ color: '#273F77' }} // Customize color to match your theme
          />
          <Typography 
            variant="h6" 
            sx={{ color: '#273F77', fontFamily: 'Inter', fontWeight: 500, marginTop: 2 }}
          >
            Loading...
          </Typography>
        </Box>
      ) : filteredRows.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            marginTop: '100px',
            padding: '32px 24px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '21px',
          }}
        >
          <Box component="img" src={nodatafound} sx={{ width: '211.889px', height: '196.824px', flexShrink: '0' }} />
          <Typography
            sx={{
              color: 'var(--s-primary-source, #273F77)',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: '26px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '150%',
              letterSpacing: '-0.352px',
            }}
          >
            No Records Found!
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          disableExport
          disableMultipleColumnsSorting
          sortingOrder={[]}
          disableColumnSort
          sx={{
            '& .header-style': {
              fontSize: '1.2rem',
              fontWeight: 'bold',
            },
          }}
        />
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Return"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure about the return quality and count? Once updated, return status cannot be changed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IssuableTable;
