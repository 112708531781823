import { axiosApiService } from "../axios";

const AdminCreateWorkshopAPIs = {    
    
    CreateWorkshopPost: async function(values, token) {
        if (!token) {
            throw new Error('Access token is missing.');
        }
        try {
            console.log('Adding Attendee:', values);
            const response = await axiosApiService.post(`/training/workshops/`, values, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Create Workshop Response:', response);
            return response.data; 
        } catch (error) {
            console.error('Error creating workshop:', error.response ? error.response.data : error.message);
            alert('Seems like tokens have expired. Please log in again');
            throw error;
        }
    },

   
}
export default AdminCreateWorkshopAPIs;