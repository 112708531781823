import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Auth_APIs from "../../utilities/api/auth_api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserSuccess, getUserFail } from "../../redux/slices/userSlice";
import { toast, ToastContainer } from 'react-toastify';
import Logo from '../../utilities/dummy_assets/MBF_Logo.png';
import mapITlogo from '../../utilities/dummy_assets/mapITlogo.png';
import expandleft from "../../utilities/dummy_assets/Expand_left.png";

const typographyStyle = {
    color: "var(--Primary-Primary03, #5C5C5C)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.0165rem",
};

const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: ".6rem",
    alignSelf: "stretch",
};

const styledTextField = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
        borderRadius: "12px",
        "& fieldset": {
            border: "1.5px solid #B5B5B5",  // Set custom border
        },
        "&:hover fieldset": {
            borderColor: "#007BFF",  // Border color on hover
        },
        "&.Mui-focused fieldset": {
            borderColor: "#007BFF",  // Border color when focused
        },
    },
};

const OrganizationAdmin = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        email: "",
        password: "",
    });

    const dispatch = useDispatch();
    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleClick = () => {
        Auth_APIs.adminLogin(userData)
            .then((result) => {
                dispatch(getUserSuccess(result));
                navigate("/dashboard");
            })
            .catch((error) => {
                dispatch(getUserFail(error));
                toast.error('Wrong credentials. Try again!');
            });
    };

    const { email, password } = userData;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", background: '#F7F7F7', justifyContent: "center", alignItems: "center" }}>
            {/* Logo in the Top Left */}
            <Box
                sx={{
                    position: "absolute",
                    top: "12px",
                    left: "50px",
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <img src={Logo} alt="MBF Logo" style={{ height: '69px', width: '159px' }} />
            </Box>

            {/* "Go to Student Site" Link in the Top Right */}
            <Box
                sx={{
                    position: "absolute",
                    top: "44px",
                    right: "104px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Typography
                    component="span"
                    onClick={() => window.location.href = "https://tinkerspace.mis.mapit.ai/"}
                    sx={{
                        color: "var(--Accent-Accent-01, #0062CC)",
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "16px",
                        fontWeight: 500,
                    }}
                >
                    Go to Student Site
                </Typography>
            </Box>

            {/* Form Section */}
            <Box
                sx={{
                    mt:15,
                    width: "100%",
                    maxWidth: "26rem",
                    padding: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1.5rem",
                    textAlign: "center",
                }}
            >
                <Box sx={boxStyle}>
                    <Typography sx={typographyStyle}>Email Address</Typography>
                    <TextField
                        sx={styledTextField}
                        name="email"
                        value={email}
                        onChange={handleChange}
                        placeholder="Enter email address"
                        variant="outlined"
                    />
                </Box>

                <Box sx={boxStyle}>
                    <Typography sx={typographyStyle}>Password</Typography>
                    <TextField
                        type="password"
                        sx={styledTextField}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        placeholder="Enter password"
                        variant="outlined"
                    />
                </Box>

                {/* Forgot Password */}
                <Typography
                    sx={{
                        mt: '-8px',
                        fontSize: "16px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        letterSpacing: "-0.01375rem",
                        alignSelf: "flex-end",
                        color: "var(--Accent-Accent-01, #007BFF)",
                        cursor: "pointer",
                    }}
                    onClick={() => navigate("/forgot_password")}
                >
                    Forgot Password?
                </Typography>

                {/* Login Button */}
                <Button
                    onClick={handleClick}
                    sx={{
                        padding: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        background: "var(--Accent-Accent-00, #007BFF)",
                        color: "#FFF",
                        '&:hover': {
                            background: "#0056b3",
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "1.25rem",
                            fontWeight: 600,
                            color: "#FFF",
                        }}
                    >
                        Login as Organizational Admin
                    </Typography>
                </Button>

                {/* Back and Register Links */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignSelf: "stretch",
                        mt: "1rem",
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
                        <img
                            src={expandleft}
                            alt="Back"
                            onClick={() => navigate("/login")}
                            style={{ marginRight: "8px" }}
                        />
                        <Typography
                            onClick={() => navigate("/login")}
                            sx={{
                                fontSize: "1rem",
                                color: "var(--Accent-Accent-00, #007BFF)",
                                cursor: "pointer",
                            }}
                        >
                            Back
                        </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1rem", color: "var(--Accent-Accent-01, #3F3F3F)" }}>
                        Don’t have an account?{" "}
                        <span
                            onClick={() => navigate("/register")}
                            style={{
                                color: "var(--Accent-Accent-01, #007BFF)",
                                cursor: "pointer",
                                fontWeight: 500,
                            }}
                        >
                            Register here
                        </span>
                    </Typography>
                </Box>
            </Box>

            {/* Footer with "Powered by" */}
            <Box
                sx={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",  // Adjust alignment to center
                    p: '16px 24px',
                    mt: 'auto',             // Push the footer to the bottom
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '24px',
                        marginRight: '8px',  // Adds some spacing between text and logo
                    }}
                >
                    Powered by
                </Typography>
                <img src={mapITlogo} alt="mapIT Logo" style={{ height: '40px' }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Times New Roman',
                        fontSize: '14px',
                        alignSelf: 'end',
                        marginLeft: '8px',  // Adds some spacing between text and logo
                    }}
                >
                    mapIT.ai
                </Typography>
            </Box>

            <ToastContainer />
        </Box>
    );
};

export default OrganizationAdmin;
