import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import AdminBarcodeUPCsAPIs from "../../../utilities/api/inventory/AdminBarcodeUPCsAPIs";

const BarcodeScanner = () => {
  const [data, setData] = useState("Not Found");
  const [loading, setLoading] = useState(false);
  const [upcData, setUPCData] = useState(null); // Store fetched UPC data
  const [error, setError] = useState(null); // Store error message

  const handleBarcodeUpdate = async (barcode) => {
    setLoading(true);
    setError(null);
    setUPCData(null);

    try {
      const response = await AdminBarcodeUPCsAPIs.GetUPCData(barcode);
      console.log('UPC data:', response);
      setUPCData(response); // Set fetched data
    } catch (err) {
      setError("Failed to fetch UPC data.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Scan a Barcode
      </Typography>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) {
            setData(result.text);
            handleBarcodeUpdate(result.text); // Fetch UPC data
          } else {
            setData("Not Found");
          }
        }}
      />
      <Typography variant="body1" sx={{ mt: 2 }}>
        Scanned Barcode: {data}
      </Typography>
      {loading && <CircularProgress sx={{ mt: 2 }} />}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {upcData && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">UPC Data:</Typography>
          <Typography variant="body2" sx={{ whiteSpace: "pre-wrap", textAlign: "left" }}>
            {JSON.stringify(upcData, null, 2)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BarcodeScanner;
