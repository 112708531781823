import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

const ConversationList = ({ userName, message, date, avatar, onClick }) => {
    return (
        <Box onClick={onClick} sx={{ display: 'flex', alignItems: 'center', padding: '10px', borderBottom: '1px solid #ddd', cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' }, width: '100%', boxSizing: 'border-box', height: '80px' }}>
            <Avatar src={avatar} sx={{ marginRight: '10px' }} />
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userName}</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{message}</Typography>
            </Box>
            <Box sx={{ textAlign: 'right', alignSelf: 'flex-start' }}>
                <Typography variant="caption" color="textSecondary">{date}</Typography>
            </Box>
        </Box>
    );
};

export default ConversationList;
