import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ProjectCard from './ProjectCard';
import ProjectViewDrawer from './ProjectViewDrawer';
import ProjectAPIs from '../../utilities/api/project/AdminProjectAPI';
import AdminUserList from '../../utilities/api/user/AdminUserList';
import { useSelector } from 'react-redux';
import nodatafound from '../../utilities/dummy_assets/nodatafound.jpg'; // Import your no data image

const progressOptions = {
  1: 'Archived',
  2: 'In Progress',
  3: 'Completed'
};

const typeOptions = {
  1: 'Academic',
  2: 'Research',
  3: 'Personal',
  4: 'Other'
};

const Projectscont = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const fetchProjectsAndUsers = async () => {
      try {
        const [projectData, userData] = await Promise.all([
          ProjectAPIs.ProjectsGet(),
          AdminUserList.AllUserListGet()
        ]);
        setProjects(projectData);
        setFilteredProjects(projectData);
        setUsers(userData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchProjectsAndUsers();
  }, [user]);

  useEffect(() => {
    let filtered = projects;
    if (typeFilter) {
      filtered = filtered.filter(project => project.type.toString() === typeFilter);
    }
    if (statusFilter) {
      filtered = filtered.filter(project => project.progress_status.toString() === statusFilter);
    }
    if (searchTerm) {
      filtered = filtered.filter(project =>
        project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredProjects(filtered);
  }, [typeFilter, statusFilter, searchTerm, projects]);

  const handleViewProject = (project) => {
    setSelectedProject(project);
    setDrawerOpen(true);
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const getUserDetails = (userId) => users.find(user => user.id === userId);

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, paddingInline: 1 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              sx={{ borderRadius: '8px' }}
              inputProps={{ name: 'type', id: 'type-select' }}
              label="Type"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Object.entries(typeOptions).map(([key, value]) => (
                <MenuItem key={key} value={key}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              sx={{ borderRadius: '8px' }}
              inputProps={{ name: 'status', id: 'status-select' }}
              label="Status"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Object.entries(progressOptions).map(([key, value]) => (
                <MenuItem key={key} value={key}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            border: '1px solid #A6A6A6',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            width: '300px',
          }}
        >
          <SearchIcon sx={{ marginLeft: 1, marginRight: 1 }} />
          <TextField
            sx={{ flexGrow: 1 }}
            placeholder="Search..."
            variant="standard"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setSearchTerm(e.target.value);
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        {filteredProjects.length > 0 ? (
          filteredProjects.map((project) => (
            <Grid item key={project.id} xs={12} sm={6} md={4}>
              <ProjectCard project={project} onView={handleViewProject} getUserDetails={getUserDetails} allUser={users} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', padding: 4 , mt:8 }}>
            <img src={nodatafound} alt="No data found" style={{ width: '350px', marginBottom: '16px'}} />
            <Typography variant="h6">No Projects Found</Typography>
          </Grid>
        )}
      </Grid>
      <ProjectViewDrawer
        drawerWidth={400}
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        currentProject={selectedProject}
        getUserDetails={getUserDetails}
      />
    </Box>
  );
};

export default Projectscont;
