import React, { useEffect, useRef } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import ChatMessage from './ChatMessage';
import MessageInput from './MessageInput';

const ChatWindow = ({ messages, currentUser, conversationUser, onNewMessage, conversationId }) => {
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);

    const handleSendMessage = async (message) => {
        try {
            await onNewMessage(message);
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTo({
                top: messagesContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        scrollToBottom(); 
    }, [messages]);

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>
                <Avatar src={conversationUser.avatar} sx={{ marginRight: '10px' }} />
                <Typography variant="h6">{conversationUser.userName}</Typography>
            </Box>
            <Box
                sx={{ flexGrow: 1, padding: '10px', overflowY: 'auto', backgroundColor: '#f9f9f9', '&::-webkit-scrollbar': { width: '0px', height: '0px' }, '&:hover::-webkit-scrollbar': { width: '8px', height: '8px' } }}
                ref={messagesContainerRef}
            >
                {messages.map((msg, index) => (
                    <ChatMessage key={index} message={msg.text} time={msg.time || msg.date} isCurrentUser={msg.isCurrentUser} />
                ))}
                <div ref={messagesEndRef} />
            </Box>
            <MessageInput onSendMessage={handleSendMessage} />
        </Box>
    );
};

export default ChatWindow;
