import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tutEditMode: false,
  tutHasReturned: false,
  tutCurrentItem: null,
  isNewTut: false, // Added new state property
};

const tutorialEditSlice = createSlice({
  name: 'tutorialeditMode', // Slice name
  initialState,
  reducers: {
    toggleTutEditMode(state) {
      state.tutEditMode = !state.tutEditMode;
    },
    setTutEditMode(state, action) {
      state.tutEditMode = action.payload;
    },
    setTutHasReturned(state, action) {
      state.tutHasReturned = action.payload;
    },
    setTutCurrentItem(state, action) {
      state.tutCurrentItem = action.payload;
    },
    updateTutCurrentItem(state, action) {
      const updatedItem = action.payload;
      state.tutCurrentItem = { ...state.tutCurrentItem, ...updatedItem };
    },
    setIsNewTut(state, action) { // New reducer
      state.isNewTut = action.payload;
    },
  },
});

export const {
  toggleTutEditMode,
  setTutEditMode,
  setTutHasReturned,
  setTutCurrentItem,
  updateTutCurrentItem,
  setIsNewTut // Export new action
} = tutorialEditSlice.actions;

export default tutorialEditSlice.reducer;




// import { createSlice } from '@reduxjs/toolkit';

// // Initial state for the edit mode slice
// const initialState = {
//   tutEditMode: false,
//   tutHasReturned: false, // Parameter to track if user has returned
//   tutCurrentItem: null, // Parameter to track the current item
// };

// // Create slice using Redux Toolkit createSlice
// const tutorialEditSlice = createSlice({
//   name: 'tutorialeditMode', // Name of the slice
//   initialState,    // Initial state
//   reducers: {
//     // Toggle edit mode action
//     toggleTutEditMode(state) {
//       console.log('toggleTutEditMode called'); // Add console log here
//       state.tutEditMode = !state.tutEditMode;  // Toggles the tutEditMode state
//     },
//     // Set edit mode action with payload
//     setTutEditMode(state, action) {
//       console.log('setTutEditMode called with payload:', action.payload); // Add console log here
//       state.tutEditMode = action.payload;   // Sets the tutEditMode state to the payload value
//     },
//     // Set has returned action with payload
//     setTutHasReturned(state, action) {
//       state.tutHasReturned = action.payload;   // Sets the tutHasReturned state to the payload value
//     },
//     // Set current item action with payload
//     setTutCurrentItem(state, action) {
//       state.tutCurrentItem = action.payload;   // Sets the tutCurrentItem state to the payload value
//     },
//     // Update current item action with payload
//     updateTutCurrentItem(state, action) {
//       const updatedItem = action.payload;
//       // Find and update the tutCurrentItem in the state
//       state.tutCurrentItem = { ...state.tutCurrentItem, ...updatedItem };
//     },
//   },
// });

// // Export actions
// export const { toggleTutEditMode, setTutEditMode, setTutHasReturned, setTutCurrentItem, updateTutCurrentItem } = tutorialEditSlice.actions;

// // Export reducer
// export default tutorialEditSlice.reducer;
