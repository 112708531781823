import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from '../../utilities/dummy_assets/MBF_Logo.png';
import mapITlogo from '../../utilities/dummy_assets/mapITlogo.png';

const typographyStyle = {
    width: "460px",
    color: "var(--Primary-Primary01, #007BFF)",
    padding: "12px 16px",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.01375rem",
    borderRadius: "0.75rem",
    border: "1px solid var(--Accent-Accent-00, #007BFF)",
};
const boxStyle = {
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    cursor: "pointer",
};

const CommonLoginPage = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", background: '#F7F7F7' }}>
            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    top: '12px',
                    left: "50px",
                    p: '16px 24px'
                }}
            >
                <img src={Logo} alt="MBF Logo" style={{ height: '69px', width: '159px' }} />
            </Box>

            {/* "Go to User Site" link */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    marginTop: '28px',
                    marginRight: "50px",
                    p: '16px 24px'
                }}
            >
                <span
                    onClick={() => (window.location.href = "https://staging.mis.mapit.ai/login")}
                    style={{
                        color: "var(--Accent-Accent-01, #0062CC)",
                        cursor: "pointer",
                        textDecoration: "underline",
                    }}
                >
                    Go to User Site
                </span>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    justifyContent: "center",  // Center content vertically
                    alignItems: "center",      // Center content horizontally
                    padding: "1.5rem"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "1.5rem",
                        width: "25.2rem",
                    }}
                >
                    <Box sx={{ alignSelf: 'center' }}>
                        <Typography
                            sx={{
                                mb: "0.5rem",
                                color: "var(--Primary-Primary01, #212121)",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                letterSpacing: " -0.02475rem",
                                alignSelf: "center",
                            }}
                        >
                            Sign In
                        </Typography>
                        <Typography sx={{
                            color: "var(--Primary-Primary01, #898989)",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "normal",
                            letterSpacing: " -0.02475rem",
                            alignSelf: "center",
                        }}>
                            to your MIS account
                        </Typography>
                    </Box>
                    <Box sx={boxStyle} onClick={() => navigate("/organizationaladminlogin")}>
                        <Typography sx={typographyStyle}>
                            Login as organizational admin
                        </Typography>
                    </Box>
                    <Box sx={boxStyle} onClick={() => navigate("/adminlogin")}>
                        <Typography sx={typographyStyle}>
                            Login as admin
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            color: "var(--Primary-Primary01, #3F3F3F)",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            letterSpacing: "-0.01375rem",
                            margin: "auto",
                            marginTop: "0px",
                        }}
                    >
                        Don’t have an account?{" "}
                        <span
                            onClick={() => navigate("/register")}
                            style={{
                                color: "var(--Accent-Accent-01, #007BFF)",
                                cursor: "pointer",
                            }}
                        >
                            Register here
                        </span>
                    </Typography>
                </Box>
            </Box>

            {/* Footer with "Powered by" */}
            <Box
                sx={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",  // Adjust alignment to center
                    p: '16px 24px',
                    mt: 'auto',             // Push the footer to the bottom
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '24px',
                        marginRight: '8px',  // Adds some spacing between text and logo
                    }}
                >
                    Powered by
                </Typography>
                <img src={mapITlogo} alt="mapIT Logo" style={{ height: '40px' }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Times New Roman',
                        fontSize: '14px',
                        alignSelf: 'end',
                        marginLeft: '8px',  // Adds some spacing between text and logo
                    }}
                >
                    mapIT.ai
                </Typography>
            </Box>
        </Box>
    );
};
export default CommonLoginPage;
