import { axiosApiService } from "../axios";
const tagMapping = {
  'Free': '0',
  'Issue': '1',
  'Purchase': '2',
  'Exhaustible': '3',
  'NonExhaustible': '4',
  'InLabOnly': '5',
  'HighValue': '6',

}
const categoryMapping = {
  'Electronics': 0,
  'Mechanical': 1,
  'Integrated Chips': 2,
};
const groupMapping = {
  'A': 0,
  'B': 1,
  'C': 2
}
const InventoryDetailAPIs = {

  InventoryDetailGet: async function(id){
    const response = await axiosApiService.get(`/admin_inventory_detail/${id}/`);
    return response.data;
  },
  InventoryDetailGetObject: async function(primaryKey){
    const response = await axiosApiService.get(`/admin_inventory_detail/${primaryKey}/`);
    return response.data;
  },
  InventoryDetailPatch: async function(primaryKey, patchData, token){
    const response = await axiosApiService.patch(`/admin_inventory_detail/${primaryKey}/`, patchData, {
      headers: { 
        "Authorization": `Bearer ${token}` // Include the token in the headers
      },
    });
    return response.data;
  },

  InventoryDetailPut: async function(primaryKey, values, token) {
    const formData = new URLSearchParams();
  
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        formData.append(key, values[key]);
      }
    }
  
    const response = await axiosApiService.put(`/admin_inventory_detail/${primaryKey}/`, formData, {
      headers: { 
        "Authorization": `Bearer ${token}` // Include the token in the headers
      },
    });
    return response.data;
  },
  

  InventoryDetailDelete: async function(id,token){
    const response = await axiosApiService.delete(`/admin_inventory_detail/${id}/`,{
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}` // Include the token in the headers
      },
    });
    return response.data;
  },

}
export default InventoryDetailAPIs;