

import { axiosApiService } from "../axios";
const tagMapping = {
  'Free': '0',
  'Issue': '1',
  'Purchase': '2',
  'Exhaustible': '3',
  'NonExhaustible': '4',
  'InLabOnly': '5',
  'HighValue': '6',

}
const availabilityMapping = {
  'Moderate': 2,
  'Very Low': 0,
  'Low': 2,
  'High': 3,
  'Always': 4
};
const categoryMapping = {
  'Electronics': 0,
  'Mechanical': 1,
  'Integrated Chips': 2,
};
const groupMapping = {
  'A': 0,
  'B': 1,
  'C': 2
}
const EquipmentDetailAPIs = {

  EquipmentDetailGet: async function(id){
    const response = await axiosApiService.get(`/admin_equipment_detail/${id}/`);
    return response.data;
  },
  EquipmentDetailGetObject: async function(primaryKey){
    const response = await axiosApiService.get(`/admin_equipment_detail/${primaryKey}/`);
    return response.data;
  },
  EquipmentDetailPatch: async function(primaryKey, patchData){
    const response = await axiosApiService.patch(`/admin_equipment_detail/${primaryKey}/`, patchData);
    return response.data;
  },
  EquipmentDetailPut: async function(primaryKey, values, token){
   const response = await axiosApiService.put(`/admin_equipment_detail/${primaryKey}/`, values, {
      headers: { 
        "Authorization": `Bearer ${token}` // Include the token in the headers
      },
    });
    return response.data;
  },

  EquipmentDetailDelete: async function(id, token){
    const response = await axiosApiService.delete(`/admin_equipment_detail/${id}/`,{
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}` // Include the token in the headers
      },
    });
    return response.data;
  },

}
export default EquipmentDetailAPIs;
