
import { axiosApiService } from "../axios";
const UserRequestAPIs = {
  UserRequestListGet: async function(){
    const response = await axiosApiService.get(`/auth/register/`);
    return response.data;
  },


  AdminRequestAcceptDecline: async function(values){
    const response = await axiosApiService.post(`/auth/admin_request/`, values);
    return response.data;
  },

  OrgAdminRequestListGet: async function(){
    const response = await axiosApiService.get(`/auth/org_user_requests_list/`);
    return response.data;
  },

}
export default UserRequestAPIs;
