import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, TextField, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import WorkbenchAPIs from '../../../utilities/api/workbench/WorkbenchAPIs';
import 'react-toastify/dist/ReactToastify.css'; // Import styles for toastify

// Validation schema using Yup
const validationSchema = Yup.object().shape({
    seats: Yup.number().required('No. of seats is required'),
    upc: Yup.string().required('Available machine/inventory is required'),
    startTime: Yup.string().required('Start time is required'),
    endTime: Yup.string().required('End time is required').test(
        'is-after-startTime',
        'End time must be after start time',
        function (value) {
            const { startTime } = this.parent;
            return new Date(`1970-01-01T${value}`) > new Date(`1970-01-01T${startTime}`);
        }
    ),
    description: Yup.string(),
});

const EditWorkbenchDialog = ({ open, onClose, workbenchId, update, setUpdate }) => {
    const [initialValues, setInitialValues] = useState({
        seats: '',
        upc: '',
        startTime: '00:00',
        endTime: '00:00',
        description: '',
    });

    // Fetch the workbench data by ID to pre-fill the form when editing
    useEffect(() => {
        const fetchWorkbenchDetails = async () => {
            try {
                const response = await WorkbenchAPIs.getDetail(workbenchId);
                setInitialValues({
                    seats: response.seats,
                    upc: response.available_machines,
                    startTime: response.slot_from.substring(0, 5), // Extract "HH:MM" from "HH:MM:SS"
                    endTime: response.slot_to.substring(0, 5),     // Extract "HH:MM" from "HH:MM:SS"
                    description: response.description || '',      // Handle empty descriptions
                });
            } catch (error) {
                console.error('Error fetching workbench details:', error);
                toast.error('Failed to fetch workbench details.');
            }
        };

        if (open) {
            fetchWorkbenchDetails();
        }
    }, [workbenchId, open]);

    const handleSubmitForm = (values, resetForm) => {
        const updatedBookingData = {
            seats: values.seats,
            available_machines: values.upc, // Map "upc" to "available_machines"
            slot_from: values.startTime + ":00", // Append ":00" to match "HH:MM:SS" format
            slot_to: values.endTime + ":00",     // Append ":00" to match "HH:MM:SS" format
            description: values.description,
        };

        // Call the API to update the existing workbench using .then() and .catch()
        const response = WorkbenchAPIs.updateWorkbench(workbenchId, updatedBookingData);
        response
            .then((result) => {
                console.log(result);
                toast.success('Workbench updated successfully!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                resetForm(); // Reset the form
                onClose(); // Close the dialog
                setUpdate((prev) => !prev); // Trigger parent component update
            })
            .catch((error) => {
                console.error('Error updating workbench:', error);
                if (error.response && error.response.data) {
                    const errorMessages = Object.values(error.response.data).flat().join(' ');
                    toast.error(`Failed to update workbench: ${errorMessages}`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else {
                    toast.error('Failed to update workbench: An unexpected error occurred.', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" sx={{
            '& .MuiDialog-paper': {
                borderRadius: '16px',
            }
        }}>
            <Box sx={{ p: 1, borderRadius: '16px', backgroundColor: '#fff', boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)' }}>
                <DialogTitle sx={{ fontSize: '24px', color: '#3f3f3f', fontWeight: 700, mb: -2 }}>Edit Work Bench</DialogTitle>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true} // Important to update form when initialValues change
                    onSubmit={(values, { resetForm }) => {
                        handleSubmitForm(values, resetForm);
                    }}
                >
                    {({ handleSubmit, values, touched, errors, handleChange }) => (
                        <Form onSubmit={handleSubmit}>
                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

                                {/* No. of seats */}
                                <Box>
                                    <Typography sx={{ mb: 1, fontWeight: 600, fontSize: '16px', color: '#3f3f3f' }}>
                                        No. of seats <span style={{ color: '#c90505' }}>*</span> :
                                    </Typography>
                                    <TextField
                                        name="seats"
                                        placeholder="Enter the number of seats"
                                        fullWidth
                                        value={values.seats}
                                        onChange={handleChange}
                                        error={touched.seats && !!errors.seats}
                                        helperText={touched.seats && errors.seats}
                                        sx={{
                                            '& .MuiInputBase-root': { borderRadius: '8px' },
                                            '& input': { color: '#898989' },
                                        }}
                                    />
                                </Box>

                                {/* Available machine/inventory */}
                                <Box>
                                    <Typography sx={{ mb: 1, fontWeight: 600, color: '#3f3f3f' }}>
                                        Available machine/inventory <span style={{ color: '#c90505' }}>*</span> :
                                    </Typography>
                                    <TextField
                                        name="upc"
                                        placeholder="Enter available machine/inventory"
                                        fullWidth
                                        value={values.upc}
                                        onChange={handleChange}
                                        error={touched.upc && !!errors.upc}
                                        helperText={touched.upc && errors.upc}
                                        sx={{
                                            '& .MuiInputBase-root': { borderRadius: '8px' },
                                            '& input': { color: '#898989' },
                                        }}
                                    />
                                </Box>

                                {/* Booking Duration */}
                                <Box>
                                    <Typography sx={{ mb: 1, fontWeight: 600, color: '#3f3f3f' }}>
                                        Booking Duration <span style={{ color: '#c90505' }}>*</span> :
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        {/* Start Time */}
                                        <TextField
                                            type="time"
                                            name="startTime"
                                            value={values.startTime}
                                            onChange={handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5-minute increments
                                            }}
                                            sx={{
                                                borderRadius: '8px',
                                                '& .MuiInputBase-root': { borderRadius: '8px' }
                                            }}
                                        />

                                        <Typography sx={{ marginInline: 1, color: '#3f3f3f', fontWeight: 500 }}>To</Typography>

                                        {/* End Time */}
                                        <TextField
                                            type="time"
                                            name="endTime"
                                            value={values.endTime}
                                            onChange={handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5-minute increments
                                            }}
                                            sx={{
                                                borderRadius: '8px',
                                                '& .MuiInputBase-root': { borderRadius: '8px' }
                                            }}
                                        />
                                    </Box>
                                </Box>

                                {/* Description */}
                                <Box>
                                    <Typography sx={{ fontWeight: 600, color: '#3f3f3f' }}>Description :</Typography>
                                    <TextField
                                        name="description"
                                        placeholder="Enter Description"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={values.description}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiInputBase-root': { borderRadius: '8px' },
                                            '& input': { color: '#898989' },
                                        }}
                                    />
                                </Box>
                            </DialogContent>

                            <DialogActions sx={{ justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={onClose}
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'none',
                                        color: '#000',
                                        backgroundColor: '#fff',
                                        border: '1px solid #b5b5b5',
                                        borderRadius: '12px',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0',
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                        borderRadius: '12px',
                                        padding: '12px 16px',
                                        '&:hover': {
                                            backgroundColor: '#0056b3',
                                        }
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    );
};

export default EditWorkbenchDialog;
