import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const TIER_MAPPING = {
  1: 'Bookings',
  2: 'Messaging',
  3: 'Workshops',
  4: 'Records',
  5: 'Login',
  6: 'Issues/Purchases',
  7: 'Web Application',
  8: 'Other',
};

const IssuesChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const issueChartRef = useRef(null);
  const [issueData, setIssueData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const issueCounts = {};
        const labels = Object.values(tlMappings);

        const fetchIssueData = async (TL_name) => {
          const response = await axiosApiService.get('/auth/report_user_issue_list/', {
            headers: { 'TL': TL_name },
          });
          return response.data;
        };

        await Promise.all(labels.map(async (tlName) => {
          const issues = await fetchIssueData(tlName);
          const issueCountsForTL = {};

          issues.forEach((issue) => {
            const tierLabel = TIER_MAPPING[issue.tier] || 'Other';
            issueCountsForTL[tierLabel] = (issueCountsForTL[tierLabel] || 0) + 1;
          });

          issueCounts[tlName] = issueCountsForTL;
        }));

        const issueDataArray = labels.map(label => 
          Object.keys(TIER_MAPPING).map(key => issueCounts[label]?.[TIER_MAPPING[key]] || 0)
        );

        setLabels(labels);
        setIssueData(issueDataArray);
      } catch (error) {
        console.error('Error fetching issue data:', error);
      }
    };

    fetchData();
  }, [tlMappings]);

  useEffect(() => {
    if (issueChartRef.current) issueChartRef.current.destroy();

    const hasData = issueData.flat().some(value => value > 0);

    if (hasData) {
      const maxIssues = Math.ceil(Math.max(...issueData.flat()) * 1.2);
      const issueCtx = chartRef.current.getContext('2d');

      const datasets = Object.keys(TIER_MAPPING).map(key => {
        const data = issueData.map(tlIssues => tlIssues[key - 1]);
        if (data.some(value => value > 0)) {
          return {
            label: TIER_MAPPING[key],
            data: data,
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
          };
        }
        return null;
      }).filter(dataset => dataset !== null);

      issueChartRef.current = new Chart(issueCtx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              display: datasets.length > 0,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const label = tooltipItem.dataset.label || '';
                  const value = tooltipItem.raw || 0;
                  return `${label}: ${value}`;
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
              max: maxIssues,
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      });
    }

    return () => {
      if (issueChartRef.current) issueChartRef.current.destroy();
    };
  }, [labels, issueData]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: 1, borderStyle: 'solid', flex: 1, width: '90%', height: 336, padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardContent sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ fontSize: '25px', fontWeight: '700', color: '#212121', textAlign: 'left', letterSpacing: 0 }}>Types of Issues</Typography>
        {issueData.flat().some(value => value > 0) ? (
          <Box sx={{ height: '250px', width: '100%', marginTop: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <canvas ref={chartRef} style={{ width: '100%' }} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
            <Typography variant='h5' color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default IssuesChart;
