import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { updateTL } from '../../redux/slices/userSlice';
import { addMessage, clearMessages } from '../../redux/slices/messagesSlice'; // Import actions from messagesSlice
import { Badge, Box, Button } from '@mui/material';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationCard from './notification';
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
import CreateTL from './CreateTL';
import { ImageBaseURL } from "../../utilities/api/axios";
import WebSocketService from '../../utilities/api/IMS/websocketService';

const NavBar = () => {
    const [notifi, setNotifi] = useState(false);
    const [tinkerLabs, setTinkerLabs] = useState([]);
    const [openCreateTL, setOpenCreateTL] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const { access, user } = useSelector((state) => state.user.user);
    const reduxMessages = useSelector((state) => state.messages.messages); // Get messages from Redux state
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socketRef = useRef(null);

    const userAvatarUrl = user.profile_pic ? `${ImageBaseURL}${user.profile_pic}` : '';

    useEffect(() => {
        const fetchTinkerLabs = async () => {
            try {
                const labs = await TinkerLabListAPIs.TinkerLabsListGet();
                setTinkerLabs(labs);
            } catch (error) {
                console.error('Error fetching Tinker Labs data:', error);
            }
        };

        fetchTinkerLabs();
    }, [openCreateTL]);

    useEffect(() => {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission().then(permission => {
                if (permission !== 'granted') {
                    console.warn('Permission for notifications was denied.');
                }
            });
        }

        const connectWebSocket = () => {
            WebSocketService.connect(access, user.username);

            WebSocketService.onMessage((messageData) => {
                console.log("WebSocket message received:", messageData);
                if (messageData && messageData.message && messageData.sender) {
                    const newNotification = {
                        avatar: "https://www.example.com/avatar.jpg",
                        message: `Message from ${messageData.sender}: ${messageData.message}`,
                        time: new Date().toLocaleTimeString() + " • Today",
                    };

                    setNotifications((prev) => [...prev, newNotification]);

                    // Dispatch the message to the Redux store
                    dispatch(addMessage(messageData));

                    showNotification(newNotification);
                }
            });

            socketRef.current = WebSocketService;
        };

        connectWebSocket();

        return () => {
            if (!notifi) {
                WebSocketService.disconnect();
            }
        };
    }, [access, user.username, notifi]);

    const showNotification = (notification) => {
        if (Notification.permission === 'granted') {
            new Notification('New Message', {
                body: notification.message,
                icon: notification.avatar,
            });
        }
    };

    const handleNotificationToggle = () => {
        setNotifi((prev) => !prev);
        if (!notifi) {
            // Clear messages from the Redux state when notifications are opened
            dispatch(clearMessages());
        }
    };

    const handleNavigation = () => {
        navigate('/calendar');
    };

    const handleProfile = () => {
        navigate('/profile');
    };

    const handleTLChange = (event) => {
        const selectedTLName = event.target.value;
        dispatch(updateTL({ TL: selectedTLName }));
    };

    const handleCreateTLOpen = () => {
        setOpenCreateTL(true);
    };

    const handleCreateTLClose = () => {
        setOpenCreateTL(false);
    };

    const showProfileIcon = 
        (user.is_org_admin && user.TL_name === "Org-Admin") || 
        !user.is_org_admin;

    return (
        <>
            <AppBar position="static" color="inherit" elevation={1}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {user?.is_org_admin ? (
                            <Select
                                value={user.TL_name || ''}
                                onChange={handleTLChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select TL' }}
                            >
                                {tinkerLabs.map((lab) => (
                                    <MenuItem key={lab.id} value={lab.name}>
                                        {lab.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            user?.TL_name || ''
                        )}
                    </Typography>

                    {user?.TL_name === 'Org-Admin' && (
                        <Button variant="contained" color="primary" onClick={handleCreateTLOpen} sx={{ marginRight: '27px' }}>
                            Create TL
                        </Button>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '27px' }}>
                        <IconButton color="inherit" onClick={handleNotificationToggle}>
                            <Badge color="secondary" badgeContent={reduxMessages.length} overlap="circular">
                                <NotificationAddIcon />
                            </Badge>
                        </IconButton>
                    </Box>

                    {user.TL_name !== "Org-Admin" && (
                      <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
                          <CalendarTodayIcon />
                      </IconButton>
                    )}

                    {showProfileIcon && (
                        <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleProfile}>
                            <Avatar alt="User Avatar" src={userAvatarUrl} />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            {notifi && <NotificationCard notifications={notifications} />}
            <CreateTL open={openCreateTL} handleClose={handleCreateTLClose} />
        </>
    );
};

export default NavBar;
