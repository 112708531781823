import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, Checkbox, ListItemText } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import * as Yup from 'yup';
import AdminGetUserAPIs from '../../../../utilities/api/workshop/AdminGetUser';
import AdminWorkshopAttendees from '../../../../utilities/api/workshop/AdminWorkshopAttendees';
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  selectedStudents: Yup.array().min(1, 'At least one student must be selected').required('Select Students is required'),
});

const AddStudentDialog = ({ open, onClose, workshopId, currentAttendees, onUpdateAttendees }) => {
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const [studentsOptions, setStudentsOptions] = useState([]);
  const initialValues = {
    selectedStudents: [],
  };

  const fetchStudents = async (query) => {
    try {
      const data = await AdminGetUserAPIs.UserDetailsGet(query);
      const options = data.map(user => ({
        id: user.id,
        name: user.name,
        email: user.email,
      }));
      setStudentsOptions(options);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
        // Iterate through selected students and add them as attendees
        for (const student of values.selectedStudents) {

            console.log('student',student);
        
            const attendeeData = {
                is_present: false, // Assuming this is a default value
                workshop: workshopId, // Use the workshopId prop
                user: student.id,
            };

            console.log('attendeeData', attendeeData);

            
            // Make a POST request to add attendee using API module
            await AdminWorkshopAttendees.AddAttendeePost(attendeeData, token);
        }

      // Create a new array with the updated attendees
      const updatedAttendees = [
        ...currentAttendees,
        ...values.selectedStudents.map(student => ({
          is_present: false, // Assuming this is a default value
          workshop: workshopId, // Use the workshopId prop
          user: student.id,
        })),
      ];

      // Update attendees in parent component (AttendanceTable)
      onUpdateAttendees(updatedAttendees);

      // Reset form and close dialog on success
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error adding attendees:', error);
      // Handle error as needed
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Students</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Box sx={{ mb: 1 }}>
                <Autocomplete
                  multiple
                  options={studentsOptions}
                  getOptionLabel={(option) => `${option.name} (${option.email})`}
                  value={values.selectedStudents}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue) {
                      fetchStudents(newInputValue);
                    }
                  }}
                  onChange={(event, newValue) => {
                    setFieldValue('selectedStudents', newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<span />}
                        checkedIcon={<span />}
                        checked={selected}
                      />
                      <ListItemText primary={`${option.name} (${option.email})`} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <Field
                      component={TextField}
                      {...params}
                      name="selectedStudents"
                      label="Select Students"
                      variant="outlined"
                      error={touched.selectedStudents && Boolean(errors.selectedStudents)}
                      helperText={touched.selectedStudents && <ErrorMessage name="selectedStudents" />}
                    />
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color="primary"
                sx={{
                  width: '80px',
                  height: '35px',
                  color: 'black',
                  border: '1px solid rgb(218,218,218)',
                  borderRadius: '10px',
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                sx={{
                  width: '80px',
                  height: '35px',
                  color: 'white',
                  backgroundColor: 'rgb(0,123,255)',
                  border: '1px solid black',
                  borderRadius: '10px',
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddStudentDialog;