
import { axiosApiService } from "../axios";

const InventoryGroupAPIs = {
    

    GroupListGet: async function () {
        
        const response = await axiosApiService.get(`/admin_inventory_group_list/`);
        return response.data
       
    },
    GroupListPost: async function (group) {
        const formData = new FormData();
        formData.append("name", group)
        const response = await axiosApiService.post(`/admin_inventory_group_list/`, formData, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        return response.data
       
    },

   
}
export default InventoryGroupAPIs;
  
