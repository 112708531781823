import * as React from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isSameDay, isSameWeek } from 'date-fns';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isToday' && prop !== 'isCurrentWeek',
})(({ theme, isToday, isCurrentWeek }) => ({
  ...(isToday && {
    backgroundColor: 'orange',
    color: theme.palette.common.white,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'darkorange',
    },
  }),
  ...(isCurrentWeek && !isToday && {
    backgroundColor: 'lightblue',
    borderRadius: '16px',
  }),
  fontSize: '0.875rem',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main, // Ensure selected date color
    color: theme.palette.common.white,
  },
}));

const DatePickerDialog = ({ open, selectedDate, handleDateChange }) => {
  return (
    <Paper
      elevation={3}
      style={{ borderRadius: '4px', margin: '16px' }}
    >
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={selectedDate}
        onChange={(newDate) => handleDateChange(newDate)}
        renderDay={(day, _selectedDate, pickersDayProps) => {
          const isToday = isSameDay(day, new Date());
          const isCurrentWeek = isSameWeek(day, new Date(), { weekStartsOn: 0 });

          return (
            <CustomPickersDay
              {...pickersDayProps}
              day={day}
              isToday={isToday}
              isCurrentWeek={isCurrentWeek}
            />
          );
        }}
      />
    </Paper>
  );
};

export default DatePickerDialog;
