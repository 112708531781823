import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { MenuComponent } from '../components/Menu';
import NavBar from '../components/Navbar/Navbar';
import LookerStudioEmbed from '../components/dashboard/LookerStudioEmbed';
import DashboardContent from '../components/dashboard/DashboardContent';
import OrgDashboard from '../components/dashboard/OrgDashboard';
import OrgAllStatsAPIs from '../utilities/api/dashboard/OrgAllStatsAPIs';

const Dashboard =  ({ collapsed, setCollapsed }) => {
  const {user}  = useSelector((state) => state.user.user); // Retrieve user from Redux store

  console.log('Dashboard User',user);

  const apicall = async () => {
    try {
      const response = await OrgAllStatsAPIs.AllStats();
      console.log('All Stats Response:', response.data);
    } catch (error) {
      console.error('Error updating admin status:', error);
    }
  }
  apicall();

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ width: collapsed ? '7%' : '17.5%' }}>
        <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <NavBar />
        {user.TL_name === 'Org-Admin' ? (
          <OrgDashboard />
        ) : (
          <DashboardContent />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
