import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, InputBase, IconButton, Select, MenuItem, FormControl, InputAdornment, OutlinedInput, InputLabel, useTheme, useMediaQuery, Checkbox, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState, useEffect } from 'react';
import nodatafound from '../../utilities/dummy_assets/No data-cuate 1.jpg';
import AdminInventoryPurchaseAPIs from '../../utilities/api/inventory/AdminInventoryPurchaseAPIs';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';  // Import CircularProgress

const PurchasesTable = () => {
  const user = useSelector(state => state.user.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [toggleState, setToggleState] = useState({});
  const [pickupStatus, setPickupStatus] = useState([]);
  const [selectedPickupStatus, setSelectedPickupStatus] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AdminInventoryPurchaseAPIs.InventoryPurchaseListGet();
        setRows(data.reverse());
        console.log('InventoryPurchaseListGet data', data);

        const uniqueCategories = [...new Set(data.map(item => item.category))];
        setCategories(uniqueCategories);
        const uniquePickupStatus = [...new Set(data.map(item => item.pickup_))];
        setPickupStatus(uniquePickupStatus);

        const initialToggleState = data.reduce((acc, item) => {
          if (item !== null && item.hasOwnProperty('id')) {
            acc[item.id] = item.pickup === '0'; // Initialize toggle state based on pickup value
          }
          return acc;
        }, {});
        setToggleState(initialToggleState);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handlePickupStatusChange = (event) => {
    setSelectedPickupStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent the default action to avoid form submission/reload
    }
  };

  const handleToggleChange = (id, newValue) => async () => {
    setToggleState({
      ...toggleState,
      [id]: newValue,
    });

    try {
      console.log('id', id);
      await AdminInventoryPurchaseAPIs.InventoryPurchaseDetailPatch(id, { pickup: newValue ? '0' : '1' });
    } catch (error) {
      console.error(`Error updating detail for ID ${id}:`, error.response ? error.response.data : error.message);
    }
  };

  const filteredRows = rows.filter((row) => {
    if (row === null) return false;
    const purchaseAmount = row.purchase_amount ? row.purchase_amount.toString().toLowerCase() : '';
    const purchaseDatetime = row.purchase_datetime ? row.purchase_datetime.toString().toLowerCase() : '';
    const purchasedBy = row.purchased_by ? row.purchased_by.toString().toLowerCase() : '';
    const name = row.name ? row.name.toString().toLowerCase() : '';
    const quantity = row.quantity ? row.quantity.toString().toLowerCase() : '';
    const category = row.category ? row.category.toString().toLowerCase() : '';
    
    const matchesCategory = !category.length || category.includes(row.category);
    const matchesPickupStatus = !selectedPickupStatus.length || selectedPickupStatus.includes(row.pickup_);
    const matchesSearchQuery =
      !searchQuery ||
      purchaseAmount.includes(searchQuery.toLowerCase()) ||
      purchaseDatetime.includes(searchQuery.toLowerCase()) ||
      purchasedBy.includes(searchQuery.toLowerCase()) ||
      name.includes(searchQuery.toLowerCase()) ||
      quantity.includes(searchQuery.toLowerCase()) ||
      category.includes(searchQuery.toLowerCase());

    return matchesCategory && matchesPickupStatus && matchesSearchQuery;
  });

  const columns = [
    {
      field: 'itemDetails',
      headerName: 'Item Details',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center', // Center align header
      renderCell: ({ row }) => (
        <Box sx={{ ml: '10%', display: 'flex', alignItems: 'center', gap: 1 }}>
          <img
            src={row.imageUrl || nodatafound} // Provide a default image path if necessary
            alt={row.name || 'No Text'}
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          />
          <Box>
            <Typography variant="subtitle2">{row.name || 'No Text'}</Typography>
            <Typography variant="body2" sx={{ color: 'blue' }}>
              {row.project_name || 'No Subtext'}
            </Typography>
          </Box>
        </Box>
      ),
    },    
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.5,
      headerClassName: 'header-style',
      headerAlign: 'center', // Center align header
      align: 'center',       // Center align cell data
    },
    {
      field: 'purchaser',
      headerName: 'Purchased By',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center', // Center align header
      align: 'center',       // Center align cell data
    },
    {
      field: 'purchase_amount',
      headerName: 'Purchase Amount',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center', // Center align header
      align: 'center',       // Center align cell data
    },
    {
      field: 'purchase_datetime',
      headerName: 'Purchase Date',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center', // Center align header
      align: 'center',       // Center align cell data
    },
    {
      field: 'action',
      headerName: 'Picked',
      sortable: false,
      width: 120, // Optional: keep a fixed width for action column if needed
      headerClassName: 'header-style',
      headerAlign: 'center', // Center align header
      align: 'center',       // Center align cell data
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={handleToggleChange(params.row.id, true)}>
            <CheckCircleOutlineIcon sx={{ color: toggleState[params.row.id] ? 'green' : 'grey' }} />
          </IconButton>
          <IconButton onClick={handleToggleChange(params.row.id, false)}>
            <CancelIcon sx={{ color: !toggleState[params.row.id] ? 'red' : 'grey' }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
      <Box
        component="form"
        onKeyDown={handleKeyDown}
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: isMobile ? 1 : 0,
          width: '100%',
          margin: 'none',
          marginBlock: '1rem',
          boxSizing: 'border-box' // Ensure padding does not cause overflow
        }}
      >
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel shrink sx={{ marginLeft: '18px' }}>Select Pickup Status</InputLabel>
          <Select
            multiple
            value={selectedPickupStatus}
            onChange={handlePickupStatusChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ mr: 45.5, height: '3rem', width: '50%', ml: '0rem' }}
            input={<OutlinedInput label="Select Pickup Status" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {pickupStatus.map((status) => (
              <MenuItem key={status} value={status}>
                <Checkbox checked={selectedPickupStatus.indexOf(status) > -1} />
                <ListItemText primary={status} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <InputBase
          sx={{
            flex: 1,
            height: '3rem',
            padding: 'none',
            border: '#B5B5B5 solid 0.5px',
            borderRadius: '10px',
            bgcolor: '#F4F4F4',
            maxWidth: '250px',
            '& .MuiInputBase-input': {
              paddingLeft: '.1rem', // Adjust this value to position the text correctly
            },
          }}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                sx={{ paddingLeft: '10px' }} // Adjust padding as needed
                aria-label="toggle password visibility"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress 
            size={60} 
            thickness={4.5} 
            sx={{ color: '#273F77' }} // Customize color to match your theme
          />
          <Typography 
            variant="h6" 
            sx={{ color: '#273F77', fontFamily: 'Inter', fontWeight: 500, marginTop: 2 }}
          >
            Loading Records...
          </Typography>
        </Box>
      ) : filteredRows.length === 0 ? (
        <Box sx={{ display: 'flex', marginTop: '100px', padding: '32px 24px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '21px' }}>
          <Box
            component="img"
            src={nodatafound}
            sx={{
              width: '211.889px',
              height: '196.824px',
              flexShrink: '0',
            }}
          />
          <Typography
            sx={{
              color: 'var(--s-primary-source, #273F77)',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: '26px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '150%',
              letterSpacing: '-0.352px',
            }}
          >
            No Records Found!
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={7}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[3]}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          disableExport
          disableMultipleColumnsSorting
          sortingOrder={[]}
          disableColumnSort
          sx={{
            '& .header-style': {
              fontSize: '1.2rem',
              fontWeight: 'bold',
            },
          }}
        />
      )}
    </Box>
  );
};

export default PurchasesTable;
