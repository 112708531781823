import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, TableContainer, Button, Table, TableBody } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AttendHeaders from './AttendHeaders';
import AttendRows from './AttendRows';
import CustomPagination from '../../../../components/Inventory/Pagination';
import AddStudentDialog from './AddStudentsDialog';
import AdminAttendanceList from '../../../../utilities/api/workshop/AdminAttendanceList';

const AttendanceTable = () => {
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const currentItem = useSelector((state) => state.editMode.currentItem);
  const workshopID = currentItem.id;
  const [attendees, setAttendees] = useState([]); // State to hold attendees
  const [shouldFetchAttendees, setShouldFetchAttendees] = useState(true); // State to trigger fetch

  useEffect(() => {
    if (shouldFetchAttendees) {
      AdminAttendanceList.AttendanceListGet(workshopID, token)
        .then((result) => {
          setAttendees(result);
          setShouldFetchAttendees(false); // Reset trigger
          console.log('AttendanceListGet', result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [shouldFetchAttendees, workshopID, token]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8); // Set items per page to 8 for demonstration
  const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog visibility

  // Get current items from currentItem's attendees
  const totalItems = attendees.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = attendees.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleUpdateAttendees = (updatedAttendees) => {
    setAttendees(updatedAttendees);
    setShouldFetchAttendees(true); // Trigger fetch after update
  };

  const handleDeleteAttendee = (deletedAttendeeId) => {
    setAttendees(prevAttendees => prevAttendees.filter(attendee => attendee.id !== deletedAttendeeId));
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <span>Attendance List</span>
        <Button
          variant="contained"
          color="primary"
          sx={{ padding: '3px 8px', borderRadius: '10px' }}
          onClick={handleOpenDialog} // Open dialog on button click
        >
          <AddIcon sx={{ marginRight: '5px' }} /> Add Students
        </Button>
      </div>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '0.5px solid rgba(181, 181, 181, 1)',
          background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
          boxShadow: 'none',
          borderRadius: '8px',
        }}
      >
        <Table>
          <AttendHeaders />
          <TableBody>
            <Box sx={{ maxHeight: '52vh', overflowY: 'auto' }}>
              {currentItems.map((item, index) => (
                <AttendRows key={index} item={item} onDelete={handleDeleteAttendee} />
              ))}
            </Box>
          </TableBody>
        </Table>
        <CustomPagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </TableContainer>
      <AddStudentDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        workshopId={currentItem.id}
        currentAttendees={attendees} // Pass the current attendees down
        onUpdateAttendees={handleUpdateAttendees} // Pass the function to update attendees
      />
    </>
  );
};

export default AttendanceTable;