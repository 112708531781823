import React, { useState } from "react";
import { Box, Button, TextField, Typography, IconButton, InputAdornment } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AdminPasswordAPIs from "../../utilities/api/AdminPasswordAPIs";
import { toast, ToastContainer } from 'react-toastify';
import Logo from '../../utilities/dummy_assets/MBF_Logo.png'; // Ensure the logo path is correct
import mapITlogo from '../../utilities/dummy_assets/mapITlogo.png'; // Ensure the logo path is correct
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const styledTextField = {
    mt: -1,
    fontSize: '24px',
    width: "460px",
    padding: "16px",
    borderRadius: "12px",
    "& .MuiOutlinedInput-root": {
        borderRadius: "12px",
        "& fieldset": {
            border: "1px solid #B5B5B5",
        },
        "&:hover fieldset": {
            borderColor: "#007BFF",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#007BFF",
        },
    },
};

const validation_schema = Yup.object({
    new_password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('Required'),
});

const ResetPasswordPage = () => {
    const [passwordReset, setPasswordReset] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle new password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility

    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get("token");

    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            const data = {
                token: token,
                new_password: values.new_password,
            };
            AdminPasswordAPIs.ResetPassword(data)
                .then((res) => {
                    setPasswordReset(true);
                    toast.success('Password reset successfully!');
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Failed to reset password. Please try again.');
                });
        },
    });

    const handleClickShowPassword = (field) => {
        if (field === "new_password") setShowNewPassword(!showNewPassword);
        if (field === "confirm_password") setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#F7F7F7", 
            }}
        >
            {/* Logo in the Top Left */}
            <Box
                sx={{
                    position: "absolute",
                    top: "12px",
                    left: "50px",
                }}
            >
                <img src={Logo} alt="MBF Logo" style={{ height: '69px', width: '159px' }} />
            </Box>

            <Box
                sx={{
                    padding: "2rem",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1.5rem",
                    textAlign: "center",
                    maxWidth: "460px",
                    width: "100%",
                }}
            >
                {!passwordReset ? (
                    <>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "36px",
                                fontWeight: 600,
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#212121",
                            }}
                        >
                            Change Your Password
                        </Typography>

                        <Typography
                            sx={{
                                mt: 1,
                                fontFamily: "Inter",
                                fontSize: "24px",
                                fontWeight: 400,
                                lineHeight: "29.05px",
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#5C5C5C",
                            }}
                        >
                            Enter a new password below to change your password
                        </Typography>

                        {/* Form */}
                        <Box
                            component="form"
                            onSubmit={formik.handleSubmit}
                            sx={{ width: "100%", p: 1, mt: 2 }}
                        >
                            {/* New Password Field */}
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "24px",
                                        fontWeight: 400,
                                        alignSelf: "flex-start",
                                    }}
                                >
                                    New Password*
                                </Typography>
                                <TextField
                                    id="new_password"
                                    name="new_password"
                                    type={showNewPassword ? "text" : "password"} // Toggle between text and password
                                    value={formik.values.new_password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                                    helperText={formik.touched.new_password && formik.errors.new_password}
                                    sx={styledTextField}
                                    placeholder="Enter new password"
                                    variant="outlined"
                                    fullWidth
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="end">
                                    //             <IconButton
                                    //                 onClick={() => handleClickShowPassword("new_password")}
                                    //                 edge="end"
                                    //             >
                                    //                 {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    //             </IconButton>
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                />
                            </Box>

                            {/* Confirm Password Field */}
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "24px",
                                        fontWeight: 400,
                                        alignSelf: "flex-start",
                                    }}
                                >
                                    Confirm Password*
                                </Typography>
                                <TextField
                                    id="confirm_password"
                                    name="confirm_password"
                                    type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
                                    value={formik.values.confirm_password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                    sx={styledTextField}
                                    placeholder="Confirm new password"
                                    variant="outlined"
                                    fullWidth
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="end">
                                    //             <IconButton
                                    //                 onClick={() => handleClickShowPassword("confirm_password")}
                                    //                 edge="end"
                                    //             >
                                    //                 {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    //             </IconButton>
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                />
                            </Box>

                            {/* Submit Button */}
                            <Button
                                type="submit"
                                sx={{
                                    height: "48px",
                                    marginTop: "1rem",
                                    padding: "1rem",
                                    fontSize: '20px',
                                    width: "100%",
                                    borderRadius: "12px",
                                    background: "#007BFF",
                                    color: "#FFF",
                                    '&:hover': {
                                        background: "#0056b3",
                                    }
                                }}
                            >
                                <Typography sx={{ fontFamily: "Inter", fontSize: "1.25rem", fontWeight: 600 }}>
                                    Reset Password
                                </Typography>
                            </Button>
                        </Box>

                        {/* Back to login link */}
                        <Typography
                            onClick={() => navigate("/login")}
                            sx={{
                                marginTop: "1rem",
                                fontSize: "20px",
                                color: "#007BFF",
                                cursor: "pointer",
                            }}
                        >
                            Back to login
                        </Typography>
                    </>
                ) : (
                    <>
                        <CheckCircleIcon sx={{ fontSize: 50, color: "rgb(30,110,178)" }} />
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "36px",
                                fontWeight: 600,
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#212121",
                            }}
                        >
                            Password Reset Successfully
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "24px",
                                fontWeight: 400,
                                lineHeight: "29.05px",
                                letterSpacing: "-0.011em",
                                textAlign: "center",
                                color: "#5C5C5C",
                            }}
                        >
                            Your password has been reset successfully. You can now log in with your new password.
                        </Typography>
                        <Button
                            onClick={() => navigate("/login")}
                            sx={{
                                height: "48px",
                                marginTop: "1rem",
                                padding: "1rem",
                                fontSize: '20px',
                                width: "100%",
                                borderRadius: "12px",
                                background: "#007BFF",
                                color: "#FFF",
                                '&:hover': {
                                    background: "#0056b3",
                                }
                            }}
                        >
                            <Typography sx={{ fontFamily: "Inter", fontSize: "1.25rem", fontWeight: 600 }}>
                                Go to Login
                            </Typography>
                        </Button>
                    </>
                )}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "16px",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '24px',
                        marginRight: '8px',
                    }}
                >
                    Powered by
                </Typography>
                <img src={mapITlogo} alt="mapIT Logo" style={{ height: '40px' }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontFamily: 'Times New Roman',
                        fontSize: '14px',
                        marginLeft: '8px',
                    }}
                >
                    mapIT.ai
                </Typography>
            </Box>

            <ToastContainer />
        </Box>
    );
};

export default ResetPasswordPage;
