import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, FormHelperText, Grid, Typography, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import InventoryListAPIs from '../../../utilities/api/inventory/AdminInventoryList';
import CustomLocationDialog from './CustomLocation';
import CustomCategoryDialog from './CustomCategory';
import CustomGroupDialog from './CustomGroup';
import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
import InventoryGroupAPIs from '../../../utilities/api/group/AdminInventoryGroupList';
import { toast } from 'react-toastify';

const taglist = ['Free', 'Issue', 'Purchase', 'Exhaustible', 'NonExhaustible', 'InLabOnly', 'HighValue'];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Product type is required'),
  // upc: Yup.number().required('UPC is required'),
  upc: Yup.number(),
  stock_available: Yup.number()
    .required('Stock available is required')
    .test('is-lte', 'Stock available cannot be more than stock total', function (value) {
      return value <= this.parent.stock_total;
    }),
  stock_total: Yup.number().required('Stock total is required'),
  category: Yup.string(),
  location: Yup.string(),
  tags: Yup.array()
    .required('This field is required')
    .test('one-of-three', 'Only one of Free, Issue, Purchase can be selected', (value) => {
      const count = ['Free', 'Issue', 'Purchase'].filter(tag => value.includes(tag)).length;
      return count <= 1;
    })
    .test('one-of-required-three', 'At least one of Free, Issue, Purchase must be selected', (value) => {
      return ['Free', 'Issue', 'Purchase'].some(tag => value.includes(tag));
    })
    .test('exclusivity', 'Exhaustible and NonExhaustible cannot be selected together', (value) => {
      return !(value.includes('Exhaustible') && value.includes('NonExhaustible'));
    }),
  manufacturer: Yup.string(),
  purchase_cost: Yup.string(),
  image: Yup.mixed(),
  // description: Yup.string().required('This field is required'),
  description: Yup.string(),
  stock_unit: Yup.string(),
});

const styledField = {
  width:'80%',
  maxWidth: '100%',
};

const RenderTextField = ({ name, label, type = 'text', sx }) => {
  const [field, meta] = useField(name);
  return (
    <TextField
      type={type}
      sx={sx}
      {...field}
      fullWidth
      label={label}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
    />
  );
};

const RenderSelectField = ({ name, label, options, sx }) => {
  const [field, meta] = useField(name);
  return (
    <TextField
      sx={sx}
      {...field}
      select
      fullWidth
      label={label}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
    >
      {options.map((option) => (
        <MenuItem sx={{ color: 'black' }} key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

const RenderFileUpload = ({ name, sx }) => {
  const [field, meta, helpers] = useField(name);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];

    if (file) {
      // Check if file size exceeds 1MB (1MB = 1,048,576 bytes)
      const fileSizeLimit = 1048576; // 1MB in bytes
      if (file.size > fileSizeLimit) {
        toast.error('File size should not exceed 1MB');
        return;  // Stop the upload process if the file is too large
      }

      // Check if the file is an image
      if (!file.type.startsWith('image/')) {
        toast.error('Only image files are allowed!');
        return;
      }

      // If both validations pass, set the image and display a preview
      helpers.setValue(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <Box>
      <input
        type='file'
        accept='image/*'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        onClick={() => fileInputRef.current.click()}
        sx={{
          height: preview ? "40px" : "100px",
          width: "150px",
          padding: preview ? "8px 12px" : "12px 16px",
          margin: 1,
          background: preview ? "#FFE6E6" : "#E6F2FF",
          color: "#3F3F3F",
          border: preview ? "1px solid #FF6666" : "1px solid #66B0FF",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontSize: preview ? 14 : 24, p: 0, m: 0, mt: preview ? 0 : -1 }}>
          {preview ? "Change Image" : "+"}
        </Typography>
        {!preview && (
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            Upload File
          </Typography>
        )}
      </Button>
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
      {preview && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <img src={preview} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
      )}
    </Box>
  );
};

const RenderDatePicker = ({ name, label, sx }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={field.value ? dayjs(field.value) : null}
        onChange={(value) => {
          helpers.setValue(dayjs(value).format('YYYY-MM-DD'));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={sx}
            fullWidth
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const AddInventoryDialog = ({ open, onClose, update, setUpdate, flag, setFlag }) => {
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const [openCustomLocation, setOpenCustomLocation] = useState(false);
  const [customLocation, setCustomLocation] = useState('');

  const [openCustomCategory, setOpenCustomCategory] = useState(false);
  const [customCategory, setCustomCategory] = useState('');

  const [openCustomGroup, setOpenCustomGroup] = useState(false);
  const [customGroup, setCustomGroup] = useState('');

  const category = useSelector(state => state.category.category_list);
  const location = useSelector(state => state.location.location_list);
  const group = useSelector(state => state.group.group_list);

  const categories = category.map(item => item.name);
  const locations = location.map(item => item.name);
  const groups = group.map(item => item.name);

  const handleConfirmLocation = () => {
    setOpenCustomLocation(false);
    const response = InventoryLocationAPIs.LocationListPost(customLocation);
    response.then((result) => {
      console.log(result);
      toast.success('Location added successfully');
      setFlag(prev => !prev);
    }).catch((err) => {
      console.log('error', err);
      toast.error('Failed to add location');
    });
  };
  
  const handleConfirmCategory = () => {
    setOpenCustomCategory(false);
    const response = InventoryCategoryAPIs.CategoryListPost(customCategory);
    response.then((result) => {
      console.log(result);
      toast.success('Category added successfully');
      setFlag(prev => !prev);
    }).catch((err) => {
      console.log('error', err);
      toast.error('Failed to add category');
    });
  };
  
  const handleConfirmGroup = () => {
    setOpenCustomGroup(false);
    const response = InventoryGroupAPIs.GroupListPost(customGroup);
    response.then((result) => {
      console.log(result);
      toast.success('Group added successfully');
      setFlag(prev => !prev);
    }).catch((err) => {
      console.log('error', err);
      toast.error('Failed to add group');
    });
  };
  
  const handleFormSubmit = async (values, actions) => {
    console.log('handleFormSubmit', values);
    const formData = new FormData();
  
    // Append all form values to formData except tags
    for (const key in values) {
      if (values.hasOwnProperty(key) && key !== 'tags') {
        formData.append(key, values[key]);
      }
    }
  
    // Define a mapping for the tag values
    const tagMapping = {
      Free: "0",
      Issue: "1",
      Purchase: "2",
      Exhaustible: "3",
      NonExhaustible: "4",
      InLabOnly: "5",
      HighValue: "6"
    };
  
    // Map the selected tags to `tag1`, `tag2`, etc.
    values.tags.forEach((tag, index) => {
      const tagKey = `tag${index + 1}`;
      const tagValue = tagMapping[tag] || ""; // Default to empty if not found in mapping
      formData.append(tagKey, tagValue);
    });
  
    try {
      const response = await InventoryListAPIs.AddInventory(formData, token);
      console.log(response);
      toast.success('Product added successfully');
      onClose();
      setUpdate(prev => !prev);
      actions.resetForm();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).flat().join(' ');
        toast.error(`Failed to add product: ${errorMessages}`);
        actions.setErrors({ submit: errorMessages });
      } else {
        toast.error('Failed to add product: An unexpected error occurred.');
      }
      actions.setSubmitting(false);
    }
  };
  
  
  const handleFormClose = (resetForm) => {
    onClose();
    // resetForm();
    toast.info('Form discarded');
  };

  return (
    <Dialog open={open} onClose={() => handleFormClose()} fullWidth maxWidth="md">
      <DialogTitle>Add New Inventory</DialogTitle>
      <Formik
        initialValues={{
          name: '',
          upc: '',
          manufacturer: '',
          description: ' ',
          location: '',
          stock_available: '',
          stock_total: '',
          stock_unit: '',
          purchase_cost: '',
          purchase_date: '',
          warranty_expiration: '',
          image: '',
          category: '',
          group: '',
          tags: [],
          is_Hidden: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, values, touched, errors, handleChange, setFieldValue, isSubmitting, resetForm }) => (
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Product Name*</Typography>
                    <RenderTextField name="name" label="Name of product" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>UPC</Typography>
                    <RenderTextField name="upc" label="Enter UPC" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Stock Available*</Typography>
                    <RenderTextField name="stock_available" label="Enter stock available" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Stock Total*</Typography>
                    <RenderTextField name="stock_total" label="Enter stock total" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Manufacturer Name</Typography>
                    <RenderTextField name="manufacturer" label="Enter manufacturer name" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Location</Typography>
                      <RenderSelectField name="location" label="Select location" options={locations} sx={styledField} />
                      <IconButton aria-label="custom-location" onClick={() => setOpenCustomLocation(true)}>
                        <AddIcon sx={{ color: 'green', alignSelf:'center' }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <CustomLocationDialog
                    openCustomLocation={openCustomLocation}
                    setCustomLocation={setCustomLocation}
                    onClose={() => setOpenCustomLocation(false)}
                    onConfirm={() => {
                      setFieldValue('location', customLocation);
                      handleConfirmLocation();
                    }}
                  />
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Purchase Cost</Typography>
                    <RenderTextField name="purchase_cost" label="Enter purchase cost" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Inventory Image</Typography>
                    <RenderFileUpload name="image" sx={styledField} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Category</Typography>
                      <RenderSelectField name="category" label="Select Category" options={categories} sx={styledField} />
                    </Box>
                    <IconButton aria-label="custom-category" onClick={() => setOpenCustomCategory(true)}>
                      <AddIcon sx={{ color: 'green' }} />
                    </IconButton>
                  </Box>
                  <CustomCategoryDialog
                    openCustomCategory={openCustomCategory}
                    setCustomCategory={setCustomCategory}
                    onClose={() => setOpenCustomCategory(false)}
                    onConfirm={() => {
                      setFieldValue('category', customCategory);
                      handleConfirmCategory();
                    }}
                  />
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Tags*</Typography>
                    <FormControl sx={{ width: '100%' }} error={Boolean(errors.tags && touched.tags)}>
                      <Select
                        multiple
                        value={values.tags}
                        onChange={(event) => {
                          setFieldValue('tags', event.target.value);
                        }}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        {taglist.map((tag) => (
                          <MenuItem sx={{ color: 'black' }} key={tag} value={tag}>
                            {tag}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText><ErrorMessage name="tags" /></FormHelperText>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Group</Typography>
                      <RenderSelectField name="group" label="Select group" options={groups} sx={styledField} />
                    </Box>
                    <IconButton aria-label="custom-group" onClick={() => setOpenCustomGroup(true)}>
                      <AddIcon sx={{ color: 'green' }} />
                    </IconButton>
                  </Box>
                  <CustomGroupDialog
                    openCustomGroup={openCustomGroup}
                    setCustomGroup={setCustomGroup}
                    onClose={() => setOpenCustomGroup(false)}
                    onConfirm={() => {
                      setFieldValue('group', customGroup);
                      handleConfirmGroup();
                    }}
                  />
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Stock Unit</Typography>
                    <RenderTextField name="stock_unit" label="Enter stock unit" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Purchase Date</Typography>
                    <RenderDatePicker name="purchase_date" label="Select date" sx={styledField} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Warranty Expiration</Typography>
                    <RenderDatePicker name="warranty_expiration" label="Select date" sx={styledField} />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mt: 2, mb: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>Description </Typography>
                <Field
                  as={TextField}
                  name="description"
                  label="Enter description"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.description}
                  onChange={handleChange}
                  sx={{ mt: 2, mb: 1 }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleFormClose(resetForm)}
                color="primary"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '12px',
                  borderWidth: '1px',
                  borderColor: 'action.active',
                  '&:hover': {
                    borderWidth: '1px',
                    borderColor: 'action.active',
                  },
                  mr: 1,
                }}
                disabled={isSubmitting}
              >
                Discard
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: 'common.white',
                  backgroundColor: 'primary.main',
                  borderRadius: '12px',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
                disabled={isSubmitting}
              >
                Add Product
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddInventoryDialog;
