import { axiosApiService } from "../axios";

const OrgAllStatsAPIs = {

    // Get all stats
    AllStats: async function () {
        try {
            const response = await axiosApiService.get(`/stats/all-stats/`);
            console.log('All Stats Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching all stats:', error);
            throw error;
        }
    },

    // Get booking hours
    BookingHours: async function () {
        try {
            const response = await axiosApiService.get(`/stats/booking_hours/`);
            console.log('Booking Hours Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching booking hours:', error);
            throw error;
        }
    },

    // Get available inventory
    InventoryAvailable: async function () {
        try {
            const response = await axiosApiService.get(`/stats/inventory_available/`);
            console.log('Inventory Available Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching available inventory:', error);
            throw error;
        }
    },

    // Get purchased inventory
    InventoryPurchased: async function () {
        try {
            const response = await axiosApiService.get(`/stats/inventory_purchased/`);
            console.log('Inventory Purchased Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching purchased inventory:', error);
            throw error;
        }
    },

    // Get issues reported
    IssueReported: async function () {
        try {
            const response = await axiosApiService.get(`/stats/issue_reported/`);
            console.log('Issues Reported Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching reported issues:', error);
            throw error;
        }
    },

    // Get issues resolved
    IssueResolved: async function () {
        try {
            const response = await axiosApiService.get(`/stats/issue_resolved/`);
            console.log('Issues Resolved Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching resolved issues:', error);
            throw error;
        }
    },

    // Get machine booked stats
    MachineBooked: async function () {
        try {
            const response = await axiosApiService.get(`/stats/machine_booked/`);
            console.log('Machine Booked Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching machine booked stats:', error);
            throw error;
        }
    },

    // Get most used items
    MostUsedItems: async function () {
        try {
            const response = await axiosApiService.get(`/stats/most_used_items/`);
            console.log('Most Used Items Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching most used items:', error);
            throw error;
        }
    },

    // Get most used machines
    MostUsedMachines: async function () {
        try {
            const response = await axiosApiService.get(`/stats/most_used_machines/`);
            console.log('Most Used Machines Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching most used machines:', error);
            throw error;
        }
    },

    // Get project covered stats
    ProjectCovered: async function () {
        try {
            const response = await axiosApiService.get(`/stats/project_covered/`);
            console.log('Project Covered Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching project covered stats:', error);
            throw error;
        }
    },

    // Get project types
    ProjectTypes: async function () {
        try {
            const response = await axiosApiService.get(`/stats/project_types/`);
            console.log('Project Types Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching project types:', error);
            throw error;
        }
    },

    // Get workshop completed stats
    WorkshopCompleted: async function () {
        try {
            const response = await axiosApiService.get(`/stats/workshop_completed/`);
            console.log('Workshop Completed Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching completed workshops:', error);
            throw error;
        }
    },

    // Get workshop running stats
    WorkshopRunning: async function () {
        try {
            const response = await axiosApiService.get(`/stats/workshop_running/`);
            console.log('Workshop Running Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching running workshops:', error);
            throw error;
        }
    },
};

export default OrgAllStatsAPIs;
