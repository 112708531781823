import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, IconButton, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import WorkbenchAPIs from '../../utilities/api/workbench/WorkbenchAPIs'; // Import the WorkbenchAPIs utility
import { toast } from 'react-toastify'; // Import toast for notifications
import nodatafound from '../../utilities/dummy_assets/nodatafound.jpg'; // Adjust path as needed
import EditWorkbenchDialog from './Popups/EditWorkbenchDialog';

const WorkbenchTable = ({ searchQuery, update, setUpdate, flag, setFlag }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);  // To handle loading state
  const [error, setError] = useState(null);      // To handle errors
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // To manage delete confirmation dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);     // To manage edit dialog state
  const [selectedWorkbench, setSelectedWorkbench] = useState(null); // Store selected workbench ID for deletion or editing

  useEffect(() => {
    // Fetch the list of workbenches from the API
    const fetchData = async () => {
      setLoading(true); // Start loading
      setError(null); // Reset any previous errors

      try {
        const response = await WorkbenchAPIs.getList(); // Fetch workbench data
        // Transform the data to fit the structure expected by the DataGrid
        const formattedData = response.map((item) => ({
          id: item.id,
          workbenchNumber: item.id.toString(), // Using id as workbenchNumber
          seats: item.seats,
          inventory: item.available_machines, // Mapping available_machines to inventory
          startTime: item.slot_from, // Separate start time
          endTime: item.slot_to,     // Separate end time
          description: item.description
        }));
        setTableData(formattedData); // Set the formatted data to tableData
      } catch (error) {
        setError('Failed to fetch workbench data');
        console.error('Error fetching workbenches:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [update]); // Refetch data if the update prop changes

  // Handle delete dialog open
  const handleDeleteClick = (workbenchId) => {
    setSelectedWorkbench(workbenchId); // Store selected workbench ID for deletion
    setDeleteDialogOpen(true); // Open confirmation dialog
  };

  // Handle edit dialog open
  const handleEditClick = (workbenchId) => {
    setSelectedWorkbench(workbenchId); // Store selected workbench ID for editing
    setEditDialogOpen(true); // Open the edit dialog
  };

  // Handle delete confirmation
  const handleConfirmDelete = async () => {
    try {
      await WorkbenchAPIs.deleteWorkbench(selectedWorkbench); // Call API to delete workbench
      toast.success('Workbench deleted successfully!'); // Show success message
      setUpdate(prev => !prev); // Trigger re-fetching of data
    } catch (error) {
      console.error('Error deleting workbench:', error);
      toast.error('Failed to delete workbench. Please try again.'); // Show error message
    } finally {
      setDeleteDialogOpen(false); // Close the dialog
      setSelectedWorkbench(null); // Reset selected workbench
    }
  };

  // Handle canceling delete
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false); // Close the dialog
    setSelectedWorkbench(null); // Reset selected workbench
  };

  // Handle canceling edit
  const handleCancelEdit = () => {
    setEditDialogOpen(false); // Close the edit dialog
    setSelectedWorkbench(null); // Reset selected workbench
  };

  // Filter rows based on the search query (optional)
  const filteredRows = tableData.filter((row) => {
    const workbenchNumber = row.workbenchNumber || ''; // Ensure it's a string
    return workbenchNumber.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const columns = [
    { field: 'workbenchNumber', headerName: 'Work Bench', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'seats', headerName: 'No. of seats', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'inventory', headerName: 'Inventory available', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'startTime', headerName: 'Start Time', flex: 1, align: 'center', headerAlign: 'center' }, // New Start Time column
    { field: 'endTime', headerName: 'End Time', flex: 1, align: 'center', headerAlign: 'center' },     // New End Time column
    { field: 'description', headerName: 'Description', flex: 1, align: 'center', headerAlign: 'center' },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
          <IconButton aria-label="edit" onClick={() => handleEditClick(params.id)}>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.id)}>
            <DeleteOutlineSharpIcon color="error" />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Handling loading state
  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <CircularProgress />
        <Typography sx={{ marginTop: 2 }}>Loading workbench data...</Typography>
      </Box>
    );
  }

  // Handling error state
  if (error) {
    return <Typography align="center">{error}</Typography>;
  }

  return (
    <Box
      sx={{
        height: '480px',
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        padding: '16px',
        boxSizing: 'border-box',
      }}
    >
      {filteredRows.length === 0 ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
          <img src={nodatafound} alt="No Data Found" style={{ width: '350px', marginBottom: '8px' }} />
          <Typography align="center">No Workbench data available</Typography>
        </Box>
      ) : (
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              color: '#3f3f3f',
              fontWeight: 'bold',
              fontSize: '16px',
              textAlign: 'center',
            },
            '& .MuiDataGrid-cell': {
              textAlign: 'center',
              color: '#3f3f3f',
            },
            '& .MuiDataGrid-footerContainer': {
              justifyContent: 'center',
              alignItems: 'center',
              '& .MuiTablePagination-root': {
                textAlign: 'center',
                '& .MuiButtonBase-root': {
                  color: '#3f3f3f',
                },
              },
            },
            '& .MuiPaginationItem-root': {
              color: '#3f3f3f',
            },
          }}
        />
      )}

      {/* Edit Workbench Dialog */}
      <EditWorkbenchDialog
        open={editDialogOpen}
        onClose={handleCancelEdit}
        workbenchId={selectedWorkbench}
        update={update}
        setUpdate={setUpdate}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle align="center">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Are you sure you want to delete this workbench? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorkbenchTable;
