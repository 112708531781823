import * as Yup from 'yup';
import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Box, Dialog, DialogTitle, DialogContent, DialogActions, Button,
  TextField, Grid, IconButton, MenuItem, Typography, FormHelperText,
  FormControlLabel, Checkbox
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import CustomLocationDialog from './CustomLocation';
import CustomCategoryDialog from './CustomCategory';
import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
import MachineDetailAPIs from '../../../utilities/api/machine/AdminMachineDetail';
import { ImageBaseURL } from "../../../utilities/api/axios";
import { toast } from 'react-toastify';

// Availability and Status Lists
const availabilityList = ['Very Low', 'Low', 'Moderate', 'High', 'Always'];
const statusList = ['Operational', 'Under Maintenance', 'Out of Service'];

// Updated Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  upc: Yup.number(),
  instances: Yup.number().required('Instances are required'),
  description: Yup.string(),
  category: Yup.string(),
  location: Yup.string(),
  availability: Yup.string(),
  status: Yup.string(),
  supervised: Yup.boolean(),
  manufacturer: Yup.string(),
  purchase_cost: Yup.number().typeError('A valid number is required'),
  image: Yup.mixed().nullable(),
  // Removed purchase_date and warranty_expiration
});

// Styling for form fields
const styledField = {
  maxWidth: '70%',
  padding: '0px 4px 8px 6px',
};

const EditMachineDialog = ({ currentRow, open, onClose, update, setUpdate, flag, setFlag }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const category = useSelector(state => state.category.category_list);
  const location = useSelector(state => state.location.location_list);
  const categories = category.map(item => item.name);
  const locations = location.map(item => item.name);

  const [openCustomLocation, setOpenCustomLocation] = useState(false);
  const [customLocation, setCustomLocation] = useState('');
  const [openCustomCategory, setOpenCustomCategory] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [newImage, setNewImage] = useState(null); // State for the new image
  const fileInputRef = useRef(null);
  // Pre-fill image preview if currentRow has an image
  useEffect(() => {
    if (currentRow?.image) {
      setNewImage(`${ImageBaseURL}${currentRow.image}`);
    }
  }, [currentRow]);

  useEffect(() => {
    if (!open) {
      setNewImage(null);
    }
  }, [open]);

  const handleConfirmLocation = () => {
    setOpenCustomLocation(false);
    const response = InventoryLocationAPIs.LocationListPost(customLocation, token);
    response.then((result) => {
      console.log(result);
      toast.success('Location added successfully');
      setFlag(prev => !prev);
      // Optionally, set the new location in the form
    }).catch((err) => {
      console.error('Location addition error:', err);
      toast.error(`Failed to add location: ${err.response?.data?.message || err.message}`);
    });
  };

  const handleConfirmCategory = () => {
    setOpenCustomCategory(false);
    const response = InventoryCategoryAPIs.CategoryListPost(customCategory, token);
    response.then((result) => {
      console.log(result);
      toast.success('Category added successfully');
      setFlag(prev => !prev);
      // Optionally, set the new category in the form
    }).catch((err) => {
      console.error('Category addition error:', err);
      toast.error(`Failed to add category: ${err.response?.data?.message || err.message}`);
    });
  };

  const initialValues = {
    id: currentRow?.id || '',
    name: currentRow?.name || '',
    upc: currentRow?.upc || '',
    manufacturer: currentRow?.manufacturer || '',
    description: currentRow?.description || '',
    availability: currentRow?.availability || '',
    status: currentRow?.status || '',
    category: currentRow?.category || '',
    location: currentRow?.location || '',
    instances: currentRow?.instances || '',
    purchase_cost: currentRow?.purchase_cost || '',
    image: null,
    supervised: currentRow?.supervised || false,
    // Removed purchase_date and warranty_expiration
    // is_Hidden: currentRow?.is_Hidden || false, // If needed, uncomment and handle accordingly
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Machine</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize // To update form when currentRow changes
        onSubmit={(values, { setSubmitting }) => {
          const formData = new FormData();

          // Append form values to formData
          for (const key in values) {
            if (values.hasOwnProperty(key) && key !== 'image') {
              formData.append(key, values[key]);
            }
          }

          // Append image to formData if it exists
          if (values.image) {
            formData.append('image', values.image);
          }

          // Handle availability and availability1
          if (values.availability) {
            // Map the selected availability to its index
            const availabilityIndex = availabilityList.indexOf(values.availability);
            formData.append('availability', values.availability);
            formData.append('availability1', availabilityIndex.toString());
          } else {
            // Send empty strings for availability and availability1 if not set
            formData.append('availability', '');
            formData.append('availability1', '');
          }

          // Handle status and status1
          if (values.status) {
            const statusIndex = statusList.indexOf(values.status);
            formData.append('status', values.status);
            formData.append('status1', statusIndex.toString());
          } else {
            formData.append('status', '');
            formData.append('status1', '');
          }

          // Handle supervised
          formData.append('supervised', values.supervised);

          // API call to update the machine
          const response = MachineDetailAPIs.MachineDetailPut(currentRow.id, formData, token, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          response.then((result) => {
            console.log(result);
            toast.success('Machine updated successfully');
            onClose();
            setUpdate(prev => !prev);
          }).catch((error) => {
            console.error('Machine update error:', error);
            if (error.response && error.response.data) {
              const errorMessages = Object.values(error.response.data).flat().join(' ');
              toast.error(`Failed to update machine: ${errorMessages}`);
            } else {
              toast.error('Failed to update machine: An unexpected error occurred.');
            }
          });
        }}
      >
        {({ handleSubmit, values, touched, errors, handleChange, setFieldValue, resetForm }) => {
          const handleFileChange = (event) => {
            const file = event.currentTarget.files[0];

            if (file) {
              // Check if file size exceeds 1MB (1MB = 1,048,576 bytes)
              const fileSizeLimit = 1048576; // 1MB in bytes
              if (file.size > fileSizeLimit) {
                toast.error('File size should not exceed 1MB');
                return;  // Do not proceed with file upload
              }

              // Check if the file is an image
              if (!file.type.startsWith('image/')) {
                toast.error('Only image files are allowed');
                return;
              }

              // If both validations pass, set the image and display a preview
              setFieldValue('image', file);
              setNewImage(URL.createObjectURL(file));
            }
          };

          const handleDiscard = () => {
            resetForm();  // Reset the form on discard
            setNewImage(currentRow?.image ? `${ImageBaseURL}${currentRow.image}` : null);  // Reset image preview
            onClose();
            toast.info('Changes discarded');
          };

          return (
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <DialogContent>
                <Grid container spacing={2}>
                  {/* Left Column */}
                  <Grid item xs={12} sm={6}>
                    {/* Name */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Name*</Typography>
                    <Field
                      name="name"
                      as={TextField}
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      sx={styledField}
                    />

                    {/* UPC */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>UPC</Typography>
                    <Field
                      name="upc"
                      as={TextField}
                      fullWidth
                      type="number"
                      value={values.upc}
                      onChange={handleChange}
                      error={touched.upc && Boolean(errors.upc)}
                      helperText={touched.upc && errors.upc}
                      sx={styledField}
                    />

                    {/* Instances */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Instances*</Typography>
                    <Field
                      name="instances"
                      as={TextField}
                      fullWidth
                      type="number"
                      value={values.instances}
                      onChange={handleChange}
                      error={touched.instances && Boolean(errors.instances)}
                      helperText={touched.instances && errors.instances}
                      sx={styledField}
                    />

                    {/* Manufacturer */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Manufacturer</Typography>
                    <Field
                      name="manufacturer"
                      as={TextField}
                      fullWidth
                      value={values.manufacturer}
                      onChange={handleChange}
                      error={touched.manufacturer && Boolean(errors.manufacturer)}
                      helperText={touched.manufacturer && errors.manufacturer}
                      sx={styledField}
                    />

                    {/* Availability */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Availability</Typography>
                    <Field
                      name="availability"
                      as={TextField}
                      select
                      fullWidth
                      value={values.availability}
                      onChange={handleChange}
                      error={touched.availability && Boolean(errors.availability)}
                      helperText={touched.availability && errors.availability}
                      sx={styledField}
                    >
                      {availabilityList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>

                    {/* Purchase Cost */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Purchase Cost</Typography>
                    <Field
                      name="purchase_cost"
                      as={TextField}
                      fullWidth
                      type="number"
                      value={values.purchase_cost}
                      onChange={handleChange}
                      error={touched.purchase_cost && Boolean(errors.purchase_cost)}
                      helperText={touched.purchase_cost && errors.purchase_cost}
                      sx={styledField}
                    />

                    {/* Machine Image */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Machine Image</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      {newImage && (
                        <img src={newImage} alt="Machine" style={{ width: '200px', height: '200px', objectFit: 'cover', marginBottom: '8px' }} />
                      )}
                      <Button
                        variant="contained"
                        onClick={() => fileInputRef.current.click()}
                        sx={{
                          height: newImage ? "40px" : "100px",
                          width: "150px",
                          padding: newImage ? "8px 12px" : "12px 16px",
                          margin: 1,
                          background: newImage ? "#FFE6E6" : "#E6F2FF",
                          color: "#3F3F3F",
                          border: newImage ? "1px solid #FF6666" : "1px solid #66B0FF",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ fontSize: newImage ? 14 : 24, p: 0, m: 0, mt: newImage ? 0 : -1 }}>
                          {newImage ? "Change Image" : "+"}
                        </Typography>
                        {!newImage && (
                          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                            Upload File
                          </Typography>
                        )}
                      </Button>

                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      {touched.image && errors.image && (
                        <FormHelperText error>{errors.image}</FormHelperText>
                      )}
                    </Box>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={12} sm={6}>
                    {/* Category with Add Button */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Category</Typography>
                      <IconButton aria-label="custom-category" onClick={() => setOpenCustomCategory(true)}>
                        <AddIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </Box>
                    <Field
                      name="category"
                      as={TextField}
                      select
                      fullWidth
                      value={values.category}
                      onChange={handleChange}
                      error={touched.category && Boolean(errors.category)}
                      helperText={touched.category && errors.category}
                      sx={styledField}
                    >
                      {categories.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                    <CustomCategoryDialog
                      openCustomCategory={openCustomCategory}
                      setCustomCategory={setCustomCategory}
                      onClose={() => setOpenCustomCategory(false)}
                      onConfirm={() => {
                        setFieldValue('category', customCategory);
                        handleConfirmCategory();
                      }}
                    />

                    {/* Location with Add Button */}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Location</Typography>
                      <IconButton aria-label="custom-location" onClick={() => setOpenCustomLocation(true)}>
                        <AddIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </Box>
                    <Field
                      name="location"
                      as={TextField}
                      select
                      fullWidth
                      value={values.location}
                      onChange={handleChange}
                      error={touched.location && Boolean(errors.location)}
                      helperText={touched.location && errors.location}
                      sx={styledField}
                    >
                      {locations.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                    <CustomLocationDialog
                      openCustomLocation={openCustomLocation}
                      setCustomLocation={setCustomLocation}
                      onClose={() => setOpenCustomLocation(false)}
                      onConfirm={() => {
                        setFieldValue('location', customLocation);
                        handleConfirmLocation();
                      }}
                    />

                    {/* Status */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey', marginTop: '16px' }}>Status</Typography>
                    <Field
                      name="status"
                      as={TextField}
                      select
                      fullWidth
                      value={values.status}
                      onChange={handleChange}
                      error={touched.status && Boolean(errors.status)}
                      helperText={touched.status && errors.status}
                      sx={styledField}
                    >
                      {statusList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>

                    {/* Supervised Checkbox */}
                    <Box sx={{ paddingTop: '16px' }}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            name="supervised"
                            color="primary"
                            checked={values.supervised}
                            onChange={handleChange}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: '1.25rem', color: 'grey' }}>
                            Supervised
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                      {touched.supervised && errors.supervised && (
                        <FormHelperText error>{errors.supervised}</FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                {/* Description */}
                <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey', mt: 4 }}>Description</Typography>
                <Field
                  name="description"
                  as={TextField}
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  sx={{ mt: 2, mb: 1 }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDiscard}  // Call handleDiscard on discard
                  color="primary"
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '12px',
                    borderWidth: '1px',
                    borderColor: 'action.active',
                    '&:hover': {
                      borderWidth: '1px',
                      borderColor: 'action.active',
                    },
                    mr: 1,
                  }}
                >
                  Discard
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    color: 'common.white',
                    backgroundColor: 'primary.main',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  }}
                >
                  Save Changes
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditMachineDialog;
