import React, { useEffect, useRef } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import Chart from 'chart.js/auto';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const AdminRolesChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (chartInstance.current) {
        try {
          chartInstance.current.resize();
        } catch (error) {
          console.error("ResizeObserver error:", error);
        }
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (data && data.data.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const total = data.data.reduce((acc, value) => acc + value, 0);

      chartInstance.current = new Chart(chartRef.current, {
        type: 'doughnut',
        data: {
          labels: data.labels,
          datasets: [{
            data: data.data,
            backgroundColor: [
              'rgba(153,102,255,0.4)',
              'rgba(255,159,64,0.4)',
              'rgba(75,192,192,0.4)',
              'rgba(54,162,235,0.4)',
              'rgba(255,99,132,0.4)',
              'rgba(255,206,86,0.4)',
            ],
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.label || '';
                  const value = context.raw;
                  const percentage = ((value / total) * 100).toFixed(2);
                  return `${label}: ${value} (${percentage}%)`;
                },
              },
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <Paper elevation={3} style={{ padding: '8px', borderRadius: '20px', backgroundColor: '#f9fafe', border: '1.3px solid #d3d3d3', width: '100%', height: '418px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6" style={{ fontSize: '24px', fontWeight: '700', color: '#212121', textAlign: 'left' }}>Different Admin Roles</Typography>
      {data && data.data.length > 0 ? (
        <Box style={{ width: '100%', height: '380px', position: 'relative' }}>
          <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="380px" width="100%">
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
          <Typography variant='h5' color="textSecondary">
            No data available
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default AdminRolesChart;
