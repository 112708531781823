import React from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import expandleft from '../../../utilities/dummy_assets/Expand_left.png';
import Auth_APIs from '../../../utilities/api/auth_api';

const buttonStyle = {
  display: 'flex',
  width: '25rem',
  padding: '1rem 1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  borderRadius: '0.75rem',
  background: 'var(--Accent-Accent-00, #007BFF)',
};

const typographyStyle = {
  color: 'var(--Primary-white, #FFF)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.0165rem',
};

const headingStyles = {
  color: '#5C5C5C',
  fontWeight: 400,
  fontSize: '20px',
};

const inputFieldsStyles = {
  height: '4rem',
  borderRadius: '12px',
  color: '#B5B5B5',
  fontSize: '16px',
  fontWeight: 400,
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    "& fieldset": {
      border: "1.5px solid #B5B5B5", // Custom border
    },
    "&:hover fieldset": {
      borderColor: "#B5B5B5", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B5B5B5", // Border color when focused
    },
  },
  "& .MuiInputLabel-root": {
    backgroundColor: '#F7F7F7',
    padding: '0 4px',
    zIndex: 1,
  },
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone_number: Yup.string().required('Phone number is required'),
  other_position: Yup.string(),
});

const OrganizationalRegistrationForm = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      other_position: '',
      TL: 'Org-Admin',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await Auth_APIs.orgAdminregister(values);
        toast.success('Successfully registered');

        setTimeout(() => {
          navigate('/login');
        }, 1500); // Delay navigation
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessage = Object.values(error.response.data).flat().join(' ');
          toast.error(errorMessage);
        } else {
          toast.error('Registration failed. Please try again.');
        }
      }
    },
  });

  return (
    <Box
      sx={{
        width: '50rem',
        height: '30rem',
        mx: 'auto',
        p: 2,
        backgroundColor: 'transparent',
      }}
    >
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6}>
            <Box mb={1}>
              <Typography variant="h6" sx={headingStyles}>
                User Name
              </Typography>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Enter user name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={inputFieldsStyles}
              />
            </Box>
            <Box mb={1}>
              <Typography variant="h6" sx={headingStyles}>
                Phone No.
              </Typography>
              <TextField
                fullWidth
                id="phone_number"
                name="phone_number"
                label="Enter phone no."
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                helperText={formik.touched.phone_number && formik.errors.phone_number}
                sx={inputFieldsStyles}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={1}>
              <Typography variant="h6" sx={headingStyles}>
                User Email Address
              </Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Enter user email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={inputFieldsStyles}
              />
            </Box>
            <Box mb={1}>
              <Typography variant="h6" sx={headingStyles}>
                Role
              </Typography>
              <TextField
                fullWidth
                id="other_position"
                name="other_position"
                label="Enter Role"
                value={formik.values.other_position}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.other_position && Boolean(formik.errors.other_position)}
                helperText={formik.touched.other_position && formik.errors.other_position}
                sx={inputFieldsStyles}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'stretch', gap: '2rem', marginTop: '20px' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/login')}
            sx={{
              display: 'flex',
              width: '25rem',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              borderRadius: '0.75rem',
              border: '1px solid var(--Primary-Primary03, #5C5C5C)',
            }}
          >
            <Typography sx={typographyStyle && { color: 'black' }}>Cancel</Typography>
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={buttonStyle}
          >
            <Typography sx={typographyStyle}>Register</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            paddingTop: '.5rem'
          }}
        >
          <Box sx={{display:'flex'}}>
            <img src={expandleft} onClick={() => navigate('/login')}
              style={{
                cursor: 'pointer',
              }} />
            <Typography onClick={() => navigate('/login')} sx={typographyStyle && { fontSize: '20px', fontWeight:400, letterSpacing: '-0.01375rem', color: 'var(--Accent-Accent-00, #007BFF)', cursor: 'pointer' }}>
              Back
            </Typography>
          </Box>
          <Typography sx={typographyStyle && { fontSize: '20px', fontWeight:400, letterSpacing: '-0.01375rem', }}>
            Already have an account? <span onClick={() => navigate('/login')} style={{ color: 'var(--Accent-Accent-01, #0062CC)', cursor: 'pointer' }}>Log in here</span>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default OrganizationalRegistrationForm;
