import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Typography,
  InputBase,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Checkbox,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import nodatafound from '../../utilities/dummy_assets/No data-cuate 1.jpg'; // Placeholder image
import { useSelector } from 'react-redux';
import WorkbenchBookingAPIs from '../../utilities/api/workbench/WorkbenchBookingAPIs';
import { toast } from 'react-toastify';

const approvalChoices = [
  { value: '', label: 'All' },
  { value: '0', label: 'Pending' },
  { value: '1', label: 'Approved' },
  { value: '2', label: 'Rejected' },
];

const WorkbenchesBookingTable = () => {
  const user = useSelector((state) => state.user?.user);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [approvalFilter, setApprovalFilter] = useState(''); // New state for filtering by approval status
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    setLoading(true); // Set loading state to true while fetching
    try {
      const response = await WorkbenchBookingAPIs.getList();
      const fetchedData = response.map((item) => ({
        id: item.id,
        group_names: item.group_names.join(', '), // Join group names as a string
        booking_date: item.booking_date, // Booking date from the API
        start_time: item.start_time, // Start time of the booking
        end_time: item.end_time, // End time of the booking
        approved_status: item.approved_status, // Approval status (numeric value)
        description: item.description, // Description of the booking
        workbench: item.workbench, // Workbench ID from the API
      }));

      setRows(fetchedData.reverse()); // Set the fetched data
    } catch (error) {
      console.error('Error fetching workbench data:', error);
    } finally {
      setLoading(false); // Set loading state to false after fetching
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount or when the user changes
  }, [user]);

  const handlePageChange = useCallback((newPage) => setPage(newPage), []);

  const handleApprovalChange = async (id, newStatus) => {
    try {
      await WorkbenchBookingAPIs.updateBooking(id, { approved_status: newStatus });
      toast.success('Approval status updated successfully!');

      // Update the local state to reflect the change
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, approved_status: newStatus } : row
        )
      );
    } catch (error) {
      toast.error('Failed to update approval status. Please try again.');
      console.error('Error updating approval status:', error);
    }
  };

  const filteredRows = useMemo(() => {
    const lowerSearchQuery = searchQuery.toLowerCase();
    return rows.filter((row) => {
      const matchesApproval = !approvalFilter || row.approved_status === approvalFilter;
      const matchesSearch =
        !searchQuery ||
        row.group_names?.toLowerCase().includes(lowerSearchQuery) ||
        row.description?.toLowerCase().includes(lowerSearchQuery) ||
        row.workbench?.toString().toLowerCase().includes(lowerSearchQuery) ||
        new Date(row.booking_date).toLocaleDateString().toLowerCase().includes(lowerSearchQuery) ||
        row.start_time?.toLowerCase().includes(lowerSearchQuery) ||
        row.end_time?.toLowerCase().includes(lowerSearchQuery) ||
        approvalChoices.find((choice) => choice.value === row.approved_status)?.label
          .toLowerCase()
          .includes(lowerSearchQuery);

      return matchesApproval && matchesSearch;
    });
  }, [rows, approvalFilter, searchQuery]);

  const columns = useMemo(
    () => [
      {
        field: 'workbench',
        headerName: 'Workbench',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'header-style',
        renderCell: (params) => (
          <Typography>{params.row.workbench}</Typography>
        ),
      },
      {
        field: 'booking_date',
        headerName: 'Booking Date',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'header-style',
        renderCell: (params) => (
          <Typography>{new Date(params.row.booking_date).toLocaleDateString()}</Typography>
        ),
      },
      {
        field: 'start_time',
        headerName: 'Start Time',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'header-style',
        renderCell: (params) => (
          <Typography>{params.row.start_time.slice(0, 5)}</Typography> // Display in HH:MM format
        ),
      },
      {
        field: 'end_time',
        headerName: 'End Time',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'header-style',
        renderCell: (params) => (
          <Typography>{params.row.end_time.slice(0, 5)}</Typography> // Display in HH:MM format
        ),
      },
      {
        field: 'group_names',
        headerName: 'Group Names',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'header-style',
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'header-style',
      },
      {
        field: 'approved_status',
        headerName: 'Approval Status',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'header-style',
        renderCell: (params) => (
          <Select
            value={params.row.approved_status}
            onChange={(event) => handleApprovalChange(params.row.id, event.target.value)}
            sx={{ width: '120px', height: '75%' }}
          >
            {approvalChoices.map((choice) => (
              <MenuItem key={choice.value} value={choice.value}>
                {choice.label}
              </MenuItem>
            ))}
          </Select>
        ),
      },
    ],
    [handleApprovalChange]
  );

  return (
    <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
      <Box
        component="form"
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
          width: '100%',
          marginBlock: '1rem',
          boxSizing: 'border-box',
        }}
      >
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Approval Status</InputLabel>
          <Select
            value={approvalFilter}
            onChange={(event) => setApprovalFilter(event.target.value)}
            label="Approval Status"
          >
            {approvalChoices.map((choice) => (
              <MenuItem key={choice.value} value={choice.value}>
                {choice.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <InputBase
          sx={{
            maxWidth: 200,
            ml: 2,
            mr: 1,
            flex: 1,
            height: '3rem',
            border: '#B5B5B5 solid 0.5px',
            borderRadius: '10px',
            bgcolor: '#F4F4F4',
            '& .MuiInputBase-input': {
              paddingLeft: '.1rem',
            },
          }}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <IconButton sx={{ paddingLeft: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress
            size={60}
            thickness={4.5}
            sx={{ color: '#273F77' }} // Customize color to match your theme
          />
          <Typography
            variant="h6"
            sx={{
              color: '#273F77',
              fontFamily: 'Inter',
              fontWeight: 500,
              marginTop: 2,
            }}
          >
            Loading...
          </Typography>
        </Box>
      ) : filteredRows.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            marginTop: '100px',
            padding: '32px 24px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '21px',
          }}
        >
          <Box component="img" src={nodatafound} alt="No Data" />
          <Typography
            sx={{
              color: '#273F77',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: 26,
              fontWeight: 500,
              lineHeight: '150%',
              letterSpacing: '-0.352px',
            }}
          >
            No Records Found!
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={7}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[7]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          sx={{
            '& .header-style': {
              fontSize: '1.2rem',
            },
          }}
        />
      )}
    </Box>
  );
};

export default WorkbenchesBookingTable;
