import { axiosApiService } from "../axios";

const AdminInventoryIssueAPIs = {
    InventoryIssueListGet: async function () {
        try {
            const response = await axiosApiService.get(`/inventory_issue_list/`);
            const data = response.data;
            return data;
        } catch (error) {
            console.error('Error fetching data:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    InventoryIssueDetailGet: async function (id) {
        try {
            const response = await axiosApiService.get(`/inventory_issue_detail/${id}/`);
            const data = response.data;
            return data;
        } catch (error) {
            console.error(`Error fetching detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    InventoryIssueDetailPatch: async function (id, updateData) {
        try {
            const response = await axiosApiService.patch(`/inventory_issue_detail/${id}/`, updateData);
            const data = response.data;
            return data;
        } catch (error) {
            console.error(`Error updating detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    InventoryIssueDetailDelete: async function (id) {
        try {
            const response = await axiosApiService.delete(`/inventory_issue_detail/${id}/`);
            const data = response.data;
            return data;
        } catch (error) {
            console.error(`Error deleting detail for ID ${id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    }
};

export default AdminInventoryIssueAPIs;
