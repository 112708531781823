import { axiosApiService } from "../axios";

const WorkbenchBookingAPIs = {
    // Method to fetch a list of workbench bookings
    async getList() {
        try {
            const response = await axiosApiService.get('workbench_bookings/');
            console.log('WorkbenchAPIs getList response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching the workbench bookings list:', error);
            throw error;
        }
    },

    // Method to fetch details of a specific workbench booking by ID
    async getDetail(id) {
        try {
            const response = await axiosApiService.get(`workbench_bookings/${id}/`);
            console.log(`WorkbenchAPIs getDetail response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error fetching the workbench booking detail for ID ${id}:`, error);
            throw error;
        }
    },

    // Method to create a new workbench booking
    async createBooking(data) {
        try {
            const response = await axiosApiService.post('workbench_bookings/', data);
            console.log('WorkbenchAPIs createBooking response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error creating a new workbench booking:', error);
            throw error;
        }
    },

    // Method to update an existing workbench booking by ID
    async updateBooking(id, data) {
        try {
            const response = await axiosApiService.patch(`workbench_bookings/${id}/`, data);
            console.log(`WorkbenchAPIs updateBooking response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error updating the workbench booking with ID ${id}:`, error);
            throw error;
        }
    },

    // Method to delete a specific workbench booking by ID
    async deleteBooking(id) {
        try {
            const response = await axiosApiService.delete(`workbench_bookings/${id}/`);
            console.log(`WorkbenchAPIs deleteBooking response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error deleting the workbench booking with ID ${id}:`, error);
            throw error;
        }
    }
};

export default WorkbenchBookingAPIs;
