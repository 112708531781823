import { Box } from '@mui/material';
import React, { useState } from 'react';
import NavigationBar from './UserManagement/NavigationBar';
import Request from './UserManagement/Request';
import Permissions from '../Settings/UserManagement/Permissions';
import User from './UserManagement/User';
import Groups from './UserManagement/Groups';

const ManagementContainer = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState(''); 

  const renderPage = () => {
    switch (activeTab) {
      case 0:
        return <Request searchQuery={searchQuery} />; 
      case 1:
        return <Permissions />;
      case 2:
        return <User searchQuery={searchQuery} />; 
      case 3:
        return <Groups />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: 'border-box',
        height: 'auto',
        marginBlock: '1rem',
        borderRadius: 1,
        border: '#A6A6A6 solid 1px',
      }}
    >
      <NavigationBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        searchQuery={searchQuery} 
        setSearchQuery={setSearchQuery} 
      />
      {renderPage()}
    </Box>
  );
};

export default ManagementContainer;
