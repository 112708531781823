import React from 'react';
import { Card, CardContent, Box, Typography, Button, Avatar, AvatarGroup, Divider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {ImageBaseURL} from "../../utilities/api/axios";

const statusColors = {
  1: { background: '#FFE5E5', color: '#C90505' }, // Archived
  2: { background: '#FEFFD2', color: '#D0A300' }, // In Progress
  3: { background: '#E5FFF1', color: '#057D35' }, // Completed
};

const ProjectCard = ({ project, onView, getUserDetails, allUser }) => {
  const status = project.progress_status;
  const statusColor = statusColors[status];

  // Function to get user details by ID
  const getUserById = (userId) => allUser.find(user => user.id === userId);

  // Get creator and teammates' details
  const creator = getUserById(project.student);
  const teammates = project.teammates.map(teammateId => getUserById(teammateId)).filter(Boolean);

  const getProfilePicUrl = (profilePicPath) => {
    return profilePicPath ? `${ImageBaseURL}${profilePicPath}` : '';
  };

  return (
    <Card sx={{ width: "auto", margin: 1, height: '240px', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, backgroundColor: statusColor.background, borderRadius: '50px', width: 'fit-content', padding: '0 8px' }}>
            <Box sx={{ height: 5, width: 5, borderRadius: '50%', backgroundColor: statusColor.color, marginRight: 0.5, marginLeft: 1 }} />
            <Typography variant="body2" style={{ color: statusColor.color, marginLeft: 1 }}>
              {status === '1' ? 'Archived' : status === '2' ? 'In Progress' : 'Completed'}
            </Typography>
          </Box>
          <Button onClick={() => onView(project)} sx={{ mr: -2, padding: '0px' }}>
            <VisibilityIcon style={{ color: 'grey', padding: '0px' }} />
          </Button>
        </Box>
        <Typography style={{ fontWeight: 600, fontSize: '30px', color: '#007BFF' }}>{project.title}</Typography>
        <Typography style={{ fontWeight: 500, fontSize: '20px' }}>Description</Typography>
        <Typography variant="body2" color="textSecondary" sx={{ fontSize: '20px', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, textOverflow: 'ellipsis' }}>{project.description}</Typography>
        <Typography variant="body2" sx={{ fontSize: '20px' }}>
          {'Type:  '}
          <Typography variant="body2" component="span" color="textSecondary" sx={{ fontSize: '20px' }}>
            {project.type === '1' ? 'Academic' : project.type === '2' ? 'Research' : project.type === '3' ? 'Personal' : 'Other'}
          </Typography>
        </Typography>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
        <Typography variant="body2" style={{ color: '#5C5C5C' }}>{project.date}</Typography>
        <AvatarGroup max={15} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12 } }}>
          {teammates.map((member, index) => (
            <Avatar
            key={index}
            src={getProfilePicUrl(member.profile_pic) || ''}
            alt={member.name}
            title={member.name} // Show username on hover
            />
          ))}
          {creator && (
            <Avatar
              src={getProfilePicUrl(creator.profile_pic) || ''}
              alt={creator.name}
              title={creator.name} // Show username on hover
              style={{
                border: '2px solid #007BFF' // Add blue border for creator's avatar
              }}
            />
          )}
        </AvatarGroup>
      </Box>
    </Card>
  );
};

export default ProjectCard;
