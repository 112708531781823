import { axiosApiService } from "../axios";

const AdminModuleAPIs = {
    ModuleListGet: async function() {
        try {
            const response = await axiosApiService.get(`training/tutorials/modules/`);
            console.log('Module List:', response);
            return response.data;
        } catch (error) {
            console.error('Error Module List:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    ModuleDetailGet: async function(module_id) {
        try {
            const response = await axiosApiService.get(`training/tutorials/modules/${module_id}/`);
            console.log(`Module ${module_id} Response:`, response);
            return response.data;
        } catch (error) {
            console.error(`Error getting module ${module_id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    CreateModulePost: async function(moduleData, token) {
        try {
            console.log('Creating Module:', moduleData);
            const response = await axiosApiService.post(`/training/tutorials/modules/`, moduleData, {});
            console.log('Module Create Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error creating module:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    UpdateModule: async function(module_id, moduleData) {
        try {
            console.log('Updating Module:', module_id, moduleData);
            // const response = await axiosApiService.put(`training/tutorials/modules/${module_id}/`, moduleData);
            const response = await axiosApiService.patch(`training/tutorials/modules/${module_id}/`, moduleData);
            console.log('Module Update Response:', response);
            return response.data;
        } catch (error) {
            console.error(`Error updating module ${module_id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    }
};

export default AdminModuleAPIs;



// import { axiosApiService } from "../axios";

// const AdminModuleAPIs = {
//     ModuleListGet: async function() {
//         try {
//             const response = await axiosApiService.get(`training/tutorials/modules/`);
//             console.log('Module List:', response);
//             return response.data;
//         } catch (error) {
//             console.error('Error Module List:', error.response ? error.response.data : error.message);
//             throw error;
//         }
//     },

//     ModuleDetailGet: async function(module_id) {
//         try {
//             const response = await axiosApiService.get(`training/tutorials/modules/${module_id}/`);
//             console.log(`Module ${module_id} Response:`, response);
//             return response.data;
//         } catch (error) {
//             console.error(`Error getting module ${module_id}:`, error.response ? error.response.data : error.message);
//             throw error;
//         }
//     },

//     CreateModulePost: async function(moduleData, token) {
//         // if (!token) {
//         //     throw new Error('Access token is missing.');
//         // }
//         try {
//             console.log('Creating Module:', moduleData);
//             const response = await axiosApiService.post(`/training/tutorials/modules/`, moduleData, {
//                 // headers: {
//                 //     Authorization: `Bearer ${token}`
//                 // }
//             });
//             console.log('Module Create Response:', response);
//             return response.data;
//         } catch (error) {
//             console.error('Error creating module:', error.response ? error.response.data : error.message);
//             throw error;
//         }
//     },
// };

// export default AdminModuleAPIs;
