import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const MostIssuedItemsChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const itemsChartRef = useRef(null);
  const [itemData, setItemData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [equipmentNames, setEquipmentNames] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const itemUsage = {};
        const equipmentSet = new Set();

        const fetchItemData = async (TL_name) => {
          const response = await axiosApiService.get('/inventory_issue_list/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        // Fetch and process data for each TL
        for (const [id, name] of Object.entries(tlMappings)) {
          const data = await fetchItemData(name);
          data.forEach(item => {
            if (!itemUsage[name]) {
              itemUsage[name] = {};
            }
            if (!itemUsage[name][item.name]) {
              itemUsage[name][item.name] = 0;
            }
            itemUsage[name][item.name] += item.quantity;
            equipmentSet.add(item.name);
          });
        }

        // Prepare labels and datasets
        const labels = Object.values(tlMappings);
        const equipmentNames = Array.from(equipmentSet);

        // Select only top 5 items for each TL
        const top5ItemData = equipmentNames.map(equipment => {
          return {
            label: equipment,
            data: labels.map(label => {
              // Get the items for each TL and sort them by quantity
              const sortedItems = Object.entries(itemUsage[label] || {})
                .sort(([, a], [, b]) => b - a) // Sort by quantity (descending)
                .slice(0, 5); // Keep only top 5 items
              
              // Find the quantity of the current equipment in the top 5 items
              const item = sortedItems.find(([itemName]) => itemName === equipment);
              return item ? item[1] : 0; // Return the quantity or 0 if the item is not in the top 5
            }),
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
          };
        });

        setLabels(labels);
        setItemData(top5ItemData);
        setEquipmentNames(equipmentNames);
      } catch (error) {
        console.error('Error fetching issued items data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  useEffect(() => {
    if (itemsChartRef.current) itemsChartRef.current.destroy();

    const hasData = itemData.length > 0 && itemData.some(dataset => dataset.data.some(value => value > 0));

    if (hasData) {
      const maxIssuedItems = Math.ceil(Math.max(...itemData.flatMap(dataset => dataset.data)) * 1.2);

      const itemsCtx = chartRef.current.getContext('2d');
      itemsChartRef.current = new Chart(itemsCtx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: itemData,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: { stacked: true },
            y: { stacked: true, beginAtZero: true, max: maxIssuedItems, ticks: { stepSize: 1 } },
          },
        },
      });
    }

    return () => {
      if (itemsChartRef.current) itemsChartRef.current.destroy();
    };
  }, [labels, itemData]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: '1px', borderStyle: 'solid', padding: '16px', width: '90%', height: '336px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardContent>
        <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '700', color: '#212121', textAlign: 'center', letterSpacing: 0 }}>Most Issued Items</Typography>
        <Typography sx={{ fontSize: '12px', color: '#7a7a7a', marginTop: '12px', fontWeight: '500', textAlign: 'center', letterSpacing: 0 }}>Top 5 most issued items from each TL</Typography>
        {itemData.length > 0 && itemData.some(dataset => dataset.data.some(value => value > 0)) ? (
          <Box sx={{ height: '250px', width: '100%' }}>
            <canvas ref={chartRef} style={{ width: '100%' }} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
            <Typography variant='h5' color="textSecondary">No data available</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default MostIssuedItemsChart;
