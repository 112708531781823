import { axiosApiService } from "../axios";

const AdminWorkshopAttendees = {
    AttendeeListGet: async function (id) {
        try {
            const response = await axiosApiService.get(`/training/workshops/${id}/attendees/`);
            console.log('Attendee List:', response);
            return response.data;
        } catch (error) {
            console.error('Error fetching attendee list:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    AddAttendeePost: async function(values, token) {
        if (!token) {
            throw new Error('Access token is missing.');
        }
        try {
            console.log('Adding Attendee:', values);
            const response = await axiosApiService.post(`/training/workshops/${values.workshop}/attendees/`, values, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Add Attendee Response:', response);
            return response.data; // Assuming the server returns the updated booking data
        } catch (error) {
            console.error('Error adding attendee:', error.response ? error.response.data : error.message);
            throw error;
        }
    },
    
    UpdateAttendeePatch: async function(values, token) {
        if (!token) {
            throw new Error('Access token is missing.');
        }
        try {
            console.log('Updating Attendee:', values);
            const response = await axiosApiService.patch(`/training/workshops/${values.workshop}/attendees/modify_attendee/${values.user}/`, {
                is_present: values.is_present
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Update Attendee Response:', response);
            return response.data; // Assuming the server returns the updated attendee data
        } catch (error) {
            console.error('Error updating attendee:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    AttendeeUserDelete: async function(values, token) {
        if (!token) {
            throw new Error('Access token is missing.');
        }
        try {
            console.log('Deleting Attendee:', values);
            const response = await axiosApiService.delete(`/training/workshops/${values.workshop}/attendees/modify_attendee/${values.user}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Delete Attendee Response:', response);
            return response.data; // Assuming the server returns the updated attendee data
        } catch (error) {
            console.error('Error deleting attendee:', error.response ? error.response.data : error.message);
            throw error;
        }
    },
};

export default AdminWorkshopAttendees;