// PersonalInfo.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import AdminUserDetail from '../../utilities/api/user/AdminUserDetail';
import { updateUser } from '../../redux/slices/userSlice';

const PersonalInfo = ({ user,refreshUserData }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);

  const handleEdit = () => setEditMode(!editMode);

  const handleSave = async (values) => {
    const payload = {
      ...values,
      name: `${values.firstName} ${values.lastName}`,
    };
    try {
      await AdminUserDetail.AdminDetailUpdate(user.id, payload);
      const updatedUser = await AdminUserDetail.AdminDetailGet(user.id);
      dispatch(updateUser(updatedUser));
      await refreshUserData();
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string(),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phone_number: Yup.string().matches(/^[0-9]+$/, 'Must be only digits').min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits'),
    position: Yup.string().required('Position is required'),
    other_position: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: user.name?.split(' ')[0] || '',
      lastName: user.name?.split(' ').slice(1).join(' ') || '',
      email: user.email || '',
      phone_number: user.phone_number || '',
      position: user.position || '',
      other_position: user.other_position || '',
    },
    validationSchema,
    validate: (values) => {
      const errors = {};
      if (values.position === '4' && !values.other_position) {
        errors.other_position = 'Please specify the other position';
      }
      return errors;
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', margin: '1rem', width: '95%', border: '1px solid #D3D3D3', borderRadius: '10px', padding: '25px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <Typography variant="h4" sx={{ color: '#007BFF' }}>Personal Information</Typography>
          {!editMode && (
            <Box sx={{ position: 'absolute', right: '20px', cursor: 'pointer' }}>
              <Typography onClick={handleEdit} sx={{ display: 'flex', alignItems: 'center', padding: '4px 10px', color: 'rgb(122,122,122)', fontSize: '1.1rem', border: '1px solid rgb(122,122,122)', borderRadius: '20px' }}>Edit<EditIcon sx={{ fontSize: '1rem', color: 'rgb(122,122,122)', marginLeft: '5px' }} /></Typography>
            </Box>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>First Name</Typography>
            <TextField label="First Name" variant="outlined" name="firstName" value={formik.values.firstName} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth disabled={!editMode} error={formik.touched.firstName && Boolean(formik.errors.firstName)} helperText={formik.touched.firstName && formik.errors.firstName} />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Last Name</Typography>
            <TextField label="Last Name" variant="outlined" name="lastName" value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth disabled={!editMode} error={formik.touched.lastName && Boolean(formik.errors.lastName)} helperText={formik.touched.lastName && formik.errors.lastName} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Email</Typography>
            <TextField label="Email" variant="outlined" type="email" name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth disabled error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Phone Number</Typography>
            <TextField label="Phone Number" variant="outlined" type="tel" name="phone_number" value={formik.values.phone_number} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth disabled={!editMode} error={formik.touched.phone_number && Boolean(formik.errors.phone_number)} helperText={formik.touched.phone_number && formik.errors.phone_number} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Position</Typography>
            <FormControl variant="outlined" fullWidth disabled={!editMode} error={formik.touched.position && Boolean(formik.errors.position)}>
              <InputLabel>Position</InputLabel>
              <Select label="Position" name="position" value={formik.values.position} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                {[{ value: '0', label: 'TL student management body' }, { value: '1', label: 'Manager (other technical clubs)' }, { value: '2', label: 'TL Manager' }, { value: '3', label: 'TL Technician' }, { value: '4', label: 'Other' }].map((choice) => (
                  <MenuItem key={choice.value} value={choice.value}>{choice.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formik.values.position === '4' && (
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '3px', fontSize: '1.4rem', color: 'rgb(122, 122, 122)' }}>Other Position</Typography>
              <TextField label="Other Position" variant="outlined" name="other_position" value={formik.values.other_position} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth disabled={!editMode} error={formik.touched.other_position && Boolean(formik.errors.other_position)} helperText={formik.touched.other_position && formik.errors.other_position} />
            </Grid>
          )}
        </Grid>
        {editMode && (
          <Box sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="contained" color="primary" sx={{ display: 'flex', alignItems: 'center', padding: '4px 10px' }}>Save</Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default PersonalInfo;
