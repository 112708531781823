import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { setIsNewTut } from '../../../../redux/slices/tutorialEditSlice';
import AddVideo from './AddVideo';
import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs';

const AddModuleForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const [videoComponents, setVideoComponents] = useState([{ id: 0 }]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoIds, setVideoIds] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [machines, setMachines] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required('Module Name is required'),
    description: Yup.string().required('Module Description is required'),
    related_machines: Yup.array().required('Related Machines is required'),
    related_equipments: Yup.array().required('Related Equipments is required'),
    related_inventory: Yup.array().required('Related Inventory is required'),
    image: Yup.mixed().nullable(),  // Optional image field
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      related_machines: [],
      related_equipments: [],
      related_inventory: [],
      image: null,  // Initial value for image
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const submittedValues = {
        name: values.name,
        description: values.description,
        related_machines: values.related_machines.map(machine => machine.id),
        related_equipments: values.related_equipments.map(equipment => equipment.id),
        related_inventory: values.related_inventory.map(inventory => inventory.id),
        image: thumbnail,
        videos: videoIds.map((id, index) => ({ video: id, order: index + 1 })),
      };

      console.log('Submitted Values:', submittedValues);

      const formData = new FormData();
      formData.append('name', submittedValues.name);
      formData.append('description', submittedValues.description);
      if (submittedValues.image) {
        formData.append('image', submittedValues.image);
      }

      submittedValues.related_machines.forEach((machine) => {
        formData.append('related_machines[]', machine);
      });

      submittedValues.related_equipments.forEach((equipment) => {
        formData.append('related_equipments[]', equipment);
      });

      submittedValues.related_inventory.forEach((inventory) => {
        formData.append('related_inventory[]', inventory);
      });

      submittedValues.videos.forEach((video) => {
        formData.append('videos[]', JSON.stringify(video));
      });

      try {
        const response = await AdminModuleAPIs.CreateModulePost(formData);
        console.log('Module created successfully:', response);
        dispatch(setIsNewTut(false));
        resetForm();
      } catch (error) {
        console.error('Error creating module:', error);
      }
    },
  });

  useEffect(() => {
    const { name, description } = formik.values;
    const formFilled = name && description;
    setIsFormFilled(formFilled);
  }, [formik.values]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'All data will be lost';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const machines = await MachineListAPIs.MachineListGet();
        setMachines(machines || []);
      } catch (error) {
        console.error('Failed to fetch machines', error);
      }
    };

    const fetchEquipments = async () => {
      try {
        const equipments = await EquipmentListAPIs.EquipmentListGet();
        setEquipments(equipments || []);
      } catch (error) {
        console.error('Failed to fetch equipments', error);
      }
    };

    const fetchInventories = async () => {
      try {
        const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
        setInventories(inventories || []);
      } catch (error) {
        console.error('Failed to fetch inventories', error);
      }
    };

    fetchMachines();
    fetchEquipments();
    fetchInventories();
  }, [user.refresh, user.access]);

  const handleCancel = () => {
    const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
    if (userConfirmed) {
      dispatch(setIsNewTut(false));
    }
  };

  const addVideoComponent = () => {
    setVideoComponents([...videoComponents, { id: videoComponents.length }]);
  };

  const removeVideoComponent = (id) => {
    setVideoComponents(videoComponents.filter((video) => video.id !== id));
  };

  const handleUploadSuccess = (videoId, videoUrl) => {
    setVideoIds([...videoIds, videoId]);
    setVideoUrls([...videoUrls, videoUrl]);
  };

  const handleUploading = (isUploading) => {
    setUploading(isUploading);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setThumbnail(file);
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      encType="multipart/form-data"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '95%',
        margin: '0 auto',
        padding: 2,
        position: 'relative',
      }}
    >
      <Typography variant="h5" sx={{ mb: 1 }}>
        Add Module
      </Typography>

      <Box>
        <Box>
          {videoComponents.map((video) => (
            <AddVideo
              key={video.id}
              onRemove={() => removeVideoComponent(video.id)}
              onUploadSuccess={handleUploadSuccess}
              isFormFilled={isFormFilled}
              handleUploading={handleUploading}
            />
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="outlined" color="primary" onClick={addVideoComponent}>
            <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
            Add Video
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>Module Name</Typography>
        <TextField
          label="Module Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <Typography>Module Description</Typography>
        <TextField
          label="Module Description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          multiline
          rows={4}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
        <Typography>Module Thumbnail</Typography>
        <input
          accept="image/*"
          id="thumbnail"
          type="file"
          onChange={handleImageChange}
        />
        {formik.errors.image && <Typography color="error">{formik.errors.image}</Typography>}
        {machines.length > 0 && (
          <>
            <Typography>Related Machines</Typography>
            <Autocomplete
              multiple
              options={machines}
              getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
              value={formik.values.related_machines}
              onChange={(event, value) => formik.setFieldValue('related_machines', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Machines"
                  name="related_machines"
                  error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
                  helperText={formik.touched.related_machines && formik.errors.related_machines}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
        {equipments.length > 0 && (
          <>
            <Typography>Related Equipments</Typography>
            <Autocomplete
              multiple
              options={equipments}
              getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
              value={formik.values.related_equipments}
              onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Equipments"
                  name="related_equipments"
                  error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
                  helperText={formik.touched.related_equipments && formik.errors.related_equipments}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
        {inventories.length > 0 && (
          <>
            <Typography>Related Inventory</Typography>
            <Autocomplete
              multiple
              options={inventories}
              getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
              value={formik.values.related_inventory}
              onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Inventory"
                  name="related_inventory"
                  error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
                  helperText={formik.touched.related_inventory && formik.errors.related_inventory}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={uploading}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddModuleForm;


// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
// import { useFormik } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import * as Yup from 'yup';
// import AddIcon from '@mui/icons-material/Add';
// import { setIsNewTut } from '../../../../redux/slices/tutorialEditSlice';
// import AddVideo from './AddVideo';
// import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
// import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
// import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
// import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs';

// const AddModuleForm = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user.user);

//   const [videoComponents, setVideoComponents] = useState([{ id: 0 }]);
//   const [videoUrls, setVideoUrls] = useState([]);
//   const [videoIds, setVideoIds] = useState([]);
//   const [isFormFilled, setIsFormFilled] = useState(false);
//   const [uploading, setUploading] = useState(false);

//   const [machines, setMachines] = useState([]);
//   const [equipments, setEquipments] = useState([]);
//   const [inventories, setInventories] = useState([]);
//   const [thumbnail, setThumbnail] = useState(null);

//   const validationSchema = Yup.object({
//     name: Yup.string().required('Module Name is required'),
//     description: Yup.string().required('Module Description is required'),
//     related_machines: Yup.array().required('Related Machines is required'),
//     related_equipments: Yup.array().required('Related Equipments is required'),
//     related_inventory: Yup.array().required('Related Inventory is required'),
//     image: Yup.mixed().nullable(),  // Optional image field
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: '',
//       description: '',
//       related_machines: [],
//       related_equipments: [],
//       related_inventory: [],
//       image: null,  // Initial value for image
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values, { resetForm }) => {
//       const formData = new FormData();
//       formData.append('name', values.name);
//       formData.append('description', values.description);
//       formData.append('image', thumbnail);

//       values.related_machines.forEach((machine, index) => {
//         formData.append(`related_machines[${index}]`, machine.id);
//       });

//       values.related_equipments.forEach((equipment, index) => {
//         formData.append(`related_equipments[${index}]`, equipment.id);
//       });

//       values.related_inventory.forEach((inventory, index) => {
//         formData.append(`related_inventory[${index}]`, inventory.id);
//       });

//       videoIds.forEach((id, index) => {
//         formData.append(`videos[${index}][video]`, id);
//         formData.append(`videos[${index}][order]`, index + 1);
//       });

//       try {
//         const response = await AdminModuleAPIs.CreateModulePost(formData);
//         console.log('Module created successfully:', response);
//         dispatch(setIsNewTut(false));
//         resetForm();
//       } catch (error) {
//         console.error('Error creating module:', error);
//       }
//     },
//   });

//   useEffect(() => {
//     const { name, description } = formik.values;
//     const formFilled = name && description;
//     setIsFormFilled(formFilled);
//   }, [formik.values]);

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       event.preventDefault();
//       event.returnValue = 'All data will be lost';
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, []);

//   useEffect(() => {
//     const fetchMachines = async () => {
//       try {
//         const machines = await MachineListAPIs.MachineListGet();
//         setMachines(machines || []);
//       } catch (error) {
//         console.error('Failed to fetch machines', error);
//       }
//     };

//     const fetchEquipments = async () => {
//       try {
//         const equipments = await EquipmentListAPIs.EquipmentListGet();
//         setEquipments(equipments || []);
//       } catch (error) {
//         console.error('Failed to fetch equipments', error);
//       }
//     };

//     const fetchInventories = async () => {
//       try {
//         const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
//         setInventories(inventories || []);
//       } catch (error) {
//         console.error('Failed to fetch inventories', error);
//       }
//     };

//     fetchMachines();
//     fetchEquipments();
//     fetchInventories();
//   }, [user.refresh, user.access]);

//   const handleCancel = () => {
//     const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
//     if (userConfirmed) {
//       dispatch(setIsNewTut(false));
//     }
//   };

//   const addVideoComponent = () => {
//     setVideoComponents([...videoComponents, { id: videoComponents.length }]);
//   };

//   const removeVideoComponent = (id) => {
//     setVideoComponents(videoComponents.filter((video) => video.id !== id));
//   };

//   const handleUploadSuccess = (videoId, videoUrl) => {
//     setVideoIds([...videoIds, videoId]);
//     setVideoUrls([...videoUrls, videoUrl]);
//   };

//   const handleUploading = (isUploading) => {
//     setUploading(isUploading);
//   };

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     setThumbnail(file);
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={formik.handleSubmit}
//       enctype="multipart/form-data"
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         width: '95%',
//         margin: '0 auto',
//         padding: 2,
//         position: 'relative',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Add Module
//       </Typography>

//       <Box>
//         <Box>
//           {videoComponents.map((video) => (
//             <AddVideo
//               key={video.id}
//               onRemove={() => removeVideoComponent(video.id)}
//               onUploadSuccess={handleUploadSuccess}
//               isFormFilled={isFormFilled}
//               handleUploading={handleUploading}
//             />
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button variant="outlined" color="primary" onClick={addVideoComponent}>
//             <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
//             Add Video
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <Typography>Module Name</Typography>
//         <TextField
//           label="Module Name"
//           name="name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.name && Boolean(formik.errors.name)}
//           helperText={formik.touched.name && formik.errors.name}
//         />
//         <Typography>Module Description</Typography>
//         <TextField
//           label="Module Description"
//           name="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           multiline
//           rows={4}
//           error={formik.touched.description && Boolean(formik.errors.description)}
//           helperText={formik.touched.description && formik.errors.description}
//         />
//         <Typography>Module Thumbnail</Typography>
//         <input
//           accept="image/*"
//           id="thumbnail"
//           type="file"
//           onChange={handleImageChange}
//         />
//         {formik.errors.image && <Typography color="error">{formik.errors.image}</Typography>}
//         {machines.length > 0 && (
//           <>
//             <Typography>Related Machines</Typography>
//             <Autocomplete
//               multiple
//               options={machines}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_machines}
//               onChange={(event, value) => formik.setFieldValue('related_machines', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Machines"
//                   name="related_machines"
//                   error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
//                   helperText={formik.touched.related_machines && formik.errors.related_machines}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {equipments.length > 0 && (
//           <>
//             <Typography>Related Equipments</Typography>
//             <Autocomplete
//               multiple
//               options={equipments}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_equipments}
//               onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Equipments"
//                   name="related_equipments"
//                   error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
//                   helperText={formik.touched.related_equipments && formik.errors.related_equipments}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {inventories.length > 0 && (
//           <>
//             <Typography>Related Inventory</Typography>
//             <Autocomplete
//               multiple
//               options={inventories}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_inventory}
//               onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Inventory"
//                   name="related_inventory"
//                   error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
//                   helperText={formik.touched.related_inventory && formik.errors.related_inventory}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//       </Box>

//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
//         <Button variant="outlined" color="secondary" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button type="submit" variant="contained" color="primary" disabled={uploading}>
//           Submit
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default AddModuleForm;



// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
// import { useFormik } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import * as Yup from 'yup';
// import AddIcon from '@mui/icons-material/Add';
// import { setIsNewTut } from '../../../../redux/slices/tutorialEditSlice';
// import AddVideo from './AddVideo';
// import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
// import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
// import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
// import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs';

// const AddModuleForm = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user.user);

//   const [videoComponents, setVideoComponents] = useState([{ id: 0 }]);
//   const [videoUrls, setVideoUrls] = useState([]);
//   const [videoIds, setVideoIds] = useState([]);
//   const [isFormFilled, setIsFormFilled] = useState(false);
//   const [uploading, setUploading] = useState(false);

//   const [machines, setMachines] = useState([]);
//   const [equipments, setEquipments] = useState([]);
//   const [inventories, setInventories] = useState([]);

//   const validationSchema = Yup.object({
//     name: Yup.string().required('Module Name is required'),
//     description: Yup.string().required('Module Description is required'),
//     related_machines: Yup.array().required('Related Machines is required'),
//     related_equipments: Yup.array().required('Related Equipments is required'),
//     related_inventory: Yup.array().required('Related Inventory is required'),
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: '',
//       description: '',
//       related_machines: [],
//       related_equipments: [],
//       related_inventory: [],
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values, { resetForm }) => {
//       const formattedValues = {
//         ...values,
//         related_machines: values.related_machines.map((machine) => machine.id),
//         related_equipments: values.related_equipments.map((equipment) => equipment.id),
//         related_inventory: values.related_inventory.map((inventory) => inventory.id),
//         videos: videoIds.map((id, index) => ({ video: id, order: index + 1 }))
//       };

//       try {
//         const response = await AdminModuleAPIs.CreateModulePost(formattedValues);
//         console.log('Module created successfully:', response);
//         dispatch(setIsNewTut(false));
//         resetForm();
//       } catch (error) {
//         console.error('Error creating module:', error);
//       }
//     },
//   });

//   useEffect(() => {
//     const { name, description} = formik.values;
//     const formFilled = name && description;
//     setIsFormFilled(formFilled);
//   }, [formik.values]);

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       event.preventDefault();
//       event.returnValue = 'All data will be lost';
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, []);

//   useEffect(() => {
//     const fetchMachines = async () => {
//       try {
//         const machines = await MachineListAPIs.MachineListGet();
//         setMachines(machines || []);
//       } catch (error) {
//         console.error('Failed to fetch machines', error);
//       }
//     };

//     const fetchEquipments = async () => {
//       try {
//         const equipments = await EquipmentListAPIs.EquipmentListGet();
//         setEquipments(equipments || []);
//       } catch (error) {
//         console.error('Failed to fetch equipments', error);
//       }
//     };

//     const fetchInventories = async () => {
//       try {
//         const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
//         setInventories(inventories || []);
//       } catch (error) {
//         console.error('Failed to fetch inventories', error);
//       }
//     };

//     fetchMachines();
//     fetchEquipments();
//     fetchInventories();
//   }, [user.refresh, user.access]);

//   const handleCancel = () => {
//     const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
//     if (userConfirmed) {
//       dispatch(setIsNewTut(false));
//     }
//   };

//   const addVideoComponent = () => {
//     setVideoComponents([...videoComponents, { id: videoComponents.length }]);
//   };

//   const removeVideoComponent = (id) => {
//     setVideoComponents(videoComponents.filter((video) => video.id !== id));
//   };

//   const handleUploadSuccess = (videoId, videoUrl) => {
//     setVideoIds([...videoIds, videoId]);
//     setVideoUrls([...videoUrls, videoUrl]);
//   };

//   const handleUploading = (isUploading) => {
//     setUploading(isUploading);
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={formik.handleSubmit}
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         width: '95%',
//         margin: '0 auto',
//         padding: 2,
//         position: 'relative',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Add Module
//       </Typography>

//       <Box>
//         <Box>
//           {videoComponents.map((video) => (
//             <AddVideo
//               key={video.id}
//               onRemove={() => removeVideoComponent(video.id)}
//               onUploadSuccess={handleUploadSuccess}
//               isFormFilled={isFormFilled}
//               handleUploading={handleUploading}
//             />
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button variant="outlined" color="primary" onClick={addVideoComponent}>
//             <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
//             Add Video
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <Typography>Module Name</Typography>
//         <TextField
//           label="Module Name"
//           name="name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.name && Boolean(formik.errors.name)}
//           helperText={formik.touched.name && formik.errors.name}
//         />
//         <Typography>Module Description</Typography>
//         <TextField
//           label="Module Description"
//           name="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           multiline
//           rows={4}
//           error={formik.touched.description && Boolean(formik.errors.description)}
//           helperText={formik.touched.description && formik.errors.description}
//         />
//         {machines.length > 0 && (
//           <>
//             <Typography>Related Machines</Typography>
//             <Autocomplete
//               multiple
//               options={machines}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_machines}
//               onChange={(event, value) => formik.setFieldValue('related_machines', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Machines"
//                   name="related_machines"
//                   error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
//                   helperText={formik.touched.related_machines && formik.errors.related_machines}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {equipments.length > 0 && (
//           <>
//             <Typography>Related Equipments</Typography>
//             <Autocomplete
//               multiple
//               options={equipments}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_equipments}
//               onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Equipments"
//                   name="related_equipments"
//                   error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
//                   helperText={formik.touched.related_equipments && formik.errors.related_equipments}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {inventories.length > 0 && (
//           <>
//             <Typography>Related Inventory</Typography>
//             <Autocomplete
//               multiple
//               options={inventories}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_inventory}
//               onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Inventory"
//                   name="related_inventory"
//                   error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
//                   helperText={formik.touched.related_inventory && formik.errors.related_inventory}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//       </Box>

//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
//         <Button variant="outlined" color="secondary" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button type="submit" variant="contained" color="primary" disabled={uploading}>
//           Submit
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default AddModuleForm;





/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
// import { useFormik } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import * as Yup from 'yup';
// import AddIcon from '@mui/icons-material/Add';
// import { toggleTutEditMode } from '../../../../redux/slices/tutorialEditSlice';
// import AddVideo from './AddVideo';
// import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
// import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
// import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';

// const AddModuleForm = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user.user);

//   const [videoComponents, setVideoComponents] = useState([{ id: 0 }]);
//   const [videoUrls, setVideoUrls] = useState([]);
//   const [isFormFilled, setIsFormFilled] = useState(false);
//   const [uploading, setUploading] = useState(false);

//   const [machines, setMachines] = useState([]);
//   const [equipments, setEquipments] = useState([]);
//   const [inventories, setInventories] = useState([]);

//   const validationSchema = Yup.object({
//     name: Yup.string().required('Module Name is required'),
//     description: Yup.string().required('Module Description is required'),
//     related_machines: Yup.array().required('Related Machines is required'),
//     related_equipments: Yup.array().required('Related Equipments is required'),
//     related_inventory: Yup.array().required('Related Inventory is required'),
//     college: Yup.string().required('College name is required')
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: '',
//       description: '',
//       related_machines: [],
//       related_equipments: [],
//       related_inventory: [],
//       college: user.user.college,
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values, { resetForm }) => {
//       const formattedValues = {
//         ...values,
//         videos: videoUrls.map((url, index) => ({ video: url, order: index + 1 }))
//       };
//       console.log(formattedValues);
//       dispatch(toggleTutEditMode());
//       resetForm();
//     },
//   });

//   useEffect(() => {
//     // const { name, description, related_machines, related_equipments, related_inventory } = formik.values;
//     // const formFilled = name && description && related_machines.length && related_equipments.length && related_inventory.length;
    
//     // ONLY NAME AND DESCRIPTION ARE THE MANDATORY FIELDS??
//     const { name, description} = formik.values;
//     const formFilled = name && description;
//     setIsFormFilled(formFilled);
//   }, [formik.values]);

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       event.preventDefault();
//       event.returnValue = 'All data will be lost';
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, []);

//   useEffect(() => {
//     const fetchMachines = async () => {
//       try {
//         const machines = await MachineListAPIs.MachineListGet();
//         setMachines(machines || []);
//       } catch (error) {
//         console.error('Failed to fetch machines', error);
//       }
//     };

//     const fetchEquipments = async () => {
//       try {
//         const equipments = await EquipmentListAPIs.EquipmentListGet();
//         setEquipments(equipments || []);
//       } catch (error) {
//         console.error('Failed to fetch equipments', error);
//       }
//     };

//     const fetchInventories = async () => {
//       try {
//         const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
//         setInventories(inventories || []);
//       } catch (error) {
//         console.error('Failed to fetch inventories', error);
//       }
//     };

//     fetchMachines();
//     fetchEquipments();
//     fetchInventories();
//   }, [user.refresh, user.access]);

//   const handleCancel = () => {
//     const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
//     if (userConfirmed) {
//       dispatch(toggleTutEditMode());
//     }
//   };

//   const addVideoComponent = () => {
//     setVideoComponents([...videoComponents, { id: videoComponents.length }]);
//   };

//   const removeVideoComponent = (id) => {
//     setVideoComponents(videoComponents.filter((video) => video.id !== id));
//   };

//   const handleUploadSuccess = (videoUrl) => {
//     setVideoUrls([...videoUrls, videoUrl]);
//   };

//   const handleUploading = (isUploading) => {
//     setUploading(isUploading);
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={formik.handleSubmit}
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         width: '95%',
//         margin: '0 auto',
//         padding: 2,
//         position: 'relative',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Add Module
//       </Typography>

//       <Box>
//         <Box>
//           {videoComponents.map((video) => (
//             <AddVideo
//               key={video.id}
//               onRemove={() => removeVideoComponent(video.id)}
//               onUploadSuccess={handleUploadSuccess}
//               isFormFilled={isFormFilled}
//               handleUploading={handleUploading}
//             />
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button variant="outlined" color="primary" onClick={addVideoComponent}>
//             <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
//             Add Video
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <Typography>Module Name</Typography>
//         <TextField
//           label="Module Name"
//           name="name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.name && Boolean(formik.errors.name)}
//           helperText={formik.touched.name && formik.errors.name}
//         />
//         <Typography>Module Description</Typography>
//         <TextField
//           label="Module Description"
//           name="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           multiline
//           rows={4}
//           error={formik.touched.description && Boolean(formik.errors.description)}
//           helperText={formik.touched.description && formik.errors.description}
//         />
//         {machines.length > 0 && (
//           <>
//             <Typography>Related Machines</Typography>
//             <Autocomplete
//               multiple
//               options={machines}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_machines}
//               onChange={(event, value) => formik.setFieldValue('related_machines', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Machines"
//                   name="related_machines"
//                   error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
//                   helperText={formik.touched.related_machines && formik.errors.related_machines}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {equipments.length > 0 && (
//           <>
//             <Typography>Related Equipments</Typography>
//             <Autocomplete
//               multiple
//               options={equipments}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_equipments}
//               onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Equipments"
//                   name="related_equipments"
//                   error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
//                   helperText={formik.touched.related_equipments && formik.errors.related_equipments}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {inventories.length > 0 && (
//           <>
//             <Typography>Related Inventory</Typography>
//             <Autocomplete
//               multiple
//               options={inventories}
//               getOptionLabel={(option) => `${option.name} (ID: ${option.id})`}
//               value={formik.values.related_inventory}
//               onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Inventory"
//                   name="related_inventory"
//                   error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
//                   helperText={formik.touched.related_inventory && formik.errors.related_inventory}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//       </Box>

//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
//         <Button variant="outlined" color="secondary" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button type="submit" variant="contained" color="primary" disabled={uploading}>
//           Submit
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default AddModuleForm;




// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, Typography } from '@mui/material';
// import { useFormik } from 'formik';
// import { useDispatch } from 'react-redux';
// import * as Yup from 'yup';
// import AddIcon from '@mui/icons-material/Add';
// import { toggleTutEditMode } from '../../../../redux/slices/tutorialEditSlice';
// import AddVideo from './AddVideo';

// const AddModuleForm = () => {
//   const dispatch = useDispatch();
//   const [videoComponents, setVideoComponents] = useState([{ id: 0 }]);
//   const [videoUrls, setVideoUrls] = useState([]);
//   const [isFormFilled, setIsFormFilled] = useState(false);
//   const [uploading, setUploading] = useState(false);

//   const validationSchema = Yup.object({
//     name: Yup.string().required('Module Name is required').min(3, 'Module Name should be at least 3 characters'),
//     description: Yup.string().required('Module Description is required').min(10, 'Module Description should be at least 10 characters'),
//     product: Yup.string().required('Product is required').min(3, 'Product should be at least 3 characters'),
//     tag: Yup.string().required('Tag is required').min(2, 'Tag should be at least 2 characters'),
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: '',
//       description: '',
//       product: '',
//       tag: '',
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values, { resetForm }) => {
//       console.log({ ...values, videos: videoUrls });
//       dispatch(toggleTutEditMode());
//       resetForm();
//     },
//   });

//   useEffect(() => {
//     const { name, description, product, tag } = formik.values;
//     const formFilled = name && description && product && tag;
//     setIsFormFilled(formFilled);
//   }, [formik.values]);

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       event.preventDefault();
//       event.returnValue = 'All data will be lost';
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, []);

//   const handleCancel = () => {
//     const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
//     if (userConfirmed) {
//       dispatch(toggleTutEditMode());
//     }
//   };

//   const addVideoComponent = () => {
//     setVideoComponents([...videoComponents, { id: videoComponents.length }]);
//   };

//   const removeVideoComponent = (id) => {
//     setVideoComponents(videoComponents.filter((video) => video.id !== id));
//   };

//   const handleUploadSuccess = (videoUrl) => {
//     setVideoUrls([...videoUrls, videoUrl]);
//   };

//   const handleUploading = (isUploading) => {
//     setUploading(isUploading);
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={formik.handleSubmit}
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         width: '95%',
//         margin: '0 auto',
//         padding: 2,
//         position: 'relative',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Add Module
//       </Typography>

//       <Box>
//         <Box>
//           {videoComponents.map((video) => (
//             <AddVideo
//               key={video.id}
//               onRemove={() => removeVideoComponent(video.id)}
//               onUploadSuccess={handleUploadSuccess}
//               isFormFilled={isFormFilled}
//               handleUploading={handleUploading}
//             />
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button variant="outlined" color="primary" onClick={addVideoComponent}>
//             <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
//             Add Video
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <Typography>Module Name</Typography>
//         <TextField
//           label="Module Name"
//           name="name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.name && Boolean(formik.errors.name)}
//           helperText={formik.touched.name && formik.errors.name}
//         />
//         <Typography>Module Description</Typography>
//         <TextField
//           label="Module Description"
//           name="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           multiline
//           rows={4}
//           error={formik.touched.description && Boolean(formik.errors.description)}
//           helperText={formik.touched.description && formik.errors.description}
//         />
//         <Typography>Add Product</Typography>
//         <TextField
//           label="Add Product"
//           name="product"
//           value={formik.values.product}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.product && Boolean(formik.errors.product)}
//           helperText={formik.touched.product && formik.errors.product}
//           sx={{ padding: '0px' }}
//         />
//         <Typography>Tag</Typography>
//         <TextField
//           label="Tag"
//           name="tag"
//           value={formik.values.tag}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.tag && Boolean(formik.errors.tag)}
//           helperText={formik.touched.tag && formik.errors.tag}
//         />
//       </Box>

//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
//         <Button variant="outlined" color="secondary" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button type="submit" variant="contained" color="primary" disabled={uploading}>
//           Submit
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default AddModuleForm;