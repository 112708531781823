import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, CircularProgress, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineSharpIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import nodatafound from "../../utilities/dummy_assets/nodatafound.jpg";
import { ImageBaseURL } from "../../utilities/api/axios";
import InventoryListAPIs from "../../utilities/api/inventory/AdminInventoryList";
import InventoryDetailAPIs from "../../utilities/api/inventory/AdminInventoryDetail";
import Delete from "./Popups/Delete";
import EditInventoryDialog from "./Popups/EditInventory";
import AddStock from "./Popups/AddStock";
import RemoveStock from "./Popups/RemoveStock";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InventoryTable = ({
    searchQuery,
    category,
    group,
    tag,
    update,
    setUpdate,
    flag,
    setFlag,
}) => {
    const user = useSelector((state) => state.user.user);
    const token = user.access;

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [isEditInventoryOpen, setEditInventoryOpen] = useState(false);
    const [openAddStockPopup, setopenAddStockPopup] = useState(false);
    const [openRemoveStockPopup, setopenRemoveStockPopup] = useState(false);

    useEffect(() => {
        setLoading(true);
        const response = InventoryListAPIs.InventoryListGet(token);
        response
            .then((result) => {
                setTableData(result);
            })
            .catch((error) => {
                toast.error("Error fetching inventory data");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [update, user]);

    const rows = tableData.map((item, index) => ({ ...item, index }));

    const handleEditInventory = (row) => {
        setCurrentRow(row);
        setEditInventoryOpen(true);
    };

    const handleOpenDeletePopup = (row) => {
        setCurrentRow(row);
        setOpenDeletePopup(true);
    };

    const handleOpenAddStockPopup = (row) => {
        setCurrentRow(row);
        setopenAddStockPopup(true);
    };

    const handleOpenRemoveStockPopup = (row) => {
        setCurrentRow(row);
        setopenRemoveStockPopup(true);
    };

    // The missing functions that need to be defined
    const handleCloseAddStockPopup = () => {
        setopenAddStockPopup(false);
    };

    const handleCloseRemoveStockPopup = () => {
        setopenRemoveStockPopup(false);
    };

    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
    };

    const handleDelete = () => {
        const response = InventoryDetailAPIs.InventoryDetailDelete(
            currentRow.id,
            token
        );
        response
            .then(() => {
                setUpdate((prev) => !prev);
                toast.success("Item successfully deleted");
            })
            .catch((error) => {
                toast.error("Error deleting item");
            });
        setOpenDeletePopup(false);
    };

    const filteredRows = rows.filter((row) => {
        const lowercasedSearchQuery = searchQuery.toLowerCase();
        return row.name && row.name.toLowerCase().includes(lowercasedSearchQuery);
    });

    const columns = [
        {
            field: "itemDetails",
            headerName: "Item Details",
            flex: 1,
            headerAlign: "center",
            headerClassName: "header-style",
            renderCell: (params) => {
                const imageUrl =
                    params.row && params.row.image
                        ? `${ImageBaseURL}${params.row.image}`
                        : nodatafound;
                const text =
                    params.row && params.row.name ? params.row.name : "No Text";
                const subtext =
                    params.row && params.row.upc ? params.row.upc : "No Subtext";
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={imageUrl}
                            alt={params.row.name}
                            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="subtitle2" sx={{ color: "#434343" }}>
                                {text}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "blue" }}>
                                {subtext}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: "group",
            headerName: "Group",
            flex: 1,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-style",
            renderCell: (params) => (
                <Typography
                    sx={{ color: "#434343", textAlign: "center", width: "100%" }}
                >
                    {params.row.group}
                </Typography>
            ),
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-style",
            renderCell: (params) => (
                <Typography
                    sx={{ color: "#434343", textAlign: "center", width: "100%" }}
                >
                    {params.row.category}
                </Typography>
            ),
        },
        {
            field: "stock_available",
            headerName: "Stock",
            flex: 1,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-style",
            renderCell: (params) => (
                <Typography
                    sx={{ color: "#434343", textAlign: "center", width: "100%" }}
                >
                    {params.row.stock_available}{" "}
                    {params.row.stock_unit !== "number" ? params.row.stock_unit : ""}
                </Typography>
            ),
        },
        {
            field: "editstock",
            headerName: "Edit stock",
            sortable: false,
            flex: 1,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-style",
            renderCell: (params) => (
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <IconButton
                        aria-label="add"
                        onClick={() => handleOpenAddStockPopup(params.row)}
                    >
                        <AddCircleOutlineOutlinedIcon sx={{ color: "green" }} />
                    </IconButton>
                    <IconButton
                        aria-label="remove"
                        onClick={() => handleOpenRemoveStockPopup(params.row)}
                    >
                        <RemoveCircleOutlineSharpIcon sx={{ color: "#C90505" }} />
                    </IconButton>
                </Box>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            flex: 1,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-style",
            renderCell: (params) => (
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <IconButton
                        aria-label="edit"
                        onClick={() => handleEditInventory(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleOpenDeletePopup(params.row)}
                    >
                        <DeleteOutlineSharpIcon sx={{ color: "#C90505" }} />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box
            sx={{
                height: "480px",
                width: "100%",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                padding: "16px",
                boxSizing: "border-box",
            }}
        >
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        marginTop: "100px",
                        padding: "32px 24px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "21px",
                    }}
                >
                    <CircularProgress />
                    <Typography
                        sx={{
                            color: "#273F77",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: 26,
                            fontWeight: 500,
                            lineHeight: "150%",
                            letterSpacing: "-0.352px",
                        }}
                    >
                        Loading...
                    </Typography>
                </Box>
            ) : filteredRows.length > 0 ? (
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={8}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    rowsPerPageOptions={[5]}
                    pagination
                    // disableSelectionOnClick
                    // disableColumnFilter
                    // disableColumnMenu
                    disableDensitySelector
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#f5f5f5",
                            color: "#3f3f3f",
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                        },
                        "& .MuiDataGrid-cell": {
                            textAlign: "center",
                            color: "#3f3f3f",
                        },
                        "& .MuiDataGrid-footerContainer": {
                            justifyContent: "center",
                            alignItems: "center",
                            "& .MuiTablePagination-root": {
                                textAlign: "center",
                                "& .MuiButtonBase-root": {
                                    color: "#3f3f3f",
                                },
                            },
                        },
                        "& .MuiPaginationItem-root": {
                            color: "#3f3f3f",
                        },
                    }}
                />
            ) : (
                <Box sx={{ textAlign: "center", padding: 4 }}>
                    <img
                        src={nodatafound}
                        alt="No data found"
                        style={{ width: "350px", marginBottom: "16px" }}
                    />
                    <Typography variant="h6">No Data Found</Typography>
                </Box>
            )}
            <AddStock
                currentRow={currentRow}
                open={openAddStockPopup}
                onClose={handleCloseAddStockPopup}
                update={update}
                setUpdate={setUpdate}
            />
            <RemoveStock
                currentRow={currentRow}
                open={openRemoveStockPopup}
                onClose={handleCloseRemoveStockPopup}
                update={update}
                setUpdate={setUpdate}
            />
            <Delete
                currentRow={currentRow}
                open={openDeletePopup}
                onClose={handleCloseDeletePopup}
                onConfirm={handleDelete}
            />
            <EditInventoryDialog
                currentRow={currentRow}
                open={isEditInventoryOpen}
                onClose={() => setEditInventoryOpen(false)}
                update={update}
                setUpdate={setUpdate}
                flag={flag}
                setFlag={setFlag}
            />
            <ToastContainer />
        </Box>
    );
};

export default InventoryTable;
