import { axiosApiService } from "../axios";

const AdminWorkshopAPIs = {
    WorkshopListGet: async function () {
        
        const response = await axiosApiService.get(`training/workshops/`);
        console.log((response));
        return response.data
    },

    WorkshopDetailPatch: async function(workshopID,values,token) {
        if (!token) {
            throw new Error('Access token is missing.');
        }
        try {
            console.log('Patching with values:', values);
            const response = await axiosApiService.patch(`/training/workshops/${workshopID}/`, values, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Patch Workshop Response:', response);
            return response.data; 
        } catch (error) {
            console.error('Error Patching workshop:', error.response ? error.response.data : error.message);
            alert('Seems like tokens have expired. Please log in again');
            throw error;
        }
    },

    workshopDetailDelete: async function(workshopID,token) {
        if (!token) {
            throw new Error('Access token is missing.');
        }
        try {
            console.log('Deleting workshop with ID:', workshopID);
            const response = await axiosApiService.delete(`/training/workshops/${workshopID}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Delete Workshop Response:', response);
            return response.data; 
        } catch (error) {
            console.error('Error deleting workshop:', error.response ? error.response.data : error.message);
            throw error;
        }
    },
   
    
}
export default AdminWorkshopAPIs;