import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Avatar, IconButton, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import AdminUserList from '../../../utilities/api/user/AdminUserList';
import AdminUserDetail from '../../../utilities/api/user/AdminUserDetail';
import { useSelector } from 'react-redux';
import { ImageBaseURL } from "../../../utilities/api/axios";

const positionLabels = {
  0: 'TL student management body',
  1: 'Manager (other technical clubs)',
  2: 'TL Manager',
  3: 'TL Technician',
  4: 'Other',
};

const User = ({ searchQuery }) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const user = useSelector(state => state.user.user);
  const currentUserCollegeName = user?.user?.TL_name;

  const handleOpenDialog = (userId, newAdminStatus, userName) => {
    setSelectedUser({ userId, newAdminStatus, userName });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleConfirmAdminToggle = async () => {
    if (selectedUser) {
      const { userId, newAdminStatus } = selectedUser;
      try {
        await AdminUserDetail.AdminDetailUpdate(userId, { is_admin: newAdminStatus });
        setRows(prevRows =>
          prevRows.map(row => row.id === userId ? { ...row, isAdmin: newAdminStatus } : row)
        );
      } catch (error) {
        console.error('Error updating admin status:', error);
      }
      handleCloseDialog();
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'User Name',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={`${ImageBaseURL}${params.row.image}`} alt={params.row.name} />
          <div>
            <div>{params.value}</div>
            <div style={{ color: 'gray', fontSize: '0.875rem' }}>{params.row.email}</div>
          </div>
        </Box>
      ),
    },
    { field: 'role', headerName: 'Role', flex: 0.5 },
    { 
      field: 'isAdmin', 
      headerName: 'Admin', 
      flex: 0.5,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={() => handleOpenDialog(params.row.id, !params.value, params.row.name)}
        />
      ),
    },
    { field: 'group', headerName: 'Group Name', flex: 0.5 },
    {
      field: 'actions',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={() => console.log('Delete', params.id)}>
            <DeleteIcon color="error" />
          </IconButton>
          <IconButton onClick={() => console.log('Edit', params.id)}>
            <EditIcon color="primary" />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AdminUserList.AllUserListGet();
        const formattedData = data
          .filter(user => user.TL_name === currentUserCollegeName)
          .map(user => ({
            id: user.id,
            username: user.username,
            name: user.name || user.username,
            email: user.email,
            role: user.other_position || positionLabels[user.position] || 'N/A',
            group: user.TL_name,
            image: user.profile_pic,
            isAdmin: user.is_admin,
          }));
        setRows(formattedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user, currentUserCollegeName]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredRows(rows);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredRows(
        rows.filter(
          row => 
            (row.name && row.name.toLowerCase().includes(lowerCaseQuery)) ||
            (row.username && row.username.toLowerCase().includes(lowerCaseQuery)) ||
            (row.email && row.email.toLowerCase().includes(lowerCaseQuery))
        )
      );
    }
  }, [searchQuery, rows]);

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Admin Status Change</DialogTitle>
        <DialogContent>
          Are you sure you want to <b>{selectedUser?.newAdminStatus ? "grant" : "remove"}</b> admin rights for <strong>{selectedUser?.userName}</strong>?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmAdminToggle} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default User;



// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { Box, Avatar, IconButton, Checkbox } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import { useState, useEffect } from 'react';
// import AdminUserList from '../../../utilities/api/user/AdminUserList';
// import AdminUserDetail from '../../../utilities/api/user/AdminUserDetail'; // Import the AdminUserDetail API
// import { useSelector } from 'react-redux';
// import { ImageBaseURL } from "../../../utilities/api/axios";

// const positionLabels = {
//   0: 'TL student management body',
//   1: 'Manager (other technical clubs)',
//   2: 'TL Manager',
//   3: 'TL Technician',
//   4: 'Other',
// };

// const User = ({ searchQuery }) => {
//   const [rows, setRows] = useState([]);
//   const [filteredRows, setFilteredRows] = useState([]);
//   const user = useSelector(state => state.user.user);
//   const currentUserCollegeName = user?.user?.TL_name;

//   // Define handleAdminToggle before columns array
//   const handleAdminToggle = async (userId, newAdminStatus) => {
//     try {
//       await AdminUserDetail.AdminDetailUpdate(userId, { is_admin: newAdminStatus });
//       setRows(prevRows => 
//         prevRows.map(row => row.id === userId ? { ...row, isAdmin: newAdminStatus } : row)
//       );
//     } catch (error) {
//       console.error('Error updating admin status:', error);
//     }
//   };

//   const columns = [
//     {
//       field: 'name',
//       headerName: 'User Name',
//       flex: 1,
//       renderCell: (params) => (
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//           <Avatar src={`${ImageBaseURL}${params.row.image}`} alt={params.row.name} />
//           <div>
//             <div>{params.value}</div>
//             <div style={{ color: 'gray', fontSize: '0.875rem' }}>{params.row.email}</div>
//           </div>
//         </Box>
//       ),
//     },
//     { field: 'role', headerName: 'Role', flex: 0.5 },
//     { 
//       field: 'isAdmin', 
//       headerName: 'Admin', 
//       flex: 0.5,
//       renderCell: (params) => (
//         <Checkbox
//           checked={params.value}
//           onChange={() => handleAdminToggle(params.row.id, !params.value)}
//         />
//       ),
//     },
//     { field: 'group', headerName: 'Group Name', flex: 0.5 },
//     {
//       field: 'actions',
//       headerName: 'Action',
//       flex: 0.5,
//       renderCell: (params) => (
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//           <IconButton onClick={() => console.log('Delete', params.id)}>
//             <DeleteIcon color="error" />
//           </IconButton>
//           <IconButton onClick={() => console.log('Edit', params.id)}>
//             <EditIcon color="primary" />
//           </IconButton>
//         </Box>
//       ),
//     },
//   ];

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await AdminUserList.AllUserListGet();
//         const formattedData = data
//           .filter(user => user.TL_name === currentUserCollegeName)
//           .map(user => ({
//             id: user.id,
//             username: user.username,
//             name: user.name || user.username,
//             email: user.email,
//             role: user.other_position || positionLabels[user.position] || 'N/A',
//             group: user.TL_name,
//             image: user.profile_pic,
//             isAdmin: user.is_admin,
//           }));
//         setRows(formattedData);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchData();
//   }, [user, currentUserCollegeName]);

//   useEffect(() => {
//     if (searchQuery.trim() === '') {
//       setFilteredRows(rows);
//     } else {
//       const lowerCaseQuery = searchQuery.toLowerCase();
//       setFilteredRows(
//         rows.filter(
//           row => 
//             (row.name && row.name.toLowerCase().includes(lowerCaseQuery)) ||
//             (row.username && row.username.toLowerCase().includes(lowerCaseQuery)) ||
//             (row.email && row.email.toLowerCase().includes(lowerCaseQuery))
//         )
//       );
//     }
//   }, [searchQuery, rows]);

//   return (
//     <Box sx={{ height: 600, width: '100%' }}>
//       <DataGrid
//         rows={filteredRows}
//         columns={columns}
//         pageSize={10}
//         rowsPerPageOptions={[5, 10, 20]}
//         checkboxSelection
//       />
//     </Box>
//   );
// };

// export default User;
