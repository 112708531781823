import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid
} from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import InfoIcon from '@mui/icons-material/Info';
import Papa from 'papaparse'; // Robust CSV parsing
import EquipmentListAPIs from '../../../utilities/api/equipment/AdminEquipmentList';
import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
import AddEquipmentDialog from './AddEquipment';
import { parseISO, isValid } from 'date-fns';

// Mapping availability descriptions to numerical values
const availabilityMapping = {
  'Moderate': 2,
  'Very Low': 0,
  'Low': 2,
  'High': 3,
  'Always': 4
};

// Expected schema fields
const schemaFields = [
  'category', 'location', 'name', 'upc', 'manufacturer', 'description',
  'status', 'instances', 'purchase_cost', 'purchase_date', 'warranty_expiration'
];

// Descriptions for schema fields
const schemaDescription = [
  "category: The category of the equipment",
  "location: The location of the equipment",
  "name: The name of the equipment",
  "upc: The UPC code",
  "manufacturer: The manufacturer of the equipment",
  "description: A description of the equipment",
  "status: The operational status",
  "instances: Number of instances",
  "purchase_cost: The purchase cost",
  "purchase_date: The purchase date",
  "warranty_expiration: The warranty expiration date",
];

// Utility function to validate dates using date-fns

const isValidDate = (dateString) => {
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) && dateString === parsedDate.toISOString().split('T')[0];
};

const ImportEquipment = ({ token, isOpen, onClose }) => {
  const [csvData, setCsvData] = useState(null);
  const [mapping, setMapping] = useState({});
  const [mappingDialogOpen, setMappingDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [errorEntries, setErrorEntries] = useState([]);
  const [successfulImports, setSuccessfulImports] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [newLocations, setNewLocations] = useState([]);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchCategoriesAndLocations = async () => {
      try {
        const [categoriesResponse, locationsResponse] = await Promise.all([
          InventoryCategoryAPIs.CategoryListGet(),
          InventoryLocationAPIs.LocationListGet()
        ]);
        setCategories(categoriesResponse.map(category => category.name));
        setLocations(locationsResponse.map(location => location.name));
      } catch (error) {
        console.error('Error fetching categories or locations:', error);
      }
    };

    fetchCategoriesAndLocations();
  }, []);

  const handleFileLoaded = (headers, rows) => {
    const initialMapping = {};

    schemaFields.forEach(field => {
      initialMapping[field] = headers.includes(field) ? field : '';
    });

    setCsvData({ headers, rows });
    setMapping(initialMapping);
    setTotalEntries(rows.length); // Set total entries
    setMappingDialogOpen(true);
    onClose();
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (file) => {
    Papa.parse(file, {
      header: true, // Automatically uses the first row as headers
      skipEmptyLines: true, // Skips empty lines
      complete: (results) => {
        const { data, errors, meta } = results;
        if (errors.length) {
          console.error('Error parsing CSV:', errors);
          // Optionally, handle parsing errors (e.g., display to user)
        } else {
          handleFileLoaded(meta.fields, data);
        }
      },
    });
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const updateInventory = async (mapping) => {
    if (!csvData || Object.keys(mapping).length === 0) return;

    const { headers, rows } = csvData;

    // Initialize local tracking variables
    let localSuccessfulImports = 0;
    const localErrors = [];
    const localNewCategories = [];
    const localNewLocations = [];

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      const entry = {};

      // Map CSV fields to schema fields
      schemaFields.forEach(field => {
        const csvField = mapping[field];
        let value = row[csvField];

        if (typeof value === 'string') {
          value = value.replace(/\r$/, '').trim(); // Remove trailing \r and trim
        }

        if (field === 'availability' && availabilityMapping[value] !== undefined) {
          value = availabilityMapping[value];
        }

        if (value !== undefined && value !== '') {
          entry[field] = value;
        }
      });

      console.log(`Processing entry ${index + 1}:`, entry);

      // --- Updated Validation Checks ---
      // Ensure 'name' and 'instances' are mandatory
      if (!entry.name) {
        localErrors.push({
          row: index + 1,
          entry,
          error: "Missing mandatory field: name."
        });
        console.error(`Entry ${index + 1} failed due to missing 'name'.`);
        continue; // Skip to the next entry
      }

      if (!entry.instances) { // Changed from 'category' to 'instances'
        localErrors.push({
          row: index + 1,
          entry,
          error: "Missing mandatory field: instances."
        });
        console.error(`Entry ${index + 1} failed due to missing 'instances'.`);
        continue; // Skip to the next entry
      }
      // --- End of Validation Checks ---

      // --- Conditional Handling of Category ---
      // Since 'category' is no longer mandatory, handle it only if present
      if (entry.category) {
        if (!categories.includes(entry.category)) {
          try {
            await InventoryCategoryAPIs.CategoryListPost(entry.category, token);
            setCategories((prevCategories) => [...prevCategories, entry.category]);
            localNewCategories.push(entry.category);
            console.log(`Added new category: ${entry.category}`);
          } catch (error) {
            if (error.response?.status === 400 || error.response?.status === 409) {
              // Ignore error if category already exists
              console.log(`Category '${entry.category}' already exists, ignoring error.`);
            } else {
              localErrors.push({
                row: index + 1,
                entry,
                error: `Failed to add category: ${entry.category}. ${error.message}`,
              });
              console.error(`Entry ${index + 1} failed to add category.`);
              continue; // Skip to the next entry if a different error occurs
            }
          }
        }
      }
      // --- End of Conditional Handling ---

      // --- Conditional Handling of Location ---
      // Assuming 'location' is still optional; handle similarly to 'category'
      if (entry.location) {
        if (!locations.includes(entry.location)) {
          try {
            await InventoryLocationAPIs.LocationListPost(entry.location, token);
            setLocations((prevLocations) => [...prevLocations, entry.location]);
            localNewLocations.push(entry.location);
            console.log(`Added new location: ${entry.location}`);
          } catch (error) {
            if (error.response?.status === 400 || error.response?.status === 409) {
              // Ignore error if location already exists
              console.log(`Location '${entry.location}' already exists, ignoring error.`);
            } else {
              localErrors.push({
                row: index + 1,
                entry,
                error: `Failed to add location: ${entry.location}. ${error.message}`,
              });
              console.error(`Entry ${index + 1} failed to add location.`);
              continue; // Skip to the next entry if a different error occurs
            }
          }
        }
      }
      // --- End of Conditional Handling ---

      // Add Inventory
      try {
        await EquipmentListAPIs.AddInventory(entry, token);
        localSuccessfulImports++;
        console.log(`Successfully imported entry ${index + 1}: ${entry.name}`);
      } catch (error) {
        localErrors.push({
          row: index + 1,
          entry,
          error: error.response?.data || error.message
        });
        console.error(`Entry ${index + 1} failed to add inventory.`, error);
      }
    }

    // Update state after processing all entries
    setErrorEntries(localErrors);
    setMappingDialogOpen(false);
    setResultDialogOpen(true);
    setSuccessfulImports(localSuccessfulImports);
    setNewCategories(localNewCategories);
    setNewLocations(localNewLocations);

    console.log(`Import completed: ${localSuccessfulImports}/${rows.length} successful.`);
    console.log(`Errors:`, localErrors);
  };

  const handleClose = () => {
    onClose();
    setMappingDialogOpen(false);
    setResultDialogOpen(false);
  };

  const handleInfoOpen = () => {
    setInfoDialogOpen(true);
  };

  const handleInfoClose = () => {
    setInfoDialogOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}>
        <DialogContent>
          <IconButton
            aria-label="info"
            onClick={handleInfoOpen}
            sx={{
              position: 'absolute',
              right: 20,
              top: 16,
              color: (theme) => theme.palette.grey[500],
              zIndex:'1000'
            }}
          >
            <InfoIcon />
          </IconButton>
          <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  handleFileChange(file);
                }
              }}
            />
            {isOpen && (
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50vh',
                  cursor: 'pointer',
                  border: '2px dashed #ccc',
                  borderRadius: '12px',
                  padding: '20px',
                  textAlign: 'center'
                }}
                onClick={handleIconClick}
              >
                <CreateNewFolderIcon sx={{ fontSize: 80, color: '#ccc' }} />
                <Typography variant="h6" sx={{ mt: 2 }}>Click to select a file to import</Typography>
                <Typography variant="body2">Or drag and drop it here</Typography>
              </Box>
            )}
          </div>
        </DialogContent>
      </Dialog>
      
      <MappingDialog
        open={mappingDialogOpen}
        headers={csvData ? csvData.headers : []}
        schemaFields={schemaFields}
        mapping={mapping}
        onClose={() => setMappingDialogOpen(false)}
        onSave={(newMapping) => {
          setMapping(newMapping);
          updateInventory(newMapping);
        }}
        onInfoOpen={handleInfoOpen}
      />

      <ResultDialog
        open={resultDialogOpen}
        errors={errorEntries}
        onClose={handleClose}
        successfulImports={successfulImports}
        totalEntries={totalEntries}
        newCategories={newCategories}
        newLocations={newLocations}
        onInfoOpen={handleInfoOpen}
      />

      <InfoDialog
        open={infoDialogOpen}
        onClose={handleInfoClose}
        schemaDescription={schemaDescription}
      />
    </>
  );
};

// MappingDialog Component for field mapping UI
const MappingDialog = ({ open, headers, schemaFields, mapping, onClose, onSave, onInfoOpen }) => {
  const [localMapping, setLocalMapping] = useState(mapping);
  const [usedFields, setUsedFields] = useState([]);

  useEffect(() => {
    setLocalMapping(mapping);
    setUsedFields(Object.values(mapping).filter(field => field !== ''));
  }, [mapping]);

  const handleMappingChange = (schemaField, csvField) => {
    const newMapping = { ...localMapping, [schemaField]: csvField };
    setLocalMapping(newMapping);
    setUsedFields(Object.values(newMapping).filter(field => field !== ''));
  };

  const handleSave = () => {
    onSave(localMapping);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{"& .MuiDialog-paper": { borderRadius: "24px", background:'#f1f6fd', } }}
    >
      <DialogTitle sx={{fontSize:'32px'}}>Column Mapping
        <IconButton
          aria-label="info"
          onClick={onInfoOpen}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <InfoIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Select the MIS field to import, then set how you would like these
          converted to fields in CSV.
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F2F2F2" }}>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: "#5c5c5c" }}
                  >
                    MIS Field
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: "#5c5c5c" }}
                  >
                    CSV Field
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schemaFields.map((field) => (
                <TableRow key={field} sx={{ backgroundColor: "#FFFFFF" }}>
                  <TableCell>
                    <Typography variant="body1" sx={{color:"#898989"}}>{field}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" >→</Typography>
                  </TableCell>
                  <TableCell>
                    <Select
                      fullWidth
                      value={localMapping[field] || ""}
                      onChange={(e) =>
                        handleMappingChange(field, e.target.value)
                      }
                      sx={{color:"#898989"}}
                    >
                      <MenuItem value="">
                        <em>Don't map this field</em>
                      </MenuItem>
                      {headers.map((header) => (
                        <MenuItem
                          key={header}
                          value={header}
                          disabled={usedFields.includes(header)}
                        >
                          {header}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} sx={{background:'#007BFF', color:'#FFFFFF', borderRadius:'12px', padding:'12px 16px'}}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// ResultDialog Component to show successful or failed entries 
const ResultDialog = ({ open, errors, onClose, successfulImports, totalEntries, newCategories, newLocations, onInfoOpen }) => {
  const [showAddEquipment, setShowAddEquipment] = useState(false);

  const handleAddEquipmentClick = () => {
    setShowAddEquipment(true);
  };

  const handleCloseAddEquipment = () => {
    setShowAddEquipment(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" minWidth="md" sx={{ '& .MuiDialog-paper': { borderRadius: '24px', background:'#f1f6fd' } }}>
      <DialogTitle>
        {showAddEquipment ? 'Add Equipment' : (errors.length > 0 ? 'Failed Entries' : 'Success')}
        <IconButton
          aria-label="info"
          onClick={onInfoOpen}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <InfoIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showAddEquipment ? (
          <AddEquipmentDialog open={showAddEquipment} onClose={handleCloseAddEquipment} />
        ) : (
          <>
            {errors.length > 0 ? (
              <>
                <Typography variant="h6" sx={{ mb: 2 }}>You can resolve it!</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="body2" sx={{ mr: 2 }}>Correct the CSV File</Typography>
                  <Typography variant="body1" sx={{ mx: 2 }}>or</Typography>
                  <Button variant="outlined" sx={{background:'#007BFF', color:'#FFFFFF', borderRadius:'12px', padding:'8px 12px'}} onClick={handleAddEquipmentClick}>Manually add Equipment</Button>
                </Box>
                <Typography variant="body1" sx={{ mb: 2 , textAlign:'center', fontSize:'larger', border:'1px solid', borderRadius:'32px', padding: '8px' }}>
                  Successfully imported entries: {successfulImports} / {totalEntries}
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Row</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Name</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">UPC</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Description</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Instances</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Category</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Location</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Error</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {errors.map((error, index) => (
                        <TableRow key={index} sx={{ backgroundColor: '#FFFFFF' }}>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.row}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.name || 'N/A'}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.upc}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.description || 'N/A'}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.instances}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.category || 'N/A'}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.location || 'N/A'}</Typography></TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: 'red' }}>
                              {typeof error.error === 'object' ? JSON.stringify(error.error) : error.error}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {(newCategories.length > 0 || newLocations.length > 0) && (
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {newCategories.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">New Categories Added:</Typography>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
                                <TableCell sx={{ color: '#FFFFFF' }}>
                                  <Typography variant="body1" fontWeight="bold">Category Name</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newCategories.map((category, index) => (
                                <TableRow key={index} sx={{ backgroundColor: '#F2F2F2' }}>
                                  <TableCell><Typography variant="body1">{category}</Typography></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                    {newLocations.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">New Locations Added:</Typography>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
                                <TableCell sx={{ color: '#FFFFFF' }}>
                                  <Typography variant="body1" fontWeight="bold">Location Name</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newLocations.map((location, index) => (
                                <TableRow key={index} sx={{ backgroundColor: '#F2F2F2' }}>
                                  <TableCell><Typography variant="body1">{location}</Typography></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            ) : (
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography variant="h4" sx={{ color: 'green', mb: 2 }}>All entries have been successfully added.</Typography>
                <Typography variant="body1">Successfully imported entries: {successfulImports} / {totalEntries}</Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      {!showAddEquipment && (
        <DialogActions>
          <Button onClick={onClose} sx={{ background: '#007BFF', color: '#FFFFFF', borderRadius: '12px', padding: '12px 8px' }}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const InfoDialog = ({ open, onClose, schemaDescription }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    sx={{ "& .MuiDialog-paper": { borderRadius: "24px", padding: "20px" } }}
  >
    <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold" }}>Schema Information</DialogTitle>
    <DialogContent>
      <Typography variant="body1" sx={{ mb: 2 }}>
        These are the mandatory fields:
      </Typography>
      <Box component="ul" sx={{ listStyleType: "none", padding: 0, mb: 2, pl: 2 }}>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>name:</strong> Product type is required
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>instances:</strong> Instances is required
          </Typography>
        </li>
      </Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Available fields:
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#9E9E9E" }}>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Field
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Options
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schemaDescription.map((desc, index) => {
              const [field, description] = desc.split(": ");
              let options = "";
              if (field === "availability") {
                options = "Moderate, Very Low, Low, High, Always";
              } else if (field === "status") {
                options = "Operational, Under Maintenance, Out of Service";
              }

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {field}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {options}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} sx={{ background: '#007BFF', color: '#FFFFFF', borderRadius: '12px', padding: '12px 8px' }}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default ImportEquipment;
