// ProfileImage.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import AdminUserDetail from '../../utilities/api/user/AdminUserDetail';
import { updateUser } from '../../redux/slices/userSlice';
import { ImageBaseURL } from '../../utilities/api/axios';

const ProfileImage = ({ user, refreshUserData }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);
  const [profilePic, setProfilePic] = React.useState(null);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async (values) => {
    try {
      // Check if there's a profile picture to upload
      if (profilePic) {
        // Create a FormData object to handle file uploads along with other form data
        const formData = new FormData();
        formData.append('profile_pic', profilePic); // Append the profile picture
        formData.append('username', values.username); // Append other necessary form data fields
  
        // Update the user details on the server with form data including the profile picture
        await AdminUserDetail.AdminDetailUpdate(user.id, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        // Update the user details without profile picture
        await AdminUserDetail.AdminDetailUpdate(user.id, values);
      }
  
      // Fetch the latest user data to ensure state consistency
      const freshUserData = await AdminUserDetail.AdminDetailGet(user.id);
  
      // Update the Redux state with the latest fetched user data
      dispatch(updateUser(freshUserData));
  
      // Exit edit mode after successful save
      await refreshUserData();
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required')
      .matches(/^\S*$/, 'Username cannot contain spaces'), // Regex to disallow spaces
  });  

  const formik = useFormik({
    initialValues: { username: user.username },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleSave(values),
  });

  const handleProfilePicChange = (event) => {
    setProfilePic(event.currentTarget.files[0]);
  };

  const profilePicUrl = user.profile_pic ? `${ImageBaseURL}/${user.profile_pic}` : '';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '1rem',
        width: '95%',
        border: '1px solid #D3D3D3',
        borderRadius: '10px',
        padding: '25px',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar
          alt="Profile"
          src={profilePic ? URL.createObjectURL(profilePic) : profilePicUrl}
          sx={{ width: 200, height: 200, border: '1px solid #007BFF' }}
        />
        <Box sx={{ marginLeft: '3rem' }}>
          {editMode ? (
            <>
              <TextField
                label="Username"
                variant="outlined"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                fullWidth
              />
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="profile-pic-upload"
                type="file"
                onChange={handleProfilePicChange}
              />
              <label htmlFor="profile-pic-upload">
                <Button variant="contained" component="span" sx={{ marginTop: '1rem' }}>
                  Upload Profile Picture
                </Button>
              </label>
            </>
          ) : (
            <>
              <Typography
                variant="h4"
                sx={{ color: '#007BFF', fontWeight: '500', fontSize: '2.5rem' }}
              >
                {user.username}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                  opacity: '0.7',
                  fontSize: '1.1rem',
                  color: '#A6A6A6',
                }}
              >
                {user.staff ? 'Staff' : 'Admin'}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {editMode ? (
        <Box sx={{ position: 'absolute', top: '12px', right: '12px', cursor: 'pointer' }}>
          <Typography
            onClick={formik.handleSubmit}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 10px',
              color: '#007BFF',
              fontSize: '1.1rem',
              border: '1px solid #007BFF',
              borderRadius: '20px',
              cursor: 'pointer',
            }}
          >
            Save
          </Typography>
        </Box>
      ) : (
        <Box sx={{ position: 'absolute', top: '12px', right: '12px', cursor: 'pointer' }} onClick={handleEdit}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 10px',
              color: '#007BFF',
              fontSize: '1.1rem',
              border: '1px solid #007BFF',
              borderRadius: '20px',
            }}
          >
            Edit
            <EditIcon sx={{ fontSize: '1rem', color: '#007BFF', marginLeft: '5px' }} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProfileImage;


// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useFormik } from 'formik';
// import Avatar from '@mui/material/Avatar';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import EditIcon from '@mui/icons-material/Edit'; // Import Edit icon from Material-UI
// import * as Yup from 'yup';
// import AdminUserDetail from '../../utilities/api/user/AdminUserDetail';
// import { updateUser } from '../../redux/slices/userSlice';
// import { ImageBaseURL } from "../../utilities/api/axios";

// const ProfileImage = () => {
//   const {user} = useSelector((state) => state.user.user); // Ensure it fetches the latest user data from the Redux store
//   const dispatch = useDispatch();
//   const [editMode, setEditMode] = React.useState(false);
//   const [profilePic, setProfilePic] = React.useState(null);

//   const handleEdit = () => {
//     setEditMode(true);
//   };

//   const handleSave = async (values) => {
//     try {
//       let updatedUser;

//       if (profilePic) {
//         const formData = new FormData();
//         formData.append('profile_pic', profilePic);
//         formData.append('username', values.username);

//         updatedUser = await AdminUserDetail.AdminDetailUpdate(user.id, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });
//       } else {
//         updatedUser = await AdminUserDetail.AdminDetailUpdate(user.id, values);
//       }

//       console.log('User updated successfully:', updatedUser);
//       dispatch(updateUser(updatedUser)); // Dispatch the updateUser action with the updated user data
//       setEditMode(false);  // Exit edit mode
//     } catch (error) {
//       console.error('Error updating user:', error);
//     }
//   };

//   const validationSchema = Yup.object().shape({
//     username: Yup.string().required('Username is required'),
//   });

//   const formik = useFormik({
//     initialValues: {
//       username: user.username,
//     },
//     enableReinitialize: true, // This ensures Formik will update its values when the user changes
//     validationSchema: validationSchema,
//     onSubmit: (values) => {
//       console.log('Form submitted with values:', values);
//       handleSave(values);
//     },
//   });

//   const handleProfilePicChange = (event) => {
//     setProfilePic(event.currentTarget.files[0]);
//   };

//   const profilePicUrl = user.profile_pic ? `${ImageBaseURL}/${user.profile_pic}` : '';

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         margin: '1rem',
//         width: '95%',
//         border: '1px solid #D3D3D3',
//         borderRadius: '10px',
//         padding: '25px',
//         position: 'relative',
//       }}
//     >
//       <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
//         <Avatar
//           alt="Profile"
//           src={profilePic ? URL.createObjectURL(profilePic) : profilePicUrl}
//           sx={{ width: 200, height: 200, border: '1px solid #007BFF' }}
//         />
//         <Box sx={{ marginLeft: '3rem' }}>
//           {editMode ? (
//             <>
//               <TextField
//                 label="Username"
//                 variant="outlined"
//                 name="username"
//                 value={formik.values.username}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.username && Boolean(formik.errors.username)}
//                 helperText={formik.touched.username && formik.errors.username}
//                 fullWidth
//               />
//               <input
//                 accept="image/*"
//                 style={{ display: 'none' }}
//                 id="profile-pic-upload"
//                 type="file"
//                 onChange={handleProfilePicChange}
//               />
//               <label htmlFor="profile-pic-upload">
//                 <Button variant="contained" component="span" sx={{ marginTop: '1rem' }}>
//                   Upload Profile Picture
//                 </Button>
//               </label>
//             </>
//           ) : (
//             <>
//               <Typography
//                 variant="h4"
//                 sx={{ color: '#007BFF', fontWeight: '500', fontSize: '2.5rem' }}
//               >
//                 {user.username} {/* This will automatically update when the username is updated */}
//               </Typography>
//               <Typography variant="body2" sx={{ fontWeight: 700, opacity: '0.7', fontSize: '1.1rem', color: '#A6A6A6' }}>
//                 {user.staff ? 'Staff' : 'Admin'}
//               </Typography>
//             </>
//           )}
//         </Box>
//       </Box>
//       {editMode ? (
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '12px',
//             right: '12px',
//             cursor: 'pointer',
//           }}
//         >
//           <Typography
//             onClick={formik.handleSubmit}
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               padding: '4px 10px',
//               color: '#007BFF',
//               fontSize: '1.1rem',
//               border: '1px solid #007BFF',
//               borderRadius: '20px',
//               cursor: 'pointer',
//             }}
//           >
//             Save
//           </Typography>
//         </Box>
//       ) : (
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '12px',
//             right: '12px',
//             cursor: 'pointer',
//           }}
//           onClick={handleEdit}
//         >
//           <Typography
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               padding: '4px 10px',
//               color: '#007BFF',
//               fontSize: '1.1rem',
//               border: '1px solid #007BFF',
//               borderRadius: '20px',
//             }}
//           >
//             Edit
//             <EditIcon sx={{ fontSize: '1rem', color: '#007BFF', marginLeft: '5px' }} />
//           </Typography>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ProfileImage;
