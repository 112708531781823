import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { toggleTutEditMode } from '../../../../redux/slices/tutorialEditSlice';
import AddVideo from './AddVideo';
import EditVideo from './EditVideo';
import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs';
import AdminVideoAPIs from '../../../../utilities/api/tutorials/AdminVideoAPIs';
import {ImageBaseURL} from "../../../../utilities/api/axios";

const EditModuleForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const currentItem = useSelector((state) => state.tutorialEdit.tutCurrentItem);

  const [videoComponents, setVideoComponents] = useState([]);
  const [existingVideos, setExistingVideos] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoIds, setVideoIds] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [machines, setMachines] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (currentItem && currentItem.videos) {
      const fetchVideos = async () => {
        try {
          const videos = await Promise.all(currentItem.videos.map(async (video) => {
            const videoData = await AdminVideoAPIs.VideoDetailGet(video.video);
            return {
              id: videoData.id,
              title: videoData.title || '',
              description: videoData.description || '',
              link: videoData.link || '',
              order: video.order
            };
          }));
          setExistingVideos(videos);
          setVideoIds(videos.map(video => ({ id: video.id, title: video.title, description: video.description, link: video.link, order: video.order })));
        } catch (error) {
          console.error('Failed to fetch video details', error);
        }
      };
      fetchVideos();
    }
  }, [currentItem]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Module Name is required'),
    description: Yup.string().required('Module Description is required'),
    related_machines: Yup.array().required('Related Machines is required'),
    related_equipments: Yup.array().required('Related Equipments is required'),
    related_inventory: Yup.array().required('Related Inventory is required'),
    image: Yup.mixed().nullable(),  // Optional image field
  });

  const formik = useFormik({
    initialValues: {
      name: currentItem?.name || '',
      description: currentItem?.description || '',
      related_machines: [],
      related_equipments: [],
      related_inventory: [],
      image: currentItem?.image || null,  // Initial value for image
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Prepare the JSON payload
      const jsonPayload = {
        id: currentItem.id,
        name: values.name,
        description: values.description,
        related_machines: values.related_machines.map(machine => machine.id),
        related_equipments: values.related_equipments.map(equipment => equipment.id),
        related_inventory: values.related_inventory.map(inventory => inventory.id),
        videos: videoIds.map((video, index) => ({
          video: video.id,
          order: index + 1
        })),
      };

      // If a new thumbnail is selected, send it separately
      if (thumbnail) {
        const formData = new FormData();
        formData.append('image', thumbnail);

        try {
          await AdminModuleAPIs.UpdateModule(currentItem.id, formData);
          console.log('Image updated successfully');
        } catch (error) {
          console.error('Error updating image:', error);
        }
      }

      // Send JSON payload
      try {
        await AdminModuleAPIs.UpdateModule(currentItem.id, jsonPayload);
        console.log('Module updated successfully');
      } catch (error) {
        console.error('Error updating module:', error);
      }

      // Update each video if its details have changed
      for (const video of videoIds) {
        try {
          await AdminVideoAPIs.UpdateVideo(video.id, {
            title: video.title,
            description: video.description,
            link: video.link,
            order: video.order,
          });
          console.log(`Video ${video.id} updated successfully`);
        } catch (error) {
          console.error(`Error updating video ${video.id}:`, error);
        }
      }
      
      resetForm();
      dispatch(toggleTutEditMode());
    },
  });

  useEffect(() => {
    const { name, description } = formik.values;
    const formFilled = name && description;
    setIsFormFilled(formFilled);
  }, [formik.values]);

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const machines = await MachineListAPIs.MachineListGet();
        setMachines(machines || []);
        if (currentItem) {
          formik.setFieldValue(
            'related_machines',
            currentItem.related_machines.map((id) => machines.find((machine) => machine.id === id))
          );
        }
      } catch (error) {
        console.error('Failed to fetch machines', error);
      }
    };

    const fetchEquipments = async () => {
      try {
        const equipments = await EquipmentListAPIs.EquipmentListGet();
        setEquipments(equipments || []);
        if (currentItem) {
          formik.setFieldValue(
            'related_equipments',
            currentItem.related_equipments.map((id) => equipments.find((equipment) => equipment.id === id))
          );
        }
      } catch (error) {
        console.error('Failed to fetch equipments', error);
      }
    };

    const fetchInventories = async () => {
      try {
        const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
        setInventories(inventories || []);
        if (currentItem) {
          formik.setFieldValue(
            'related_inventory',
            currentItem.related_inventory.map((id) => inventories.find((inventory) => inventory.id === id))
          );
        }
      } catch (error) {
        console.error('Failed to fetch inventories', error);
      }
    };

    fetchMachines();
    fetchEquipments();
    fetchInventories();
  }, [user.refresh, user.access, currentItem]);

  const handleCancel = () => {
    const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
    if (userConfirmed) {
      dispatch(toggleTutEditMode());
    }
  };

  const addVideoComponent = () => {
    setVideoComponents([...videoComponents, { id: videoComponents.length }]);
  };

  const removeVideoComponent = (id) => {
    setVideoComponents(videoComponents.filter((video) => video.id !== id));
  };

  const handleUploadSuccess = (videoId, videoUrl) => {
    setVideoIds([...videoIds, { id: videoId, link: videoUrl, order: videoIds.length + 1 }]);
    setVideoUrls([...videoUrls, videoUrl]);
  };

  const handleUploading = (isUploading) => {
    setUploading(isUploading);
  };

  const handleVideoChange = (id, field, value) => {
    setExistingVideos((prevVideos) =>
      prevVideos.map((video) => (video.id === id ? { ...video, [field]: value } : video))
    );
    setVideoIds((prevVideoIds) =>
      prevVideoIds.map((video) => (video.id === id ? { ...video, [field]: value } : video))
    );
  };

  const removeExistingVideoComponent = (id) => {
    setExistingVideos(existingVideos.filter((video) => video.id !== id));
    setVideoIds(videoIds.filter((video) => video.id !== id));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setThumbnail(file);
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      encType="multipart/form-data"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '95%',
        margin: '0 auto',
        padding: 2,
        position: 'relative',
      }}
    >
      <Typography variant="h5" sx={{ mb: 1 }}>
        Edit Module
      </Typography>
  
      <Box>
        <Box>
          {existingVideos.map((video) => (
            <EditVideo
              key={video.id}
              video={video}
              onRemove={() => removeExistingVideoComponent(video.id)}
              onChange={(id, field, value) => handleVideoChange(id, field, value)}
            />
          ))}
          {videoComponents.map((video) => (
            <AddVideo
              key={video.id}
              onRemove={() => removeVideoComponent(video.id)}
              onUploadSuccess={handleUploadSuccess}
              isFormFilled={isFormFilled}
              handleUploading={handleUploading}
            />
          ))}
        </Box>
  
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="outlined" color="primary" onClick={addVideoComponent}>
            <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
            Add Video
          </Button>
        </Box>
      </Box>
  
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>Module Name</Typography>
        <TextField
          label="Module Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <Typography>Module Description</Typography>
        <TextField
          label="Module Description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          multiline
          rows={4}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
        <Typography>Module Thumbnail</Typography>
        {currentItem?.image && (
          <Box sx={{ mb: 2 }}>
            <img
              // src={currentItem.image}
              src={`${ImageBaseURL}${currentItem.image}`}
              alt={currentItem.image.split('/').pop()}
              style={{ width: '250px', height: '250px', objectFit: 'cover' }}
            />
          </Box>
        )}
        <input
          accept="image/*"
          id="thumbnail"
          type="file"
          onChange={handleImageChange}
        />
        {formik.errors.image && <Typography color="error">{formik.errors.image}</Typography>}
        {machines.length > 0 && (
          <>
            <Typography>Related Machines</Typography>
            <Autocomplete
              multiple
              options={machines}
              getOptionLabel={(option) => option.name}
              value={formik.values.related_machines}
              onChange={(event, value) => formik.setFieldValue('related_machines', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Machines"
                  name="related_machines"
                  error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
                  helperText={formik.touched.related_machines && formik.errors.related_machines}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
        {equipments.length > 0 && (
          <>
            <Typography>Related Equipments</Typography>
            <Autocomplete
              multiple
              options={equipments}
              getOptionLabel={(option) => option.name}
              value={formik.values.related_equipments}
              onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Equipments"
                  name="related_equipments"
                  error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
                  helperText={formik.touched.related_equipments && formik.errors.related_equipments}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
        {inventories.length > 0 && (
          <>
            <Typography>Related Inventory</Typography>
            <Autocomplete
              multiple
              options={inventories}
              getOptionLabel={(option) => option.name}
              value={formik.values.related_inventory}
              onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Inventory"
                  name="related_inventory"
                  error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
                  helperText={formik.touched.related_inventory && formik.errors.related_inventory}
                />
              )}
              disableCloseOnSelect
            />
          </>
        )}
      </Box>
  
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={uploading}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );  
};

export default EditModuleForm;






// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
// import { useFormik } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import * as Yup from 'yup';
// import AddIcon from '@mui/icons-material/Add';
// import { toggleTutEditMode } from '../../../../redux/slices/tutorialEditSlice';
// import AddVideo from './AddVideo';
// import EditVideo from './EditVideo';
// import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
// import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
// import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
// import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs';
// import AdminVideoAPIs from '../../../../utilities/api/tutorials/AdminVideoAPIs';

// const EditModuleForm = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user.user);
//   const currentItem = useSelector((state) => state.tutorialEdit.tutCurrentItem);

//   const [videoComponents, setVideoComponents] = useState([]);
//   const [existingVideos, setExistingVideos] = useState([]);
//   const [videoUrls, setVideoUrls] = useState([]);
//   const [videoIds, setVideoIds] = useState([]);
//   const [isFormFilled, setIsFormFilled] = useState(false);
//   const [uploading, setUploading] = useState(false);

//   const [machines, setMachines] = useState([]);
//   const [equipments, setEquipments] = useState([]);
//   const [inventories, setInventories] = useState([]);
//   const [thumbnail, setThumbnail] = useState(null);

//   useEffect(() => {
//     if (currentItem && currentItem.videos) {
//       const fetchVideos = async () => {
//         try {
//           const videos = await Promise.all(currentItem.videos.map(async (video) => {
//             const videoData = await AdminVideoAPIs.VideoDetailGet(video.video);
//             return {
//               id: videoData.id,
//               title: videoData.title || '',
//               description: videoData.description || '',
//               link: videoData.link || '',
//             };
//           }));
//           setExistingVideos(videos);
//           setVideoIds(videos.map(video => ({ id: video.id, link: video.link })));
//         } catch (error) {
//           console.error('Failed to fetch video details', error);
//         }
//       };
//       fetchVideos();
//     }
//   }, [currentItem]);

//   const validationSchema = Yup.object({
//     name: Yup.string().required('Module Name is required'),
//     description: Yup.string().required('Module Description is required'),
//     related_machines: Yup.array().required('Related Machines is required'),
//     related_equipments: Yup.array().required('Related Equipments is required'),
//     related_inventory: Yup.array().required('Related Inventory is required'),
//     image: Yup.mixed().nullable(),  // Optional image field
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: currentItem?.name || '',
//       description: currentItem?.description || '',
//       related_machines: [],
//       related_equipments: [],
//       related_inventory: [],
//       image: null,  // Initial value for image
//     },
//     enableReinitialize: true,
//     validationSchema: validationSchema,
//     onSubmit: async (values, { resetForm }) => {
//       const formData = new FormData();
//       formData.append('name', values.name);
//       formData.append('description', values.description);
//       if (thumbnail) {
//         formData.append('image', thumbnail);
//       }

//       values.related_machines.forEach((machine) => {
//         formData.append('related_machines[]', machine.id);
//       });

//       values.related_equipments.forEach((equipment) => {
//         formData.append('related_equipments[]', equipment.id);
//       });

//       values.related_inventory.forEach((inventory) => {
//         formData.append('related_inventory[]', inventory.id);
//       });

//       videoIds.forEach((video, index) => {
//         formData.append(`videos[${index}][video]`, video.id);
//         formData.append(`videos[${index}][order]`, index + 1);
//       });

//       try {
//         await AdminModuleAPIs.UpdateModule(currentItem.id, formData);

//         const videoPromises = videoIds.map((video) => {
//           return AdminVideoAPIs.UpdateVideo(video.id, {
//             id: video.id,
//             title: video.title,
//             description: video.description,
//             link: video.link,
//           });
//         });
//         await Promise.all(videoPromises);

//         console.log('Module and videos updated successfully');
//         dispatch(toggleTutEditMode());
//         resetForm();
//       } catch (error) {
//         console.error('Error updating module and videos:', error);
//       }
//     },
//   });

//   useEffect(() => {
//     const { name, description } = formik.values;
//     const formFilled = name && description;
//     setIsFormFilled(formFilled);
//   }, [formik.values]);

//   useEffect(() => {
//     const fetchMachines = async () => {
//       try {
//         const machines = await MachineListAPIs.MachineListGet();
//         setMachines(machines || []);
//         if (currentItem) {
//           formik.setFieldValue(
//             'related_machines',
//             currentItem.related_machines.map((id) => machines.find((machine) => machine.id === id))
//           );
//         }
//       } catch (error) {
//         console.error('Failed to fetch machines', error);
//       }
//     };

//     const fetchEquipments = async () => {
//       try {
//         const equipments = await EquipmentListAPIs.EquipmentListGet();
//         setEquipments(equipments || []);
//         if (currentItem) {
//           formik.setFieldValue(
//             'related_equipments',
//             currentItem.related_equipments.map((id) => equipments.find((equipment) => equipment.id === id))
//           );
//         }
//       } catch (error) {
//         console.error('Failed to fetch equipments', error);
//       }
//     };

//     const fetchInventories = async () => {
//       try {
//         const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
//         setInventories(inventories || []);
//         if (currentItem) {
//           formik.setFieldValue(
//             'related_inventory',
//             currentItem.related_inventory.map((id) => inventories.find((inventory) => inventory.id === id))
//           );
//         }
//       } catch (error) {
//         console.error('Failed to fetch inventories', error);
//       }
//     };

//     fetchMachines();
//     fetchEquipments();
//     fetchInventories();
//   }, [user.refresh, user.access, currentItem]);

//   const handleCancel = () => {
//     const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
//     if (userConfirmed) {
//       dispatch(toggleTutEditMode());
//     }
//   };

//   const addVideoComponent = () => {
//     setVideoComponents([...videoComponents, { id: videoComponents.length }]);
//   };

//   const removeVideoComponent = (id) => {
//     setVideoComponents(videoComponents.filter((video) => video.id !== id));
//   };

//   const handleUploadSuccess = (videoId, videoUrl) => {
//     setVideoIds([...videoIds, { id: videoId, link: videoUrl }]);
//     setVideoUrls([...videoUrls, videoUrl]);
//   };

//   const handleUploading = (isUploading) => {
//     setUploading(isUploading);
//   };

//   const handleVideoChange = (id, field, value) => {
//     setExistingVideos((prevVideos) =>
//       prevVideos.map((video) => (video.id === id ? { ...video, [field]: value } : video))
//     );
//     setVideoIds((prevVideoIds) =>
//       prevVideoIds.map((video) => (video.id === id ? { ...video, [field]: value } : video))
//     );
//   };

//   const removeExistingVideoComponent = (id) => {
//     setExistingVideos(existingVideos.filter((video) => video.id !== id));
//     setVideoIds(videoIds.filter((video) => video.id !== id));
//   };

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     setThumbnail(file);
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={formik.handleSubmit}
//       encType="multipart/form-data"
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         width: '95%',
//         margin: '0 auto',
//         padding: 2,
//         position: 'relative',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Edit Module
//       </Typography>

//       <Box>
//         <Box>
//           {existingVideos.map((video) => (
//             <EditVideo
//               key={video.id}
//               video={video}
//               onRemove={() => removeExistingVideoComponent(video.id)}
//               onChange={handleVideoChange}
//             />
//           ))}
//           {videoComponents.map((video) => (
//             <AddVideo
//               key={video.id}
//               onRemove={() => removeVideoComponent(video.id)}
//               onUploadSuccess={handleUploadSuccess}
//               isFormFilled={isFormFilled}
//               handleUploading={handleUploading}
//             />
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button variant="outlined" color="primary" onClick={addVideoComponent}>
//             <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
//             Add Video
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <Typography>Module Name</Typography>
//         <TextField
//           label="Module Name"
//           name="name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.name && Boolean(formik.errors.name)}
//           helperText={formik.touched.name && formik.errors.name}
//         />
//         <Typography>Module Description</Typography>
//         <TextField
//           label="Module Description"
//           name="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           multiline
//           rows={4}
//           error={formik.touched.description && Boolean(formik.errors.description)}
//           helperText={formik.touched.description && formik.errors.description}
//         />
//         <Typography>Module Thumbnail</Typography>
//         <input
//           accept="image/*"
//           id="thumbnail"
//           type="file"
//           onChange={handleImageChange}
//         />
//         {formik.errors.image && <Typography color="error">{formik.errors.image}</Typography>}
//         {machines.length > 0 && (
//           <>
//             <Typography>Related Machines</Typography>
//             <Autocomplete
//               multiple
//               options={machines}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_machines}
//               onChange={(event, value) => formik.setFieldValue('related_machines', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Machines"
//                   name="related_machines"
//                   error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
//                   helperText={formik.touched.related_machines && formik.errors.related_machines}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {equipments.length > 0 && (
//           <>
//             <Typography>Related Equipments</Typography>
//             <Autocomplete
//               multiple
//               options={equipments}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_equipments}
//               onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Equipments"
//                   name="related_equipments"
//                   error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
//                   helperText={formik.touched.related_equipments && formik.errors.related_equipments}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {inventories.length > 0 && (
//           <>
//             <Typography>Related Inventory</Typography>
//             <Autocomplete
//               multiple
//               options={inventories}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_inventory}
//               onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Inventory"
//                   name="related_inventory"
//                   error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
//                   helperText={formik.touched.related_inventory && formik.errors.related_inventory}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//       </Box>

//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
//         <Button variant="outlined" color="secondary" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button type="submit" variant="contained" color="primary" disabled={uploading}>
//           Save Changes
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default EditModuleForm;





//////////////////////////////////////////////////////////////////////////////////////



// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
// import { useFormik } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import * as Yup from 'yup';
// import AddIcon from '@mui/icons-material/Add';
// import { toggleTutEditMode } from '../../../../redux/slices/tutorialEditSlice';
// import EditVideo from './EditVideo';
// import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
// import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
// import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
// import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs';
// import AdminVideoAPIs from '../../../../utilities/api/tutorials/AdminVideoAPIs';

// const EditModuleForm = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user.user);
//   const currentItem = useSelector((state) => state.tutorialEdit.tutCurrentItem);

//   const [videoIds, setVideoIds] = useState([]);
//   const [isFormFilled, setIsFormFilled] = useState(false);
//   const [uploading, setUploading] = useState(false);

//   const [machines, setMachines] = useState([]);
//   const [equipments, setEquipments] = useState([]);
//   const [inventories, setInventories] = useState([]);

//   useEffect(() => {
//     if (currentItem && currentItem.videos) {
//       const fetchVideos = async () => {
//         try {
//           const videos = await Promise.all(currentItem.videos.map(async (video) => {
//             const videoData = await AdminVideoAPIs.VideoDetailGet(video.video);
//             return {
//               id: videoData.id,
//               title: videoData.title || '',
//               description: videoData.description || '',
//               link: videoData.link || '',
//             };
//           }));
//           setVideoIds(videos);
//         } catch (error) {
//           console.error('Failed to fetch video details', error);
//         }
//       };
//       fetchVideos();
//     }
//   }, [currentItem]);

//   const validationSchema = Yup.object({
//     name: Yup.string().required('Module Name is required'),
//     description: Yup.string().required('Module Description is required'),
//     related_machines: Yup.array().required('Related Machines is required'),
//     related_equipments: Yup.array().required('Related Equipments is required'),
//     related_inventory: Yup.array().required('Related Inventory is required'),
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: currentItem?.name || '',
//       description: currentItem?.description || '',
//       related_machines: [],
//       related_equipments: [],
//       related_inventory: [],
//     },
//     enableReinitialize: true,
//     validationSchema: validationSchema,
//     onSubmit: async (values, { resetForm }) => {
//       const formattedValues = {
//         ...values,
//         related_machines: values.related_machines.map((machine) => machine.id),
//         related_equipments: values.related_equipments.map((equipment) => equipment.id),
//         related_inventory: values.related_inventory.map((inventory) => inventory.id),
//         videos: videoIds.map((video, index) => ({ video: video.id, order: index + 1 })),
//       };

//       try {
//         // Update module
//         await AdminModuleAPIs.UpdateModule(currentItem.id, formattedValues);

//         // Update videos
//         const videoPromises = videoIds.map((video) => {
//           return AdminVideoAPIs.UpdateVideo(video.id, {
//             id: video.id,
//             title: video.title,
//             description: video.description,
//             link: video.link,
//             college: currentItem.college,
//           });
//         });
//         await Promise.all(videoPromises);

//         console.log('Module and videos updated successfully');
//         dispatch(toggleTutEditMode());
//         resetForm();
//       } catch (error) {
//         console.error('Error updating module and videos:', error);
//       }
//     },
//   });

//   useEffect(() => {
//     const { name, description } = formik.values;
//     const formFilled = name && description;
//     setIsFormFilled(formFilled);
//   }, [formik.values]);

//   useEffect(() => {
//     const fetchMachines = async () => {
//       try {
//         const machines = await MachineListAPIs.MachineListGet();
//         setMachines(machines || []);
//         if (currentItem) {
//           formik.setFieldValue(
//             'related_machines',
//             currentItem.related_machines.map((id) => machines.find((machine) => machine.id === id))
//           );
//         }
//       } catch (error) {
//         console.error('Failed to fetch machines', error);
//       }
//     };

//     const fetchEquipments = async () => {
//       try {
//         const equipments = await EquipmentListAPIs.EquipmentListGet();
//         setEquipments(equipments || []);
//         if (currentItem) {
//           formik.setFieldValue(
//             'related_equipments',
//             currentItem.related_equipments.map((id) => equipments.find((equipment) => equipment.id === id))
//           );
//         }
//       } catch (error) {
//         console.error('Failed to fetch equipments', error);
//       }
//     };

//     const fetchInventories = async () => {
//       try {
//         const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
//         setInventories(inventories || []);
//         if (currentItem) {
//           formik.setFieldValue(
//             'related_inventory',
//             currentItem.related_inventory.map((id) => inventories.find((inventory) => inventory.id === id))
//           );
//         }
//       } catch (error) {
//         console.error('Failed to fetch inventories', error);
//       }
//     };

//     fetchMachines();
//     fetchEquipments();
//     fetchInventories();
//   }, [user.refresh, user.access, currentItem]);

//   const handleCancel = () => {
//     const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
//     if (userConfirmed) {
//       dispatch(toggleTutEditMode());
//     }
//   };

//   const addVideoComponent = () => {
//     setVideoIds([...videoIds, { id: videoIds.length, title: '', description: '', link: '' }]);
//   };

//   const removeVideoComponent = (id) => {
//     setVideoIds(videoIds.filter((video) => video.id !== id));
//   };

//   const handleVideoChange = (id, field, value) => {
//     setVideoIds((prevVideoIds) =>
//       prevVideoIds.map((video) => (video.id === id ? { ...video, [field]: value } : video))
//     );
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={formik.handleSubmit}
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         width: '95%',
//         margin: '0 auto',
//         padding: 2,
//         position: 'relative',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Edit Module
//       </Typography>

//       <Box>
//         <Box>
//           {videoIds.map((video) => (
//             <EditVideo
//               key={video.id}
//               video={video}
//               onRemove={() => removeVideoComponent(video.id)}
//               onChange={handleVideoChange}
//             />
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button variant="outlined" color="primary" onClick={addVideoComponent}>
//             <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
//             Add Video
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <Typography>Module Name</Typography>
//         <TextField
//           label="Module Name"
//           name="name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.name && Boolean(formik.errors.name)}
//           helperText={formik.touched.name && formik.errors.name}
//         />
//         <Typography>Module Description</Typography>
//         <TextField
//           label="Module Description"
//           name="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           multiline
//           rows={4}
//           error={formik.touched.description && Boolean(formik.errors.description)}
//           helperText={formik.touched.description && formik.errors.description}
//         />
//         {machines.length > 0 && (
//           <>
//             <Typography>Related Machines</Typography>
//             <Autocomplete
//               multiple
//               options={machines}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_machines}
//               onChange={(event, value) => formik.setFieldValue('related_machines', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Machines"
//                   name="related_machines"
//                   error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
//                   helperText={formik.touched.related_machines && formik.errors.related_machines}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {equipments.length > 0 && (
//           <>
//             <Typography>Related Equipments</Typography>
//             <Autocomplete
//               multiple
//               options={equipments}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_equipments}
//               onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Equipments"
//                   name="related_equipments"
//                   error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
//                   helperText={formik.touched.related_equipments && formik.errors.related_equipments}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {inventories.length > 0 && (
//           <>
//             <Typography>Related Inventory</Typography>
//             <Autocomplete
//               multiple
//               options={inventories}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_inventory}
//               onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Inventory"
//                   name="related_inventory"
//                   error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
//                   helperText={formik.touched.related_inventory && formik.errors.related_inventory}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//       </Box>

//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
//         <Button variant="outlined" color="secondary" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button type="submit" variant="contained" color="primary" disabled={uploading}>
//           Save Changes
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default EditModuleForm;


//////////////////////////////////////////////////////////////////////////////////////////////////////


// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, Typography, Autocomplete } from '@mui/material';
// import { useFormik } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import * as Yup from 'yup';
// import AddIcon from '@mui/icons-material/Add';
// import { toggleTutEditMode } from '../../../../redux/slices/tutorialEditSlice';
// import EditVideo from './EditVideo';
// import MachineListAPIs from '../../../../utilities/api/machine/AdminMachineList';
// import EquipmentListAPIs from '../../../../utilities/api/equipment/AdminEquipmentList';
// import InventoryListAPIs from '../../../../utilities/api/inventory/AdminInventoryList';
// import AdminModuleAPIs from '../../../../utilities/api/tutorials/AdminModuleAPIs'; // Ensure this import is correct

// const EditModuleForm = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user.user);
//   const currentItem = useSelector((state) => state.tutorialEdit.tutCurrentItem); // Access tutCurrentItem

//   const [videoIds, setVideoIds] = useState([]);
//   const [isFormFilled, setIsFormFilled] = useState(false);
//   const [uploading, setUploading] = useState(false);

//   const [machines, setMachines] = useState([]);
//   const [equipments, setEquipments] = useState([]);
//   const [inventories, setInventories] = useState([]);

//   useEffect(() => {
//     if (currentItem && currentItem.videos) {
//       setVideoIds(currentItem.videos.map((video) => video.video));
//     }
//   }, [currentItem]);

//   const validationSchema = Yup.object({
//     name: Yup.string().required('Module Name is required'),
//     description: Yup.string().required('Module Description is required'),
//     related_machines: Yup.array().required('Related Machines is required'),
//     related_equipments: Yup.array().required('Related Equipments is required'),
//     related_inventory: Yup.array().required('Related Inventory is required'),
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: currentItem?.name || '',
//       description: currentItem?.description || '',
//       related_machines: [],
//       related_equipments: [],
//       related_inventory: [],
//     },
//     enableReinitialize: true,
//     validationSchema: validationSchema,
//     onSubmit: async (values, { resetForm }) => {
//       const formattedValues = {
//         ...values,
//         related_machines: values.related_machines.map((machine) => machine.id),
//         related_equipments: values.related_equipments.map((equipment) => equipment.id),
//         related_inventory: values.related_inventory.map((inventory) => inventory.id),
//         videos: videoIds.map((id, index) => ({ video: id, order: index + 1 }))
//       };

//       try {
//         await AdminModuleAPIs.UpdateModule(currentItem.id, formattedValues); // Use currentItem.id
//         console.log('Module updated successfully');
//         dispatch(toggleTutEditMode());
//         resetForm();
//       } catch (error) {
//         console.error('Error updating module:', error);
//       }
//     },
//   });

//   useEffect(() => {
//     const { name, description } = formik.values;
//     const formFilled = name && description;
//     setIsFormFilled(formFilled);
//   }, [formik.values]);

//   useEffect(() => {
//     const fetchMachines = async () => {
//       try {
//         const machines = await MachineListAPIs.MachineListGet();
//         setMachines(machines || []);
//         if (currentItem) {
//           formik.setFieldValue('related_machines', currentItem.related_machines.map(id => machines.find(machine => machine.id === id)));
//         }
//       } catch (error) {
//         console.error('Failed to fetch machines', error);
//       }
//     };

//     const fetchEquipments = async () => {
//       try {
//         const equipments = await EquipmentListAPIs.EquipmentListGet();
//         setEquipments(equipments || []);
//         if (currentItem) {
//           formik.setFieldValue('related_equipments', currentItem.related_equipments.map(id => equipments.find(equipment => equipment.id === id)));
//         }
//       } catch (error) {
//         console.error('Failed to fetch equipments', error);
//       }
//     };

//     const fetchInventories = async () => {
//       try {
//         const inventories = await InventoryListAPIs.InventoryListGet({ refresh_token: user.refresh, access_token: user.access });
//         setInventories(inventories || []);
//         if (currentItem) {
//           formik.setFieldValue('related_inventory', currentItem.related_inventory.map(id => inventories.find(inventory => inventory.id === id)));
//         }
//       } catch (error) {
//         console.error('Failed to fetch inventories', error);
//       }
//     };

//     fetchMachines();
//     fetchEquipments();
//     fetchInventories();
//   }, [user.refresh, user.access, currentItem]);

//   const handleCancel = () => {
//     const userConfirmed = window.confirm('All data will be lost. Are you sure you want to cancel?');
//     if (userConfirmed) {
//       dispatch(toggleTutEditMode());
//     }
//   };

//   const addVideoComponent = () => {
//     setVideoIds([...videoIds, { id: videoIds.length }]);
//   };

//   const removeVideoComponent = (id) => {
//     setVideoIds(videoIds.filter((video) => video.id !== id));
//   };

//   const handleUploadSuccess = (videoId) => {
//     setVideoIds([...videoIds, videoId]);
//   };

//   const handleUploading = (isUploading) => {
//     setUploading(isUploading);
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={formik.handleSubmit}
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         width: '95%',
//         margin: '0 auto',
//         padding: 2,
//         position: 'relative',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Edit Module
//       </Typography>

//       <Box>
//         <Box>
//           {currentItem?.videos?.map((video) => (
//             <EditVideo
//               key={video.video}
//               videoId={video.video}
//               onRemove={() => removeVideoComponent(video.video)}
//               onEditSuccess={handleUploadSuccess}
//               handleUploading={handleUploading}
//             />
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button variant="outlined" color="primary" onClick={addVideoComponent}>
//             <AddIcon sx={{ marginRight: '2px', padding: '0px' }} />
//             Add Video
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//         <Typography>Module Name</Typography>
//         <TextField
//           label="Module Name"
//           name="name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           error={formik.touched.name && Boolean(formik.errors.name)}
//           helperText={formik.touched.name && formik.errors.name}
//         />
//         <Typography>Module Description</Typography>
//         <TextField
//           label="Module Description"
//           name="description"
//           value={formik.values.description}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           fullWidth
//           required
//           multiline
//           rows={4}
//           error={formik.touched.description && Boolean(formik.errors.description)}
//           helperText={formik.touched.description && formik.errors.description}
//         />
//         {machines.length > 0 && (
//           <>
//             <Typography>Related Machines</Typography>
//             <Autocomplete
//               multiple
//               options={machines}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_machines}
//               onChange={(event, value) => formik.setFieldValue('related_machines', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Machines"
//                   name="related_machines"
//                   error={formik.touched.related_machines && Boolean(formik.errors.related_machines)}
//                   helperText={formik.touched.related_machines && formik.errors.related_machines}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {equipments.length > 0 && (
//           <>
//             <Typography>Related Equipments</Typography>
//             <Autocomplete
//               multiple
//               options={equipments}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_equipments}
//               onChange={(event, value) => formik.setFieldValue('related_equipments', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Equipments"
//                   name="related_equipments"
//                   error={formik.touched.related_equipments && Boolean(formik.errors.related_equipments)}
//                   helperText={formik.touched.related_equipments && formik.errors.related_equipments}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//         {inventories.length > 0 && (
//           <>
//             <Typography>Related Inventory</Typography>
//             <Autocomplete
//               multiple
//               options={inventories}
//               getOptionLabel={(option) => option.name}
//               value={formik.values.related_inventory}
//               onChange={(event, value) => formik.setFieldValue('related_inventory', value)}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Related Inventory"
//                   name="related_inventory"
//                   error={formik.touched.related_inventory && Boolean(formik.errors.related_inventory)}
//                   helperText={formik.touched.related_inventory && formik.errors.related_inventory}
//                 />
//               )}
//               disableCloseOnSelect
//             />
//           </>
//         )}
//       </Box>

//       <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
//         <Button variant="outlined" color="secondary" onClick={handleCancel}>
//           Cancel
//         </Button>
//         <Button type="submit" variant="contained" color="primary" disabled={uploading}>
//           Save Changes
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default EditModuleForm;
