import React, { useState, useEffect } from 'react';
import {
  IconButton, Button, Typography, Dialog, DialogActions,
  DialogContent, DialogTitle, Box, useTheme, useMediaQuery
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WorkbenchAPIs from '../../../utilities/api/workbench/WorkbenchAPIs';

const MAX_FILE_SIZE_MB = 2; // Max file size in MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Max file size in bytes

const LayoutImageManager = () => {
  const [layoutImages, setLayoutImages] = useState([]); // List of layout images
  const [openDialog, setOpenDialog] = useState(false); // Dialog visibility
  const [currentImage, setCurrentImage] = useState(null); // Currently displayed image
  const [uploadedImages, setUploadedImages] = useState([]); // Uploaded images
  const [isDragging, setIsDragging] = useState(false); // Drag state
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);

  const user = useSelector(state => state.user.user);

  useEffect(() => {
    fetchLayoutImages();
  }, [user]);

  const fetchLayoutImages = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await WorkbenchAPIs.getLayoutImage();
      if (response && response.length > 0) {
        setLayoutImages(response);
        setCurrentImage(response[response.length - 1]); // Show the last image
      } else {
        setLayoutImages([]);
        setCurrentImage(null);
      }
    } catch (err) {
      console.error("Error fetching layout images:", err);
      setError(err);
      toast.error('Failed to fetch layout images.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddLayoutImage = () => {
    if (layoutImages.length > 0) {
      setCurrentImage(layoutImages[layoutImages.length - 1]);
    } else {
      setCurrentImage(null);
    }
    setOpenDialog(true);  // Open dialog
  };

  const handleDialogClose = () => {
    setOpenDialog(false);  // Close dialog
    setUploadedImages([]); // Clear any selected images
    setIsDragging(false);  // Reset drag state
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);  // Show dragging state
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);  // Hide dragging state
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const files = Array.from(event.dataTransfer.files);
    handleFileUpload(files);
  };

  const handleFileUpload = (files) => {
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    
    // Check if more than one image is being uploaded
    if (imageFiles.length > 1) {
      toast.error('Only one image can be uploaded at a time.');
      return;
    }

    // Check if the file size exceeds the maximum allowed size
    if (imageFiles.length === 1 && imageFiles[0].size > MAX_FILE_SIZE_BYTES) {
      toast.error(`File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`);
      return;
    }

    // Only proceed if all checks pass
    setUploadedImages(imageFiles);  // Replace with the single image
    toast.info('Image selected for upload.');
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    handleFileUpload(files);  // Handle file selection through input
  };

  const handleImageUploadSubmit = async () => {
    if (uploadedImages.length === 0) {
      toast.warn("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    uploadedImages.forEach((file) => {
      formData.append('layout', file);  // Append image file(s)
    });

    try {
      const uploadResponse = await WorkbenchAPIs.uploadLayoutImage(formData);
      console.log("Layout image uploaded successfully:", uploadResponse);

      const updatedLayoutImages = [...layoutImages, uploadResponse];
      setLayoutImages(updatedLayoutImages);
      setCurrentImage(uploadResponse); // Set the newly uploaded image as current

      setUploadedImages([]); // Clear uploaded images
      handleDialogClose(); // Close the dialog
      toast.success("Image uploaded successfully!");
    } catch (error) {
      console.error("Error uploading layout image:", error);
      toast.error("Failed to upload image. Please try again.");
    }
  };

  const handleImageUpdateSubmit = async () => {
    if (uploadedImages.length === 0) {
      toast.warn("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    uploadedImages.forEach((file) => {
      formData.append('layout', file);  // Append image file(s)
    });

    try {
      const uploadResponse = await WorkbenchAPIs.uploadLayoutImage(formData);
      console.log("Layout image uploaded successfully:", uploadResponse);

      const previousImage = layoutImages[layoutImages.length - 1];

      if (previousImage && previousImage.id) {
        await WorkbenchAPIs.deleteLayoutImage(previousImage.id);
        console.log(`Deleted previous layout image with ID: ${previousImage.id}`);
      }

      const updatedLayoutImages = [...layoutImages, uploadResponse];
      setLayoutImages(updatedLayoutImages);
      setCurrentImage(uploadResponse); // Set the newly uploaded image as current

      setUploadedImages([]); // Clear uploaded images
      handleDialogClose(); // Close the dialog
      toast.success("Image updated successfully!");
    } catch (error) {
      console.error("Error updating layout image:", error);
      toast.error("Failed to update image. Please try again.");
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <ToastContainer />
      <IconButton
        variant="contained"
        color="primary"
        sx={{
          ml: 2,
          height: '3rem',
          background: "white",
          color: '#3F3F3F',
          border: '2px solid #B5B5B5',
          borderRadius: '12px'
        }}
        onClick={handleAddLayoutImage}
      >
        <AddIcon sx={{ border: '2px solid #3f3f3f', borderRadius: '50%', mr: 1 }} />
        <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '16px' }}>
          {layoutImages.length > 0 ? 'View Image' : 'Add Layout Image'}
        </Typography>
      </IconButton>

      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>{currentImage ? 'View Layout Image' : 'Upload Layout Image'}</DialogTitle>
        <DialogContent>
          {currentImage ? (
            <Box>
              <img src={currentImage.layout} alt="Layout" style={{ width: '100%', height: 'auto' }} />
              <Box mt={2}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileSelect}
                  style={{ display: 'none' }} 
                  id="image-change-upload"
                />
                <label htmlFor="image-change-upload">
                  <Button variant="contained" component="span">Change Image</Button>
                </label>
              </Box>
            </Box>
          ) : (
            <Box
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              sx={{
                border: '2px dashed #B5B5B5',
                padding: '1rem',
                borderRadius: '10px',
                textAlign: 'center',
                cursor: 'pointer',
                height: '150px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: isDragging ? '#f0f0f0' : 'transparent',
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                style={{ display: 'none' }}  
                id="image-upload"
              />
              <label htmlFor="image-upload">
                {isDragging ? (
                  <Typography>Drop the image here...</Typography>
                ) : (
                  <Typography>Drag & drop an image here, or click to select an image</Typography>
                )}
              </label>
            </Box>
          )}
          <Box sx={{ marginTop: '1rem' }}>
            {uploadedImages.map((file, index) => (
              <Typography key={index}>{file.name}</Typography>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={currentImage ? handleImageUpdateSubmit : handleImageUploadSubmit}
          >
            {currentImage ? 'Update Image' : 'Upload Image'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LayoutImageManager;
