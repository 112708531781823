import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Button,
} from "@mui/material";
import React, { useState } from "react";

const NotificationCard = ({ notifications }) => {
  const [number, setNumber] = useState(4);

  return (
    <Box
      sx={{
        width: "20rem",
        height: "auto",
        background: "blue",
        position: "absolute",
        top: "64px",
        right: "233px",
        zIndex: 10,
        borderRadius: "22px",
        border: "2px solid black",
      }}
    >
      <Box sx={{ borderRadius: 4 }}>
        <Box
          sx={{
            p: 2,
            bgcolor: "Black",
            color: "white",
            borderRadius: "20px 20px 0 0",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: 700, fontSize: "1.5rem" }}
          >
            Notification
            <Typography
              variant="subtitle1"
              sx={{ float: "right", fontWeight: 500, fontSize: "1.2rem" }}
            >
              {notifications.length} new
            </Typography>
          </Typography>
        </Box>
        <Box sx={{ maxHeight: "25rem", overflowY: "auto" }}>
          {notifications.map((notification, index) =>
            index < number ? (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start" sx={{ background: "White" }}>
                  <ListItemAvatar>
                    <Avatar alt="Profile Picture" src={notification.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.message}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {notification.time}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="middle" />
              </React.Fragment>
            ) : null
          )}
        </Box>

        <Box
          sx={{
            textAlign: "center",
            p: 2,
            background: "White",
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button
            size="small"
            onClick={() => setNumber(notifications.length)}
            sx={{ color: "black", fontWeight: "600", fontSize: "1rem" }}
          >
            See all notifications
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationCard;
