import { axiosApiService } from "../axios";

const availabilityMapping = {
  'Moderate': 2,
  'Very Low': 0,
  'Low': 1,
  'High': 3,
  'Always': 4
};

const MachineListAPIs = {
  MachineListGet: async function() {
    try {
      const response = await axiosApiService.get(`/admin_machine_list/`);
      console.log('response', response);
      return response.data;
    } catch (error) {
      console.error('Error fetching machine list:', error);
      // You can choose to return an error response or throw an error
      throw error;
    }
  },

  AddInventory: async function (values, token) {
    try {
      const response = await axiosApiService.post(`/admin_machine_list/`, values, {
        headers: { 
          "Authorization": `Bearer ${token}` // Include the token in the headers
        },
      });
      return response;
    } catch (error) {
      console.error('Error adding Machine:', error);
      // You can choose to return an error response or throw an error
      throw error;
    }
  },
}

export default MachineListAPIs;
