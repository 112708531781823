import React, { useEffect, useState } from 'react';
import {
  Box, InputBase, IconButton, Select, MenuItem, FormControl, OutlinedInput,
  useTheme, useMediaQuery, InputAdornment,InputLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';

import MachineListAPIs from '../../utilities/api/machine/AdminMachineList';
import EquipmentListAPIs from '../../utilities/api/equipment/AdminEquipmentList';
import InventoryListAPIs from '../../utilities/api/inventory/AdminInventoryList';
import LayoutImageManager from './Popups/LayoutImageManager';

const tagsList = ['Free', 'Issue', 'Purchase', 'Exhaustible', 'NonExhaustible', 'InLabOnly', 'HighValue'];

const SearchBar = ({
  activeTab,
  searchQuery,
  setSearchQuery,
  category,
  setCategory,
  group,
  setGroup,
  tag,
  setTag
}) => {
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = useSelector(state => state.user.user);
  const tokens = {
    refresh_token: user.refresh,
    access_token: user.access
  };

  // Effect to handle API calls and reset filters when activeTab changes
  useEffect(() => {
    // Reset filters when the activeTab changes
    setCategory([]);
    setGroup([]);
    setTag([]);

    setLoading(true);
    setError(null);
    let apiCall;
    switch (activeTab) {
      case 0:
        apiCall = MachineListAPIs.MachineListGet();
        break;
      case 1:
        apiCall = EquipmentListAPIs.EquipmentListGet();
        break;
      case 2:
        apiCall = InventoryListAPIs.InventoryListGet(tokens);
        break;
      default:
        break;
    }

    if (apiCall) {
      apiCall.then((result) => {
        const uniqueCategories = Array.from(new Set(result.map(item => item.category))).filter(Boolean);
        setCategories(uniqueCategories);

        const uniqueGroups = Array.from(new Set(result.map(item => item.group))).filter(Boolean);
        setGroups(uniqueGroups);

        const allTags = result.reduce((acc, item) => {
          const tagsArray = [item.tag_1, item.tag_2, item.tag_3, item.tag_4].filter(Boolean);
          return acc.concat(tagsArray);
        }, []);
        const uniqueTags = Array.from(new Set(allTags)).filter(Boolean);
        setTags(uniqueTags);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [activeTab, user, setCategory, setGroup, setTag]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearchSubmit();
    }
  };

  const handleSearchSubmit = () => {
    console.log(`Searching for: ${searchQuery} in category: ${category}`);
    // Implement actual search functionality here
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="form"
      onKeyDown={handleKeyDown}
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: isMobile ? 1 : 0,
        width: '100%',
        padding: theme.spacing(1),
        margin: 'none',
        marginBlock: '1rem',
        boxSizing: 'border-box'
      }}
    >
      <FormControl sx={{ minWidth: 120 }} >
        <InputLabel shrink sx={{ marginLeft: '18px' }}>Select Categories</InputLabel>
        <Select
          value={category}
          onChange={handleCategoryChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ mr: activeTab === 2 ? 20 : 45.5, height: '3rem', width: activeTab === 2 ? '90%' : '50%', ml: '1rem' }}
          multiple
          input={<OutlinedInput label="Select Categories" />}
        >
          {categories.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {activeTab === 2 && (
        <>
          <FormControl sx={{ minWidth: 80 }}>
            <InputLabel shrink sx={{ marginLeft: '18px' }}>Group</InputLabel>
            <Select
              value={group}
              onChange={handleGroupChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mr: 5, height: '3rem', width: '75%', ml: '1rem' }}
              multiple
              input={<OutlinedInput label="Select Groups" />}
            >
              {groups.map((grp) => (
                <MenuItem key={grp} value={grp}>
                  {grp}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 80 }}>
            <InputLabel shrink sx={{ marginLeft: '18px' }}>Tags</InputLabel>
            <Select
              value={tag}
              onChange={handleTagChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mr: 5, height: '3rem', width: '100%', ml: '1rem' }}
              multiple
              input={<OutlinedInput label="Tags" />}
            >
              {tags.map((tg) => (
                <MenuItem key={tg} value={tg}>
                  {tg}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <InputBase
        sx={{
          ml: 50,
          mr: 1,
          flex: 1,
          height: '3rem',
          padding: 'none',
          border: '#B5B5B5 solid 0.5px',
          borderRadius: '10px',
          bgcolor: '#F4F4F4',
          '& .MuiInputBase-input': {
            paddingLeft: '.1rem',
          },
        }}
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        value={searchQuery}
        onChange={handleSearchChange}
        startAdornment={
          <InputAdornment position="start">
            <IconButton
              sx={{ paddingLeft: '10px' }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {activeTab === 3 && (
        <LayoutImageManager /> // Integrate the new component
      )}
    </Box>
  );
};

export default SearchBar;




// import React, { useEffect, useState } from 'react';
// import {
//   Box, InputBase, IconButton, Select, MenuItem, FormControl, InputAdornment,
//   useTheme, useMediaQuery, OutlinedInput, InputLabel, Button, Typography,
//   Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert
// } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import AddIcon from '@mui/icons-material/Add';
// import { useSelector } from 'react-redux';

// import MachineListAPIs from '../../utilities/api/machine/AdminMachineList';
// import EquipmentListAPIs from '../../utilities/api/equipment/AdminEquipmentList';
// import InventoryListAPIs from '../../utilities/api/inventory/AdminInventoryList';
// import WorkbenchAPIs from '../../utilities/api/workbench/WorkbenchAPIs';

// const tagsList = ['Free', 'Issue', 'Purchase', 'Exhaustible', 'NonExhaustible', 'InLabOnly', 'HighValue'];

// const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

// const SearchBar = ({
//   activeTab,
//   searchQuery,
//   setSearchQuery,
//   category,
//   setCategory,
//   group,
//   setGroup,
//   tag,
//   setTag
// }) => {
//   const [categories, setCategories] = useState([]);
//   const [groups, setGroups] = useState([]);
//   const [tags, setTags] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // New state variables for layout images
//   const [layoutImages, setLayoutImages] = useState([]); // List of layout images
//   const [openDialog, setOpenDialog] = useState(false); // Dialog visibility
//   const [currentImage, setCurrentImage] = useState(null); // Currently displayed image
//   const [uploadedImages, setUploadedImages] = useState([]); // Uploaded images
//   const [isDragging, setIsDragging] = useState(false); // Drag state

//   // State for Toast Notifications
//   const [toast, setToast] = useState({
//     open: false,
//     message: '',
//     severity: 'success', // 'success' | 'error' | 'warning' | 'info'
//   });

//   const user = useSelector(state => state.user.user);
//   const tokens = {
//     refresh_token: user.refresh,
//     access_token: user.access
//   };

//   // Effect to handle API calls and reset filters when activeTab changes
//   useEffect(() => {
//     // Reset filters when the activeTab changes
//     setCategory([]);
//     setGroup([]);
//     setTag([]);

//     setLoading(true);
//     setError(null);
//     let apiCall;
//     switch (activeTab) {
//       case 0:
//         apiCall = MachineListAPIs.MachineListGet();
//         break;
//       case 1:
//         apiCall = EquipmentListAPIs.EquipmentListGet();
//         break;
//       case 2:
//         apiCall = InventoryListAPIs.InventoryListGet(tokens);
//         break;
//       case 3:
//         // Fetch layout images list when activeTab is 3
//         WorkbenchAPIs.getLayoutImage()
//           .then(response => {
//             if (response && response.length > 0) {
//               setLayoutImages(response);
//               setCurrentImage(response[response.length - 1]); // Show the last image
//             } else {
//               setLayoutImages([]);
//               setCurrentImage(null);
//             }
//             setLoading(false);
//           })
//           .catch(error => {
//             console.error("Error fetching layout images:", error);
//             setLayoutImages([]);
//             setCurrentImage(null);
//             setLoading(false);
//             setToast({
//               open: true,
//               message: "Failed to fetch layout images.",
//               severity: 'error',
//             });
//           });
//         break;
//       default:
//         break;
//     }

//     if (apiCall) {
//       apiCall.then((result) => {
//         const uniqueCategories = Array.from(new Set(result.map(item => item.category))).filter(Boolean);
//         setCategories(uniqueCategories);

//         const uniqueGroups = Array.from(new Set(result.map(item => item.group))).filter(Boolean);
//         setGroups(uniqueGroups);

//         const allTags = result.reduce((acc, item) => {
//           const tagsArray = [item.tag_1, item.tag_2, item.tag_3, item.tag_4].filter(Boolean);
//           return acc.concat(tagsArray);
//         }, []);
//         const uniqueTags = Array.from(new Set(allTags)).filter(Boolean);
//         setTags(uniqueTags);
//         setLoading(false);
//       })
//       .catch((error) => {
//         setError(error);
//         setLoading(false);
//         setToast({
//           open: true,
//           message: "Failed to fetch data.",
//           severity: 'error',
//         });
//       });
//     }
//   }, [activeTab, user]);

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleCategoryChange = (event) => {
//     setCategory(event.target.value);
//   };

//   const handleGroupChange = (event) => {
//     setGroup(event.target.value);
//   };

//   const handleTagChange = (event) => {
//     setTag(event.target.value);
//   };

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault();
//       handleSearchSubmit();
//     }
//   };

//   const handleSearchSubmit = () => {
//     console.log(`Searching for: ${searchQuery} in category: ${category}`);
//     // Implement actual search functionality here
//   };

//   const handleAddLayoutImage = () => {
//     if (layoutImages.length > 0) {
//       // If images exist, set the current image to the last one
//       setCurrentImage(layoutImages[layoutImages.length - 1]);
//     } else {
//       setCurrentImage(null);
//     }
//     setOpenDialog(true);  // Open dialog
//   };

//   const handleDialogClose = () => {
//     setOpenDialog(false);  // Close dialog
//     setUploadedImages([]); // Clear any selected images
//     setIsDragging(false);  // Reset drag state
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//     setIsDragging(true);  // Show dragging state
//   };

//   const handleDragLeave = (event) => {
//     event.preventDefault();
//     setIsDragging(false);  // Hide dragging state
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     setIsDragging(false);

//     const files = Array.from(event.dataTransfer.files);
//     handleFileUpload(files);
//   };

//   const handleFileUpload = (files) => {
//     if (files.length > 1) {
//       setToast({
//         open: true,
//         message: "Only one image can be uploaded at a time.",
//         severity: 'warning',
//       });
//       return;
//     }

//     const file = files[0];
//     if (file.size > MAX_FILE_SIZE) {
//       setToast({
//         open: true,
//         message: "Image size should not exceed 2MB.",
//         severity: 'warning',
//       });
//       return;
//     }

//     if (!file.type.startsWith('image/')) {
//       setToast({
//         open: true,
//         message: "Only image files are allowed.",
//         severity: 'warning',
//       });
//       return;
//     }

//     setUploadedImages([file]);  // Replace with the new file
//   };

//   const handleFileSelect = (event) => {
//     const files = Array.from(event.target.files);
//     handleFileUpload(files);  // Handle file selection through input
//   };

//   const handleImageUploadSubmit = async () => {
//     if (uploadedImages.length === 0) {
//       setToast({
//         open: true,
//         message: "Please select an image to upload.",
//         severity: 'warning',
//       });
//       return;
//     }

//     const formData = new FormData();
//     formData.append('layout', uploadedImages[0]);  // Append single image

//     try {
//       setToast({
//         open: true,
//         message: "Uploading image...",
//         severity: 'info',
//       });

//       // Upload the new image
//       const uploadResponse = await WorkbenchAPIs.uploadLayoutImage(formData);
//       console.log("Layout image uploaded successfully:", uploadResponse);

//       // Update the layout images list by re-fetching
//       const response = await WorkbenchAPIs.getLayoutImage();
//       if (response && response.length > 0) {
//         setLayoutImages(response);
//         setCurrentImage(response[response.length - 1]); // Show the latest image
//       } else {
//         setLayoutImages([]);
//         setCurrentImage(null);
//       }

//       setUploadedImages([]); // Clear uploaded images
//       handleDialogClose(); // Close the dialog

//       setToast({
//         open: true,
//         message: "Image uploaded successfully.",
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error("Error uploading layout image:", error);
//       setToast({
//         open: true,
//         message: "Failed to upload image. Please try again.",
//         severity: 'error',
//       });
//     }
//   };

//   const handleImageUpdateSubmit = async () => {
//     if (uploadedImages.length === 0) {
//       setToast({
//         open: true,
//         message: "Please select an image to update.",
//         severity: 'warning',
//       });
//       return;
//     }

//     const formData = new FormData();
//     formData.append('layout', uploadedImages[0]);  // Append single image

//     try {
//       setToast({
//         open: true,
//         message: "Updating image...",
//         severity: 'info',
//       });

//       // Upload the new image
//       const uploadResponse = await WorkbenchAPIs.uploadLayoutImage(formData);
//       console.log("Layout image uploaded successfully:", uploadResponse);

//       // Find the previous image (last image before upload)
//       const previousImage = layoutImages[layoutImages.length - 1];

//       // Delete the previous image
//       if (previousImage && previousImage.id) {
//         await WorkbenchAPIs.deleteLayoutImage(previousImage.id);
//         console.log(`Deleted previous layout image with ID: ${previousImage.id}`);
//       }

//       // Update the layout images list by re-fetching
//       const response = await WorkbenchAPIs.getLayoutImage();
//       if (response && response.length > 0) {
//         setLayoutImages(response);
//         setCurrentImage(response[response.length - 1]); // Show the latest image
//       } else {
//         setLayoutImages([]);
//         setCurrentImage(null);
//       }

//       setUploadedImages([]); // Clear uploaded images
//       handleDialogClose(); // Close the dialog

//       setToast({
//         open: true,
//         message: "Image updated successfully.",
//         severity: 'success',
//       });
//     } catch (error) {
//       console.error("Error updating layout image:", error);
//       setToast({
//         open: true,
//         message: "Failed to update image. Please try again.",
//         severity: 'error',
//       });
//     }
//   };

//   const handleToastClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setToast(prev => ({ ...prev, open: false }));
//   };

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   return (
//     <>
//       <Box
//         component="form"
//         onKeyDown={handleKeyDown}
//         sx={{
//           display: 'flex',
//           flexDirection: isMobile ? 'column' : 'row',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           gap: isMobile ? 1 : 0,
//           width: '100%',
//           padding: theme.spacing(1),
//           margin: 'none',
//           marginBlock: '1rem',
//           boxSizing: 'border-box'
//         }}
//       >
//         <FormControl sx={{ minWidth: 120 }} >
//           <InputLabel shrink sx={{ marginLeft: '18px' }}>Select Categories</InputLabel>
//           <Select
//             value={category}
//             onChange={handleCategoryChange}
//             displayEmpty
//             inputProps={{ 'aria-label': 'Without label' }}
//             sx={{ mr: activeTab === 2 ? 20 : 45.5, height: '3rem', width: activeTab === 2 ? '90%' : '50%', ml: '1rem' }}
//             multiple
//             input={<OutlinedInput label="Select Categories" />}
//           >
//             {categories.map((name) => (
//               <MenuItem key={name} value={name}>
//                 {name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {activeTab === 2 && (
//           <>
//             <FormControl sx={{ minWidth: 80 }}>
//               <InputLabel shrink sx={{ marginLeft: '18px' }}>Group</InputLabel>
//               <Select
//                 value={group}
//                 onChange={handleGroupChange}
//                 displayEmpty
//                 inputProps={{ 'aria-label': 'Without label' }}
//                 sx={{ mr: 5, height: '3rem', width: '75%', ml: '1rem' }}
//                 multiple
//                 input={<OutlinedInput label="Select Groups" />}
//               >
//                 {groups.map((grp) => (
//                   <MenuItem key={grp} value={grp}>
//                     {grp}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <FormControl sx={{ minWidth: 80 }}>
//               <InputLabel shrink sx={{ marginLeft: '18px' }}>Tags</InputLabel>
//               <Select
//                 value={tag}
//                 onChange={handleTagChange}
//                 displayEmpty
//                 inputProps={{ 'aria-label': 'Without label' }}
//                 sx={{ mr: 5, height: '3rem', width: '100%', ml: '1rem' }}
//                 multiple
//                 input={<OutlinedInput label="Tags" />}
//               >
//                 {tags.map((tg) => (
//                   <MenuItem key={tg} value={tg}>
//                     {tg}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </>
//         )}
//         <InputBase
//           sx={{
//             ml: 50,
//             mr: 1,
//             flex: 1,
//             height: '3rem',
//             padding: 'none',
//             border: '#B5B5B5 solid 0.5px',
//             borderRadius: '10px',
//             bgcolor: '#F4F4F4',
//             '& .MuiInputBase-input': {
//               paddingLeft: '.1rem',
//             },
//           }}
//           placeholder="Search…"
//           inputProps={{ 'aria-label': 'search' }}
//           value={searchQuery}
//           onChange={handleSearchChange}
//           startAdornment={
//             <InputAdornment position="start">
//               <IconButton
//                 sx={{ paddingLeft: '10px' }}
//                 aria-label="search"
//               >
//                 <SearchIcon />
//               </IconButton>
//             </InputAdornment>
//           }
//         />
//         {activeTab === 3 && (
//           <IconButton
//             variant="contained"
//             color="primary"
//             sx={{
//               ml: 2,
//               height: '3rem',
//               background: "white",
//               color: '#3F3F3F',
//               border: '2px solid #B5B5B5',
//               borderRadius: '12px'
//             }}
//             onClick={handleAddLayoutImage}
//           >
//             <AddIcon sx={{ border: '2px solid #3f3f3f', borderRadius: '50%', mr: 1 }} />
//             <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '16px' }}>
//               {layoutImages.length > 0 ? 'View Image' : 'Add Layout Image'}
//             </Typography>
//           </IconButton>
//         )}

//         {/* Dialog for Viewing or Uploading the Image */}
//         <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
//           <DialogTitle>{currentImage ? 'View Layout Image' : 'Upload Layout Image'}</DialogTitle>
//           <DialogContent>
//             {currentImage ? (
//               <Box>
//                 {/* Show the current image */}
//                 <img src={currentImage.layout} alt="Layout" style={{ width: '100%', height: 'auto' }} />
                
//                 {/* Option to change the image */}
//                 <Box mt={2}>
//                   <input
//                     type="file"
//                     accept="image/*"
//                     multiple={false}
//                     onChange={handleFileSelect}
//                     style={{ display: 'none' }}  // Hidden file input for clicking to upload
//                     id="image-change-upload"
//                   />
//                   <label htmlFor="image-change-upload" style={{ cursor: 'pointer' }}>
//                     <Button variant="contained" component="span">Change Image</Button>
//                   </label>
//                 </Box>
//               </Box>
//             ) : (
//               <Box
//                 onDragOver={handleDragOver}
//                 onDragLeave={handleDragLeave}
//                 onDrop={handleDrop}
//                 sx={{
//                   border: '2px dashed #B5B5B5',
//                   padding: '1rem',
//                   borderRadius: '10px',
//                   textAlign: 'center',
//                   cursor: 'pointer',
//                   height: '150px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   bgcolor: isDragging ? '#f0f0f0' : 'transparent',
//                 }}
//                 onClick={() => document.getElementById('image-upload').click()} // Make entire box clickable
//               >
//                 <input
//                   type="file"
//                   accept="image/*"
//                   multiple={false} // Allow only single file selection
//                   onChange={handleFileSelect}
//                   style={{ display: 'none' }}  // Hidden file input for clicking to upload
//                   id="image-upload"
//                 />
//                 <label htmlFor="image-upload" style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
//                   {isDragging ? (
//                     <Typography>Drop the image here...</Typography>
//                   ) : (
//                     <Typography>Drag & drop an image here, or click to select an image</Typography>
//                   )}
//                 </label>
//               </Box>
//             )}
//             <Box sx={{ marginTop: '1rem' }}>
//               {uploadedImages.map((file, index) => (
//                 <Typography key={index}>{file.name}</Typography>
//               ))}
//             </Box>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleDialogClose}>Cancel</Button>
//             <Button
//               variant="contained"
//               onClick={currentImage ? handleImageUpdateSubmit : handleImageUploadSubmit}
//             >
//               {currentImage ? 'Update Image' : 'Upload Image'}
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>

//       {/* Snackbar for Toast Notifications */}
//       <Snackbar
//         open={toast.open}
//         autoHideDuration={6000}
//         onClose={handleToastClose}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//       >
//         <Alert onClose={handleToastClose} severity={toast.severity} sx={{ width: '100%' }}>
//           {toast.message}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// };

// export default SearchBar;
