import axios from 'axios';
import { store } from '../../redux/store';
import { getUserFail, logoutUser, updateTokens } from '../../redux/slices/userSlice';

const axiosAuthService = axios.create({
    baseURL: 'https://sys.staging.mis.mapit.ai/api',
});

const axiosApiService = axios.create({
    baseURL: 'https://sys.staging.mis.mapit.ai/api',
});

const ImageBaseURL = '';

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
    refreshSubscribers.push(callback);
};

const onRefreshed = (newAccessToken) => {
    refreshSubscribers.forEach((callback) => callback(newAccessToken));
    refreshSubscribers = [];
};

const refreshToken = async (refreshToken) => {
    try {
        const response = await axiosAuthService.post('/auth/refresh/', { refresh: refreshToken });
        return response.data;
    } catch (error) {
        console.error('Error refreshing token:', error.response?.data || error.message);
        throw error;
    }
};

const setupInterceptors = () => {
    const requestInterceptor = (config) => {
        const state = store.getState();
        const user = state.user.user;

        if (user?.access) {
            config.headers.Authorization = `Bearer ${user.access}`;
        }

        if (user?.user?.TL_name && !config.headers.TL) {
            config.headers.TL = user.user.TL_name;
        }

        return config;
    };

    //// New Code to update the token values with new implementation in userSlice
    const responseErrorInterceptor = async (error) => {
        const originalRequest = error.config;
        console.log('Error:', error);
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (originalRequest.url.includes('refresh/')) {
                // If the refresh token request failed with 401, logout
                store.dispatch(logoutUser());
                return Promise.reject(error);
            }
    
            if (!isRefreshing) {
                isRefreshing = true;
                const state = store.getState();
                const user = state.user.user;
    
                try {
                    const newTokens = await refreshToken(user.refresh);                    // Dispatch updateTokens to update only access and refresh tokens
                    store.dispatch(updateTokens({ access: newTokens.access, refresh: user.refresh }));
                    onRefreshed(newTokens.access);
                    isRefreshing = false;
                } catch (refreshError) {
                    isRefreshing = false;
                    store.dispatch(getUserFail(refreshError.response?.data || refreshError.message));
                    refreshSubscribers = [];
                    return Promise.reject(refreshError);
                }
            }
    
            return new Promise((resolve) => {
                subscribeTokenRefresh((newAccessToken) => {
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    resolve(axios(originalRequest));
                });
            });
        }
    
        return Promise.reject(error);
    };
    

    axiosAuthService.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
    axiosAuthService.interceptors.response.use(
        (response) => response,
        responseErrorInterceptor
    );

    axiosApiService.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
    axiosApiService.interceptors.response.use(
        (response) => response,
        responseErrorInterceptor
    );
};

// Set up the interceptors
setupInterceptors();

// Correctly export ImageBaseURL along with the services
export default axiosAuthService;
export { axiosApiService, ImageBaseURL }; // Ensure ImageBaseURL is exported
