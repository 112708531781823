// src/features/categorySlice.js
import { createSlice } from '@reduxjs/toolkit';

const categorySlice = createSlice({
    name: 'category',
    initialState: { category_list: [] },
    reducers: {
        getCategorySuccess: (state, action) => {
            state.category_list = action.payload;
        },
        getCategoryFail: (state, action) => {
            state.error = action.payload;
        },
        updateCategory: (state, action) => {
            state.category_list = action.payload;
        },
    },
});

export const { getCategorySuccess, getCategoryFail, updateCategory } = categorySlice.actions;
export default categorySlice;