import React, { useEffect, useRef } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import Chart from 'chart.js/auto';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const WeeklyStatisticsChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (chartInstance.current) {
        try {
          chartInstance.current.resize();
        } catch (error) {
          console.error("ResizeObserver error:", error);
        }
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (data.weeks.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const maxBorrowed = Math.max(...data.borrowed);
      const maxReserved = Math.max(...data.reserved);
      const maxValue = Math.max(maxBorrowed, maxReserved);
      const suggestedMax = Math.ceil(maxValue * 1.2);

      chartInstance.current = new Chart(chartRef.current, {
        type: 'line',
        data: {
          labels: data.weeks,
          datasets: [
            {
              label: 'Inventory borrowed',
              data: data.borrowed,
              borderColor: 'orange',
              fill: false,
            },
            {
              label: 'Machines reserved',
              data: data.reserved,
              borderColor: 'blue',
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Week',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Items',
              },
              beginAtZero: true,
              ticks: {
                stepSize: 1, // Ensure the scale uses integers
              },
              suggestedMax: suggestedMax, // Set the maximum value of the axis
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <Paper elevation={3} style={{ padding: '10px', borderRadius: '16px', backgroundColor: '#f9fafe', border: '1px solid #b5b5b5', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box style={{ alignSelf: 'flex-start', marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" style={{ fontSize: '25px', fontWeight: '700', color: '#212121' }}>Weekly statistics</Typography>
      </Box>
      {data.weeks.length > 0 ? (
        <Box style={{ width: '100%', height: '250px', position: 'relative' }}>
          <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
          <Typography variant='h5' color="textSecondary">
            No data available
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default WeeklyStatisticsChart;
