import { createSlice } from '@reduxjs/toolkit';

// Initial state for the edit mode slice
const initialState = {
  editMode: false,
  hasReturned: false, // Parameter to track if user has returned
  currentItem: null, // Parameter to track the current item
  isNewWorkshop: false, // Parameter to check whether 'Create Workshop' is clicked
};

// Create slice using Redux Toolkit createSlice
const editModeSlice = createSlice({
  name: 'editMode', // Name of the slice
  initialState,    // Initial state
  reducers: {
    // Toggle edit mode action
    toggleEditMode(state) {
      state.editMode = !state.editMode;  // Toggles the editMode state
    },
    // Set edit mode action with payload
    setEditMode(state, action) {
      state.editMode = action.payload;   // Sets the editMode state to the payload value
    },
    // Set has returned action with payload
    setHasReturned(state, action) {
      state.hasReturned = action.payload;   // Sets the hasReturned state to the payload value
    },
    // Set current item action with payload
    setCurrentItem(state, action) {
      state.currentItem = action.payload;   // Sets the currentItem state to the payload value
    },
    // Update current item action with payload
    updateCurrentItem(state, action) {
      const updatedItem = action.payload;
      // Find and update the currentItem in the state
      state.currentItem = { ...state.currentItem, ...updatedItem };
    },
    // Set isNewWorkshop action with payload
    setIsNewWorkshop(state, action) {
      console.log('setIsNewWorkshop',state,action);
      state.isNewWorkshop = action.payload; // Sets the isNewWorkshop state to the payload value
    },
  },
});

// Export actions
export const { toggleEditMode, setEditMode, setHasReturned, setCurrentItem, updateCurrentItem, setIsNewWorkshop } = editModeSlice.actions;

// Export reducer
export default editModeSlice.reducer;



// import { createSlice } from '@reduxjs/toolkit';

// // Initial state for the edit mode slice
// const initialState = {
//   editMode: false,
//   hasReturned: false, // New parameter to track if user has returned
//   currentItem: null, // Parameter to track the current item
//   isNewWorkshop: false, // Parameter to check whether 'Create Workshop' is clicked
// };

// // Create slice using Redux Toolkit createSlice
// const editModeSlice = createSlice({
//   name: 'editMode', // Name of the slice
//   initialState,    // Initial state
//   reducers: {
//     // Toggle edit mode action
//     toggleEditMode(state) {
//       state.editMode = !state.editMode;  // Toggles the editMode state
//     },
//     // Set edit mode action with payload
//     setEditMode(state, action) {
//       state.editMode = action.payload;   // Sets the editMode state to the payload value
//     },
//     // Set has returned action with payload
//     setHasReturned(state, action) {
//       state.hasReturned = action.payload;   // Sets the hasReturned state to the payload value
//     },
//     // Set current item action with payload
//     setCurrentItem(state, action) {
//       state.currentItem = action.payload;   // Sets the currentItem state to the payload value
//     },
//     // Update current item action with payload
//     updateCurrentItem(state, action) {
//       const updatedItem = action.payload;
//       // Find and update the currentItem in the state
//       state.currentItem = { ...state.currentItem, ...updatedItem };
//     },
//   },
// });

// // Export actions
// export const { toggleEditMode, setEditMode, setHasReturned, setCurrentItem, updateCurrentItem } = editModeSlice.actions;

// // Export reducer
// export default editModeSlice.reducer;




// import { createSlice } from '@reduxjs/toolkit';

// // Initial state for the edit mode slice
// const initialState = {
//   editMode: false,
//   hasReturned: false, // New parameter to track if user has returned
//   currentItem: null, // Parameter to track the current item
// };

// // Create slice using Redux Toolkit createSlice
// const editModeSlice = createSlice({
//   name: 'editMode', // Name of the slice
//   initialState,    // Initial state
//   reducers: {
//     // Toggle edit mode action
//     toggleEditMode(state) {
//       state.editMode = !state.editMode;  // Toggles the editMode state
//     },
//     // Set edit mode action with payload
//     setEditMode(state, action) {
//       state.editMode = action.payload;   // Sets the editMode state to the payload value
//     },
//     // Set has returned action with payload
//     setHasReturned(state, action) {
//       state.hasReturned = action.payload;   // Sets the hasReturned state to the payload value
//     },
//     // Set current item action with payload
//     setCurrentItem(state, action) {
//       state.currentItem = action.payload;   // Sets the currentItem state to the payload value
//     },
//   },
// });

// // Export actions
// export const { toggleEditMode, setEditMode, setHasReturned, setCurrentItem } = editModeSlice.actions;

// // Export reducer
// export default editModeSlice.reducer;






// import { createSlice } from '@reduxjs/toolkit';

// // Initial state for the edit mode slice
// const initialState = {
//   editMode: false,
//   hasReturned: false, // New parameter to track if user has returned
// };

// // Create slice using Redux Toolkit createSlice
// const editModeSlice = createSlice({
//   name: 'editMode', // Name of the slice
//   initialState,    // Initial state
//   reducers: {
//     // Toggle edit mode action
//     toggleEditMode(state) {
//       state.editMode = !state.editMode;  // Toggles the editMode state
//     },
//     // Set edit mode action with payload
//     setEditMode(state, action) {
//       state.editMode = action.payload;   // Sets the editMode state to the payload value
//     },
//     // Set has returned action with payload
//     setHasReturned(state, action) {
//       state.hasReturned = action.payload;   // Sets the hasReturned state to the payload value
//     },
//   },
// });

// // Export actions
// export const { toggleEditMode, setEditMode, setHasReturned } = editModeSlice.actions;

// // Export reducer
// export default editModeSlice.reducer;