import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // styled,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
  getDay,
  getDate,
  getMonth,
  getYear,
} from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import WeeksData from "./weeks";
import MonthsData from "./months";
import DaysData from "./days";
import PlacesAPI from "../../utilities/api/placeApi";
import AddReservationDialog from "./popups/AddReservation";
import DatePickerDialog from "./popups/datePicker";
import { styled } from "@mui/system"; 

import TimeAxis from "./TimeAxis"; // Import the TimeAxis component
// import DatePickerDialog from "../Inventory/Popups/DatePicker";
// import DatePickerDialog from "./popups/datePicker";
// import AddReservationDialog from "./popups/AddReservation";
// import { useNavigate } from "react-router-dom";
// import WeeksData from "./weeks";
// import MonthsData from "./months";
// import DaysData from "./days";
// import { useSelector } from "react-redux";
// import PlacesAPI from "../../utilities/api/placeApi";

const times = [
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
];
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  minWidth: 120,
  "& .MuiInputLabel-root": {
    color: "#666",
    fontWeight: 500,
  },
  "& .MuiInputBase-root": {
    borderRadius: 8,
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
    },
  },
  "& .MuiSelect-select": {
    padding: theme.spacing(1.5),
  },
}));
const addReservation = {
  background: "#1976d2",
  color: "#F4F4F4",
  borderRadius: "15px",
  fontSize: "17px",
  fontWeight: 400,
  fontFamily: "Segoe UI",
};

// function CalendarPage({ collapsed, setCollapsed, }) {
//   const [reservationList, setReservationList] = useState([]);
//   const [reservationConstList, setReservationConstList] = useState([]);

function CalendarPage({ collapsed, setCollapsed, machineId = null }) {
  const [reservationList, setReservationList] = useState([]);
  const [reservationConstList, setReservationConstList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDialog, setOpenDialog] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [type, setType] = useState("Weeks");
  const { user } = useSelector((state) => state.user);
  const [selectMachineName, setSelectMachineName] = useState("All");
  const [selectMachineList, setSelectMachineList] = useState([]);
  const [selectProjectName, setSelectProjectName] = useState("All");
  const [selectProjectList, setSelectProjectList] = useState([]);
  // You would have state here for reservations and a function to add them.

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await PlacesAPI.ReservationListGet();
        console.log("response is ",response);
        setReservationList(response);
  
        const reservationNames = [
          ...new Set(response.map((reservation) => reservation.name)),
        ];
        console.log(
          "Response of the user for a particular machine",
          reservationNames
        );
        setSelectMachineList(reservationNames);
  
        const reservationProjectNames = [
          ...new Set(response.map((reservation) => reservation.project_name)),
        ];
        console.log(
          "Response of the user for a particular machine",
          reservationProjectNames
        );
        setSelectProjectList(reservationProjectNames);
        setReservationConstList(response);
        console.log(reservationConstList, " - list")
      } catch (error) {
        console.error('Error Fetching reservations:', error);
      }
    };
    fetchReservations();
  },[]);
  


useEffect(() => {
      const list = reservationConstList;

      if(selectMachineName!=="All"){
        const filteredResponse = list.filter((value) => value.name===selectMachineName);
        console.log("Filtered selected machine ",filteredResponse);
        console.log("list ",list);
        setReservationList(filteredResponse);

        if(selectProjectName!=="All"){
          const filteredResponseProject = filteredResponse.filter((value) => value.project_name===selectProjectName)
          console.log("Filtered selected project ",filteredResponseProject);
          setReservationList(filteredResponseProject);
        }
      }
      else{
        setReservationList(list);
        if(selectProjectName!=="All"){
          const filteredResponseProject = list.filter((value) => value.project_name===selectProjectName)
          console.log("Filtered selected project ",filteredResponseProject);
          setReservationList(filteredResponseProject);
        }
      }
  },[selectMachineName, selectProjectName]);


  const handleMachineSelect = (val)=>{
    setSelectMachineList(val);
  }
  const handleProjectSelect = (val)=>{
    setSelectProjectList(val);
  }
  const navigate = useNavigate();
  const handleExit = () => {
    // setCollapsed()
    navigate("/inventory");
  };
  // const handleDateChange = (newDate) => {
  //   setSelectedDate(newDate);
  // };
  const days = eachDayOfInterval({
    start: startOfWeek(selectedDate),
    end: endOfWeek(selectedDate),
  });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleToggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  // Add the reservation logic here
  const handleAddReservation = () => {
    setOpenDialog(true);
    handleOpenDialog();
    // Implement the functionality to add a reservation to your state or backend
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 24px",
            gap: "8px",
          }}
        >
          <IconButton
            size="large"
            sx={{
              background: "#E6F2FF",
              marginRight: "12px",
              borderRadius: "15px",
            }}
          >
            <CalendarTodayIcon sx={{ color: "#212121" }} />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, color: "#212121", fontWeight: 500 }}
          >
            Calendar
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
            {/* <Button
              // startIcon={<AddIcon />}
              variant="contained"
              sx={addReservation}
              onClick={handleAddReservation}
            >
              Add Reservation
            </Button> */}
            <IconButton
              onClick={handleExit}
              size="large"
              sx={{ background: "#FFE5E5" }}
            >
              <CloseIcon
                sx={{
                  color: "#C90505",
                  border: "2px solid red",
                  borderRadius: "15px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
            }}
          >
            {/* Select Machine */}
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel id="select-machine-label">Select Machine</InputLabel>
              <Select
                labelId="select-machine-label"
                id="selectMachine"
                name="selectMachine"
                value={selectMachineName}
                label="Select Machine"
                onChange={(e) => setSelectMachineName(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                {selectMachineList.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Select Project */}
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel id="select-project-label">Select Project</InputLabel>
              <Select
                labelId="select-project-label"
                id="selectProject"
                name="selectProject"
                value={selectProjectName}
                label="Select Project"
                onChange={(e) => setSelectProjectName(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                {selectProjectList.map((option, index) => (
                  <MenuItem key={index} value={option ? option : "Null"}>
                    {option ? option : "Null"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {type === "Weeks" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <IconButton
                  onClick={() =>
                    setSelectedDate(
                      (prevDate) =>
                        new Date(
                          prevDate.getFullYear(),
                          prevDate.getMonth(),
                          prevDate.getDate() - 7
                        )
                    )
                  }
                >
                  <ChevronLeftIcon sx={{ color: "#212121" }} />
                </IconButton>
                <Typography sx={{ color: "#3F3F3F", fontWeight: "bold" }}>
                  {getDate(startOfWeek(selectedDate))}
                  {" "}
                  {format(startOfWeek(selectedDate), 'LLLL')}
                  {" "}
                  {getYear(startOfWeek(selectedDate))}
                </Typography>
                <IconButton
                  onClick={() =>
                    setSelectedDate(
                      (prevDate) =>
                        new Date(
                          prevDate.getFullYear(),
                          prevDate.getMonth(),
                          prevDate.getDate() + 7
                        )
                    )
                  }
                >
                  <ChevronRightIcon sx={{ color: "#212121" }} />
                </IconButton>
              </Box>
            ) : type === "Weeks" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <IconButton
                  onClick={() =>
                    setSelectedDate(
                      (prevDate) =>
                        new Date(
                          prevDate.getFullYear(),
                          prevDate.getMonth(),
                          prevDate.getDate() - 7
                        )
                    )
                  }
                >
                  <ChevronLeftIcon sx={{ color: "#212121" }} />
                </IconButton>
                <Typography sx={{ color: "#3F3F3F", fontWeight: "bold" }}>
                  {format(selectedDate, "dd MMMM yyyy")}
                </Typography>
                <IconButton
                  onClick={() =>
                    setSelectedDate(
                      (prevDate) =>
                        new Date(
                          prevDate.getFullYear(),
                          prevDate.getMonth(),
                          prevDate.getDate() + 7
                        )
                    )
                  }
                >
                  <ChevronRightIcon sx={{ color: "#212121" }} />
                </IconButton>
              </Box>
            ) : type === "Months" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <IconButton
                  onClick={() =>
                    setSelectedDate(
                      (prevDate) =>
                        new Date(
                          prevDate.getFullYear(),
                          prevDate.getMonth() - 1,
                          prevDate.getDate()
                        )
                    )
                  }
                >
                  <ChevronLeftIcon sx={{ color: "#212121" }} />
                </IconButton>
                <Typography sx={{ color: "#3F3F3F", fontWeight: "bold" }}>
                  {format(selectedDate, "LLLL")}
                </Typography>
                <IconButton
                  onClick={() =>
                    setSelectedDate(
                      (prevDate) =>
                        new Date(
                          prevDate.getFullYear(),
                          prevDate.getMonth() + 1,
                          prevDate.getDate()
                        )
                    )
                  }
                >
                  <ChevronRightIcon sx={{ color: "#212121" }} />
                </IconButton>
              </Box>
            ) : (
              " "
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginInline: "1rem",
                textDecoration: type === "Months" ? "underline" : "none",
                fontWeight: type === "Months" ? 600 : "normal",
              }}
              onClick={() => setType("Months")}
            >
              Months
            </Typography>
            <Typography
              sx={{
                marginInline: "1rem",
                textDecoration: type === "Weeks" ? "underline" : "none",
                fontWeight: type === "Weeks" ? 600 : "normal",
              }}
              onClick={() => setType("Weeks")}
            >
              Weeks
            </Typography>
            <Typography
              sx={{
                marginInline: "1rem",
                textDecoration: type === "Days" ? "underline" : "none",
                fontWeight: type === "Days" ? 600 : "normal",
              }}
              onClick={() => setType("Days")}
            >
              Days
            </Typography>
          </Box>
        </Box>
        {type === "Months" ? (
          <MonthsData
            reservationList={reservationList}
            selectedDate={selectedDate}
            handleMachineSelect={handleMachineSelect}
            handleProjectSelect={handleProjectSelect}
          />
        ) : type === "Weeks" ? (
          <WeeksData
            reservationList={reservationList}
            selectedDate={selectedDate}
            handleMachineSelect={handleMachineSelect}
            handleProjectSelect={handleProjectSelect}
            setSelectedDate={setSelectedDate}
          />
        ) : (
          <DaysData
            selectedDate={selectedDate}
            reservationList={reservationList}
            handleMachineSelect={handleMachineSelect}
            handleProjectSelect={handleProjectSelect}
          />
        )}
      </Paper>
      <AddReservationDialog open={openDialog} onClose={handleCloseDialog} />
      {/* <DatePickerDialog
        open={calendarOpen}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        handleToggleCalendar={handleToggleCalendar}
      /> */}
    </LocalizationProvider>
  );
}

export default CalendarPage;
