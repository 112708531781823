import React, { useState } from "react";
import { Box, Typography, Container, Button } from "@mui/material";
import Logo from "../../utilities/dummy_assets/MBF_Logo.png";
import mapITlogo from "../../utilities/dummy_assets/mapITlogo.png";
import AdminRegistrationForm from "../../components/admin/register/AdminRegistrationForm";
import OrganizationalRegistrationForm from "../../components/admin/register/OrganizationalRegistrationForm";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [bgAdmin, setBgAdmin] = useState("#212121");
  const [bgOrgAdmin, setBgOrgAdmin] = useState("212121");

  const handleAdminClick = () => {
    setBgOrgAdmin("white");
    setBgAdmin("#212121");
  };

  const handleOrgAdminClick = () => {
    setBgOrgAdmin("#212121");
    setBgAdmin("white");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#F7F7F7",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {/* Top Left Logo */}
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          left: "50px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={Logo} alt="MBF Logo" style={{ height: "69px", width: "159px" }} />
      </Box>

      {/* Top Right Link */}
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          right: "50px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          component="span"
          onClick={() => window.location.href = "https://tinkerspace.mis.mapit.ai/"}
          sx={{
            color: "#0062CC",
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          Go to Student Site
        </Typography>
      </Box>

      {/* Registration Form Section */}
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        {/* Toggle Between Admin and Organizational Registration */}
        <Box sx={{ ml:'180px', display: "flex", alignSelf: "start", height:'61px', }}>
          <Button
            variant="outlined"
            onClick={handleAdminClick}
            sx={{
              // width: "122px",
              // height: "61px",
              fontSize: "18px",
              padding: "16px 24px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0.75rem 0 0 0.75rem",
              border: "1px solid #5C5C5C",
              backgroundColor: bgAdmin,
              color: bgAdmin === "#212121" ? "white" : "#212121",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: bgAdmin === "#212121" ? "#212121" : "#f0f0f0",
              },
            }}
          >
            Admin
          </Button>
          <Button
            variant="outlined"
            onClick={handleOrgAdminClick}
            sx={{
              // width: "214px",
              // height: "61px",
              padding: "16px 24px",
              fontSize: "18px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0 0.75rem 0.75rem 0",
              border: "1px solid #5C5C5C",
              backgroundColor: bgOrgAdmin,
              color: bgOrgAdmin === "#212121" ? "white" : "#212121",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: bgOrgAdmin === "#212121" ? "#212121" : "#f0f0f0",
              },
            }}
          >
            Organizational
          </Button>
        </Box>

        {/* Render the Registration Form based on the selected tab */}
        {bgAdmin === "#212121" ? (
          <AdminRegistrationForm />
        ) : (
          <OrganizationalRegistrationForm />
        )}
      </Container>

      {/* Footer with Bottom Middle Logo */}
      <Box
        sx={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // flexDirection: "column",
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: "24px"}}>
          Powered by
        </Typography>
        <img src={mapITlogo} alt="mapIT Logo" style={{ height: "40px" }} />
        <Typography
          sx={{
            fontWeight: 400,
            fontFamily: "Times New Roman",
            fontSize: "14px",
            marginTop: "8px",
            ml:1,
          }}
        >
          mapIT.ai
        </Typography>
      </Box>

      <ToastContainer />
    </Box>
  );
};

export default RegisterPage;
