import React from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField, Typography, MenuItem, Box, Select } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EastIcon from '@mui/icons-material/East';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FormField = ({ name, label, fieldType, options, heading, sx }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { setFieldValue } = useFormikContext();

  const renderField = () => {
    switch (fieldType) {
      case 'dropdown':
        return (
          <TextField
            {...field}
            select
            fullWidth
            label={label}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            sx={sx}
          >
            {options.map((option) => (
              <MenuItem sx={{ color: 'black' }} key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );
      case 'dropdown2':
        return (
          <Select
            multiple
            {...field}
            fullWidth
            label={label}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            sx={sx}
          >
            {options.map((option) => (
              <MenuItem sx={{ color: 'black' }} key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      case 'dropdown3':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField {...field} label={label} sx={sx} />
            <TextField
              {...field}
              select
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              sx={sx}
            >
              {options.map((option) => (
                <MenuItem sx={{ color: 'black' }} key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        );
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={label}
              value={field.value ? dayjs(field.value) : null}
              onChange={(newValue) => {
                setFieldValue(name, newValue ? newValue.toISOString() : null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  sx={sx}
                />
              )}
            />
          </LocalizationProvider>
        );
      case 'timeInterval':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TimePicker
              label="Start Time"
              value={field.value ? dayjs(field.value.start) : null}
              onChange={(newValue) => {
                setFieldValue(`${name}.start`, newValue ? newValue.toISOString() : null);
              }}
              renderInput={(params) => <TextField {...params} sx={sx} />}
            />
            <EastIcon sx={{ padding: '5px' }} />
            <TimePicker
              label="End Time"
              value={field.value ? dayjs(field.value.end) : null}
              onChange={(newValue) => {
                setFieldValue(`${name}.end`, newValue ? newValue.toISOString() : null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  sx={sx}
                />
              )}
            />
          </Box>
        );
      default:
        return (
          <TextField
            {...field}
            fullWidth
            label={label}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            sx={sx}
          />
        );
    }
  };

  return (
    <>
      {heading && (
        <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>
          {heading}
        </Typography>
      )}
      {renderField()}
    </>
  );
};

export default FormField;
