import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Paper, TextField, IconButton, Avatar } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import AdminMessagingAPIs from '../../utilities/api/IMS/AdminMessagingAPIs';
import WebSocketService from '../../utilities/api/IMS/websocketService';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const IndividualChat = ({ threadId, userName, initialMessages, onBack, addChatToChatList }) => {
    const { access, user } = useSelector(state => state.user.user);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [currentThreadId, setCurrentThreadId] = useState(threadId);
    const [pendingMessage, setPendingMessage] = useState(null);
    const [lastSentMessageId, setLastSentMessageId] = useState(null);
    const chatContainerRef = useRef(null);

    // Helper function to filter messages
    const filterMessages = (msgs) => {
        return msgs.filter(message => message.sender === user.username || message.sender === userName);
    };

    // Function to fetch latest messages for the thread
    const fetchLatestMessages = async () => {
        if (currentThreadId) {
            try {
                const threadDetail = await AdminMessagingAPIs.GetThreadDetail(currentThreadId);
                const filteredMessages = filterMessages(threadDetail.messages);
                const formattedMessages = filteredMessages.map(message => ({
                    id: message.id,
                    text: message.content,
                    date: new Date(message.timestamp).toLocaleString(),
                    sender: message.sender,
                }));
                setMessages(formattedMessages);
            } catch (error) {
                console.error(`Error fetching latest messages for thread ${currentThreadId}:`, error);
            }
        }
    };

    // Fetch latest messages when the component mounts or threadId changes
    useEffect(() => {
        fetchLatestMessages();
    }, [currentThreadId]);

    // Initialize messages when initialMessages prop changes
    useEffect(() => {
        if (initialMessages && initialMessages.length > 0) {
            const filteredInitialMessages = filterMessages(initialMessages);
            setMessages(filteredInitialMessages);
        }
    }, [initialMessages]);

    // Auto-scroll to the latest message
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            const messageId = uuidv4();
            setLastSentMessageId(messageId);

            const newMsg = {
                id: messageId,
                text: newMessage,
                date: new Date().toLocaleString(),
                sender: user.username,
            };
            setNewMessage("");

            if (currentThreadId === null) {
                // If there's no thread, create one and send the first message using REST API
                setPendingMessage(newMsg);
                try {
                    const newThread = await AdminMessagingAPIs.CreateThread({
                        participants: [userName],
                        messages: []
                    });
                    setCurrentThreadId(newThread.id);
                    console.log("New thread created:", newThread.id);

                    // Send the first message via REST API
                    await AdminMessagingAPIs.PostMessage(newThread.id, { content: newMsg.text });

                    // Add the message to the UI
                    setMessages(prevMessages => [...prevMessages, newMsg]);

                    // Add the new thread to the chat list in ChatBar
                    updateChatList(newThread, newMsg);

                    // After the first message, connect to WebSocket for future messages
                    WebSocketService.connect(access, userName);
                } catch (error) {
                    console.error("Error creating new thread or sending first message:", error);
                    setPendingMessage(null);
                }
            } else {
                // If there's already a thread, send the message using WebSocket
                setMessages(prevMessages => [...prevMessages, newMsg]);
                WebSocketService.sendMessage(newMsg.text, currentThreadId);
            }
        }
    };

    const updateChatList = (newThread, newMsg) => {
        if (typeof addChatToChatList === 'function') {
            const newChat = {
                id: newThread.id,
                userName: userName,
                message: newMsg.text,
                date: newMsg.date,
                otherUser: userName
            };
            addChatToChatList(newChat);
        }
    };

    const handleIncomingMessage = useCallback((messageData) => {
        console.log("Received incoming message:", messageData);

        // Only process messages from the receiver or the current user
        if (messageData.sender !== userName && messageData.sender !== user.username) {
            console.warn("Ignoring message from unrecognized sender:", messageData.sender);
            return;
        }

        // Ignore the message if it matches the last sent message ID to prevent duplication
        if (messageData.id && messageData.id === lastSentMessageId) { // Check if id exists
            console.log("Ignoring duplicate message with ID:", messageData.id);
            return;
        }

        const incomingMessage = {
            id: messageData.id || uuidv4(), // Assign a unique ID if not provided
            text: messageData.message,
            date: messageData.timestamp ? new Date(messageData.timestamp).toLocaleString() : new Date().toLocaleString(),
            sender: messageData.sender
        };

        setMessages(prevMessages => [...prevMessages, incomingMessage]);
    }, [lastSentMessageId, userName, user.username]);

    useEffect(() => {
        if (currentThreadId !== null) {
            WebSocketService.connect(access, userName);
            WebSocketService.onMessage(handleIncomingMessage);
        }

        return () => {
            WebSocketService.disconnect();
        };
    }, [access, userName, handleIncomingMessage, currentThreadId]);

    useEffect(() => {
        if (pendingMessage && currentThreadId) {
            WebSocketService.sendMessage(pendingMessage.text, currentThreadId);
            setPendingMessage(null);
        }
    }, [pendingMessage, currentThreadId]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {/* Header */}
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
                <IconButton onClick={onBack}>
                    <ArrowBack />
                </IconButton>
                <Avatar src="path_to_profile_image" sx={{ marginLeft: '10px' }} />
                <Typography variant="h6" sx={{ marginLeft: '10px' }}>
                    {userName}
                </Typography>
            </Box>
            
            {/* Chat Messages */}
            <Box ref={chatContainerRef} sx={{ flexGrow: 1, overflowY: 'auto', padding: '10px', backgroundColor: '#e5ddd5' }}>
                {messages.map((message) => (
                    <Box key={message.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: message.sender === user.username ? 'flex-end' : 'flex-start', marginBottom: '10px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: message.sender === user.username ? '#dcf8c6' : '#fff', borderRadius: '10px', padding: '10px', maxWidth: '60%', boxShadow: '0px 1px 2px rgba(0,0,0,0.1)' }}>
                            {message.sender !== user.username && (
                                <Avatar src="path_to_sender_image" sx={{ width: 30, height: 30, marginRight: '10px' }} />
                            )}
                            <Typography variant="body1">{message.text}</Typography>
                        </Box>
                        <Typography variant="caption" color="textSecondary" sx={{ marginTop: '2px' }}>
                            {message.date}
                        </Typography>
                    </Box>
                ))}
            </Box>
            
            {/* Message Input */}
            <Paper component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', padding: '0 10px', boxShadow: 'none', borderTop: '1px solid #ddd', position: 'sticky', bottom: 0, backgroundColor: '#fff' }}>
                <TextField
                    sx={{ flex: 1 }}
                    placeholder="Type a message"
                    value={newMessage}
                    onChange={handleInputChange}
                    variant="outlined"
                    InputProps={{ 'aria-label': 'type a message' }}
                />
                <IconButton type="submit" aria-label="send">
                    <SendIcon />
                </IconButton>
            </Paper>
        </Box>
    );
};

export default IndividualChat;
